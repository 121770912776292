import { combineReducers } from "redux";

import { TellurideMetaDataReducer } from "./metaData/MetaDataReducer";
import { OfferDetailsReducer } from "./offerDetails/OfferDetailsReducer";
import { SegmentDetailsReducer } from "./segmentation/SegmentDetailsReducer";
import { OfferAnalyticsReducer } from "./offerAnalytics/OfferAnalyticsReducer";
import { ToasterReducer } from "./toaster/ToasterReducer";
import { LeavingSetupCreationReducer } from "./leavingSetupCreation/LeavingSetupCreationReducer";
import { SecurityReducer } from "./security/SecurityReducer";
import { CampaignDetailsReducer } from "./campaignDetails/CampaignDetailsReducer";
import { promptDataReducer } from "./promptData/PromptDataReducer";
import { TellurideSubclubReducer } from "./subclub/SubclubReducer";
import { genericTableDataReducer } from "./genericTableDetails/GenericTableDetailsReducer";
import { JourneyReducer } from "./journey/JourneyReducer";
import { ChatbotReducer } from "./chatbot/ChatbotReducer";
import { reportTokenReducer } from "./reportsDetails/ReportDetailsReducer";
import { RequirementsReducer } from "./requirements/RequirementsReducer";

const rootReducer = combineReducers({
  metaData: TellurideMetaDataReducer,
  // createOfferData: createOfferDataHandlerReducer,
  offerDetailsData: OfferDetailsReducer,
  OfferAnalyticsData: OfferAnalyticsReducer,
  toasterData: ToasterReducer,
  leaveSetupCreation: LeavingSetupCreationReducer,
  securityData: SecurityReducer,
  segmentDetailsData: SegmentDetailsReducer,
  campaignDetailsData: CampaignDetailsReducer,
  promptData: promptDataReducer,
  subclubData: TellurideSubclubReducer,
  genericTableList: genericTableDataReducer,
  journeyDetailsData: JourneyReducer,
  chatbotData: ChatbotReducer,
  reportTokenData:reportTokenReducer,
  requirementData: RequirementsReducer,
});

export default rootReducer;
