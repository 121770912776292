import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import "./SetupConnectorComponent.style.scss";
import {
  connector_options_image_urls,
  connectorRightsJSON,
  setup_connector_list_details,
} from "../ConnectorConstant";
import { useEffect } from "react";
import { getAddConnectorOptions } from "../../../tellurideExtRedux";
import { headers } from "../../constantComponent/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../../utilsComponent/CommonUtil";
import { useState } from "react";
import Loading from "../../LoaderComponents/Loading";
import { CONNECTOR_LOADING_SOURCE } from "../../LoaderComponents/LoadingConstant";
import { emptyData } from "../../constantComponent/DesignConstant";
const SetupConnectorComponent = ({
  setIsShowCreatePopup,
  setCurrentClickedData,
  setIsExistingData,
}) => {
  const dispatch = useDispatch();

  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const connectorListData = useSelector(
    (state) => state.metaData.addConnectorOptionsResponse
  );

  const [connectorOptions, setConnectorOptions] = useState(
    ""
    // connectorListData?.metadata
  );
  useEffect(() => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    dispatch(getAddConnectorOptions(requestHeaders, ""));
  }, []);

  useEffect(() => {
    if (connectorListData) {
      setConnectorOptions(connectorListData?.metadata);
    }
  }, [connectorListData]);

  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <Loading source={CONNECTOR_LOADING_SOURCE}>
        <section className="display-flex new-connector-section">
          <div className="new-connector-component">
            <div className="connectors-header setup-page-section-title">
              Setup New Connectors
            </div>
            <div className="content-view">
              <div className="connector-list">
                {connectorListData?.metadata?.map((connector) => {
                  return (
                    <>
                      {checkRightsIsVisible(
                        getRightsKey(
                          connectorRightsJSON[connector?.connectorSubType]
                        )
                      ) && (
                        <div
                          className={`connector-types flex ${
                            connector?.status === "A" ? "" : "disabled"
                          }`}
                          onClick={() => {
                            if (connector?.status === "A") {
                              setIsShowCreatePopup(true);
                              setIsExistingData(false);
                              setCurrentClickedData(connector);
                            }
                          }}
                        >
                          <img
                            src={
                              connector_options_image_urls[
                                connector?.connectorSubType
                              ]?.src
                            }
                            width={
                              connector_options_image_urls[
                                connector?.connectorSubType
                              ]?.width
                            }
                            height={
                              connector_options_image_urls[
                                connector?.connectorSubType
                              ]?.height
                            }
                          />
                          {connector?.status !== "A" && (
                            <p className="message">Coming Soon</p>
                          )}
                        </div>
                      )}
                    </>
                  );
                })}
                {connectorListData?.metadata?.length <= 0 && emptyData()}
              </div>
            </div>
          </div>
        </section>
      </Loading>
    </ErrorBoundary>
  );
};

export default SetupConnectorComponent;
