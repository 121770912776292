import React from "react";
const queryString = require("query-string");
const OfferSdk = () => {
	const refid = queryString.parse(window.location.search).refid;
	const organization = queryString.parse(window.location.search).organization;
	const clientid = queryString.parse(window.location.search).clientid;
	return <syw-offer-sdk ref-id={refid} organization={organization} client-id={clientid} title=""></syw-offer-sdk>;
};

export default OfferSdk;
