import moment from "moment";
import {
	BASE_EARN,
	BASE_REDEEM,
	LOYALTY_CASH,
	OFRI,
	RIGHTS,
	SKEY,
	EVENT_OFFER,
} from "../constantComponent/Constants";
import { v4 as uuidv4 } from "uuid";
import {
	CAP,
	OFFER_SETUP_COMMUNICATION_LINK_STATUS_DELETE,
	OFFER_SETUP_COMMUNICATION_LINK_STATUS_NEW,
	OFFER_SETUP_COMMUNICATION_LINK_STATUS_RENAME,
	OFFER_SETUP_COMMUNICATION_STATUS_N,
	OFFER_SETUP_COMMUNICATION_STATUS_Y,
} from "../offerSetupComponent/offerSetupConstant";
import { ZERO } from "./GenericRules/GenericRulesConstants";
import { filter } from "lodash";
export function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}
export function formatDateMM_DD_YYYY(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	// return [year, month, day].join("-");
	return [month, day, year].join("/");
}

export function onBlurAction(e, val, setVal, limit) {
	if (!(e.target.value.indexOf("$") > -1)) {
		e.target.value = "$" + e.target.value;
		setVal(e.target.value);
	}
	var amountVal =
		e.target.value.indexOf("$") > -1
			? e.target.value.substring(1, e.target.value.length)
			: e.target.value;

	if (amountVal >= -1 || amountVal <= limit) {
		amountVal = (amountVal * 100) / 100;
		amountVal = amountVal + "";
		if (amountVal.indexOf(".") > -1 && amountVal.split(".")[1].length === 1) {
			setVal(amountVal + "0");
			e.target.value = "$" + amountVal + "0";
		} else if (amountVal.indexOf(".") === -1 && amountVal.length >= 1) {
			setVal(amountVal + ".00");
			e.target.value = "$" + amountVal + ".00";
		} else if (
			amountVal.indexOf(".") > -1 &&
			amountVal.split(".")[1].length > 2
		) {
			setVal("0.00");
			e.target.value = "$0.00";
		} else {
			setVal(amountVal);
			e.target.value = "$" + amountVal;
		}
	}
}

export function onChangeAction(e, val, setVal, limit) {
	var target = e.target;
	var amountVal =
		target.value.indexOf("$") > -1
			? target.value.substring(1, target.value.length)
			: target.value;
	amountVal = amountVal.replace(/[^0-9.]+/g, "");
	if (
		amountVal.indexOf(".") > -1 &&
		amountVal.indexOf(".", amountVal.indexOf(".") + 1) > -1
	) {
		setVal(val);
		target.value = "$" + val;
	} else {
		if (
			amountVal <= -0.01 ||
			amountVal >= limit ||
			(amountVal.indexOf(".") > -1 && amountVal.split(".")[1].length > 2)
		) {
			setVal(val);
			target.value = "$" + val;
			if (amountVal.indexOf(".") > -1 && amountVal.split(".")[1].length > 2) {
				target.value = "$" + val;
				setVal(val);
			}
		} else {
			setVal(amountVal);
			target.value = "$" + amountVal;
		}
	}
}

export function onKeyPressAction(e) {
	if (!/[0-9.]/.test(e.key)) {
		e.preventDefault();
	}
	e.key === "Enter" && e.preventDefault();
}
export function onKeyPressActionNonDecimal(e) {
	if (!/[0-9]/.test(e.key)) {
		e.preventDefault();
	}
	e.key === "Enter" && e.preventDefault();
}

export function changeDateDashFormat(date) {
	return moment(date).format("YYYY-MM-DD");
}
export function changeDateDashFormatToBackwardSlash(date) {
	return moment(date).format("YYYY/MM/DD");
}
export function skey() {
	return sessionStorage.getItem(SKEY);
}
export function getRightsFromSession() {
	return sessionStorage.getItem(RIGHTS);
}
export function getAllRights() {
	return localStorage.getItem("AllRights")?.split(",");
}
export function trimExtraSpaces(value) {
	return value.trim();
}
export function copyToClipBoard(value) {
	navigator.clipboard.writeText(value);
}

export function getExtensionOfFile(files) {
	return files[0].name.split(".").pop();
}
export function bytesToMB(bytes) {
	const mb = Math.round(bytes / (1024 * 1024));
	return mb;
}

export function generateUniqueId() {
	return uuidv4().replace(/-/g, "") + Date.now();
}
export function generateUniqueIdByLength(length) {
	const characters =
		"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	let uniqueId = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		uniqueId += characters[randomIndex];
	}
	return uniqueId;
}
export function generateUniqueVariationId() {
	return uuidv4().replace(/-/g, "");
}
export function reverseString(str) {
	if (str === "") {
		return "";
	} else {
		return reverseString(str.substr(1)) + str.charAt(0);
	}
}
export function toTitleCase(str) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}
export function GetRequestBodyForCreateUpdateOffer(
	user,
	offerDetailsPageData,
	updatedOfferName
) {
	let updatedOfferPageData = {};
	const RewardData = offerDetailsPageData?.updateRewardData;
	const RuleData = offerDetailsPageData?.updateRuleData;
	const OfferDetailData = offerDetailsPageData?.updateOfferData;
	const LinkedData = offerDetailsPageData?.updateLinkedOfferData;
	const OfferAttribute = offerDetailsPageData?.updateOfferAttributes;
	const capData = offerDetailsPageData?.updateCapData;
	updatedOfferPageData.offerData = OfferDetailData;

	//Process capData and merge it with existing actionProperty in actions.
	if (OfferDetailData.offerType !== EVENT_OFFER) {
		if (capData && capData?.propertyType === CAP) {
			let filteredActionProperty =
				RewardData.action[0].actionProperties.actionProperty.filter(
					(element) => element.propertyType !== CAP
				);
			filteredActionProperty.push(capData);
			RewardData.action[0].actionProperties.actionProperty =
				filteredActionProperty;
		} else {
			if (RewardData) {
				let filteredActionProperty =
					RewardData.action[0].actionProperties.actionProperty.filter(
						(element) => element.propertyType !== CAP
					);
				RewardData.action[0].actionProperties.actionProperty =
					filteredActionProperty;
			}
		}
	} else {
		//Always set empty value as OfferCategory for EventProcessing offers.
		delete offerDetailsPageData.offerData.offerCategory;
		delete OfferDetailData.expenseAllocation;
	}
	if (OfferDetailData.offerType === EVENT_OFFER && !RewardData) {
		updatedOfferPageData.actions = {
			action: [
				{
					actionJson: JSON.stringify({}),
					actionName: "OFFER_ACTION",
				},
			],
		};
	} else {
		updatedOfferPageData.actions = RewardData;
	}

	if (
		OfferAttribute.offerAttribute &&
		offerDetailsPageData?.updateOfferData?.programType
	) {
		let { offerAttribute } = OfferAttribute;
		if (offerAttribute.find((item) => item?.name === "PROGRAM_TYPE")) {
			offerAttribute = offerAttribute.map((item) => {
				if (item?.name === "PROGRAM_TYPE") {
					return {
						name: "PROGRAM_TYPE",
						values: {
							value: [offerDetailsPageData?.updateOfferData?.programType],
						},
					};
				}
				return item;
			});
		} else {
			offerAttribute.push({
				name: "PROGRAM_TYPE",
				values: {
					value: [offerDetailsPageData?.updateOfferData?.programType],
				},
			});
		}
	}
	updatedOfferPageData.offerAttributes = OfferAttribute;

	if (
		LinkedData &&
		LinkedData[0].offerData.linkedOfferStatus ===
			OFFER_SETUP_COMMUNICATION_STATUS_Y
	) {
		if (
			offerDetailsPageData?.imageUploadJson?.imageLinkStatus ===
				OFFER_SETUP_COMMUNICATION_LINK_STATUS_RENAME &&
			!offerDetailsPageData?.imageUploadJson?.imageId.includes("_new")
		) {
			offerDetailsPageData.imageUploadJson.imageLinkStatus =
				OFFER_SETUP_COMMUNICATION_LINK_STATUS_NEW;
		}

		if (
			offerDetailsPageData?.imageUploadJson?.imageLinkStatus !==
				OFFER_SETUP_COMMUNICATION_LINK_STATUS_DELETE &&
			offerDetailsPageData?.uploadImageContentResponse
		) {
			LinkedData[0].actions.action[0].actionJson.imageName =
				offerDetailsPageData.uploadImageContentResponse.imageName.replaceAll(
					"_new",
					""
				);
			LinkedData[0].actions.action[0].actionJson.imageId =
				offerDetailsPageData.uploadImageContentResponse.imageId.replaceAll(
					"_new",
					""
				);
		} else if (
			offerDetailsPageData?.imageUploadJson?.imageLinkStatus ===
			OFFER_SETUP_COMMUNICATION_LINK_STATUS_DELETE
		) {
			LinkedData[0].actions.action[0].actionJson.imageName = "";
			LinkedData[0].actions.action[0].actionJson.imageId = "";
		}

		LinkedData[0].offerData.imageDetails = offerDetailsPageData.imageUploadJson;
		updatedOfferPageData.linkedOffers = LinkedData;
		updatedOfferPageData.linkedOffers[0].offerData.offerPointsDollarName =
			updatedOfferName;
	} else if (LinkedData && LinkedData[0].offerData.linkedOfferStatus === "N") {
		if (offerDetailsPageData.offerDetailsResponse.linkedOffers) {
			updatedOfferPageData.linkedOffers =
				offerDetailsPageData.offerDetailsResponse.linkedOffers;
			updatedOfferPageData.linkedOffers[0].offerData.offerPointsDollarName =
				updatedOfferName;
			updatedOfferPageData.linkedOffers[0].offerData.linkedOfferStatus =
				OFFER_SETUP_COMMUNICATION_STATUS_N;
		}
	}

	if (OfferDetailData.offerType === EVENT_OFFER) {
		if (
			RuleData &&
			Object.values(RuleData)[0] &&
			Object.values(RuleData)[0]?.length > ZERO
		) {
			updatedOfferPageData.rules = {
				rule: [{ entity: "AdvanceRule", condition: RuleData }],
			};
		} else {
			updatedOfferPageData.rules = {
				rule: [{ entity: "AdvanceRule", condition: "{}" }],
			};
		}
	} else if (
		RuleData &&
		Object.values(RuleData)[0] &&
		Object.values(RuleData)[0]?.length > ZERO
	) {
		updatedOfferPageData.rules = {
			rule: [{ entity: "AdvanceRule", condition: RuleData }],
		};
	} else {
		updatedOfferPageData.rules = {};
	}
	updatedOfferPageData.offerData = {
		...OfferDetailData,
		offerPointsDollarName: trimExtraSpaces(updatedOfferName),
		offerCategory:
			offerDetailsPageData?.offerData?.offerType === BASE_EARN
				? "Always Applicable"
				: OfferDetailData.offerCategory,
	};
	delete updatedOfferPageData?.offerData?.programType;
	if (OfferDetailData.offerType === EVENT_OFFER) {
		delete updatedOfferPageData.offerData.offerCategory;
	}
	// updatedOfferPageData.offerData.offerType = "Points Earn";
	// updatedOfferPageData.offerData.offerSubType = "";
	updatedOfferPageData.offerData.offerBUProgram = undefined;
	updatedOfferPageData.offerData.modifiedBy = user.email;
	const requestBody = {
		requestorID: OFRI,
		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
		//orgName: "ESLO",
		offerData: updatedOfferPageData.offerData,
		rules: updatedOfferPageData.rules || {},
		actions: updatedOfferPageData.actions,
		linkedOffers: updatedOfferPageData.linkedOffers,
		offerAttributes: updatedOfferPageData.offerAttributes,
	};
	requestBody.offerData.offerStartDate = changeDateDashFormat(
		requestBody?.offerData?.offerStartDate
	);
	requestBody.offerData.offerEndDate = changeDateDashFormat(
		requestBody?.offerData?.offerEndDate
	);
	if (
		offerDetailsPageData.offerData.offerType === BASE_REDEEM ||
		offerDetailsPageData.offerData.offerType === LOYALTY_CASH
	) {
		requestBody.offerData.offerConditions = "Excludes Everyday Great Price";
		requestBody.offerData.offerExclusions = "FOOD DRUG";
	}
	return requestBody;
}
const abbreviationMap = {
	navigation: "nav",
	loyalty: "loy",
	dashboard: "db",
	button: "btn",
	createoffer: "co",
	link: "lnk",
	viewoffer: "vwo",
	popup: "pop",
	pointsearnoffer: "peo",
	pointsdiscountoffer: "pdo",
	loyaltycashoffer: "lco",
	baseearnoffer: "beo",
	baseredeemoffer: "bro",
	missionoffer: "mo",
	customoffer: "csto",
	nonpurchasepointsearn: "nppe",
	generate: "gen",
	continuetosetup: "cts",
	detail: "det",
	stats: "stats",
	addcontent: "ac",
	active: "act",
	deactive: "deact",
	edit: "edt",
	save: "sav",
	editnode: "edtn",
	addnode: "addn",
	deletenode: "deln",
	segmentation: "seg",
	createsegment: "cs",
	static: "stic",
	datadriven: "dd",
	email: "eml",
	continuetosetupbutton: "ctsb",
	modify: "mdy",
	viewsegmentation: "vws",
	configure: "cnfgr",
	attribute: "attr",
	createattribute: "ca",
	subclub: "subc",
	createsubclub: "cs",
	campaigns: "cmp",
	createcampaign: "cc",
	viewcampaigns: "vc",
	segmenttriggered: "st",
	eventtriggered: "et",
	campaign: "cmp",
	startabtest: "sat",
	content: "cnt",
	createcontent: "ccnt",
	messageoffer: "msgo",
	cuoponoffer: "cupo",
	pointoffer: "pnto",
	pricediscountoffer: "pdo",
	previewtest: "prwtst",
	setup: "stp",
	pointsearn: "pe",
	pricediscount: "pd",
	loyaltycash: "lc",
	baseearn: "be",
	baseredeem: "br",
	mission: "ms",
	messageonly: "msg",
	custom: "cstm",
	coupon: "cop",
	point: "poi",
	filter: "flt",
	offertype: "ot",
	type: "typ",
	batch: "batch",
	segmentcampaign: "segcmp",
	eventcampaign: "evtcmp",
	eventprocessoffer: "epo",
	eventoffer: "epo",
	reports: "rep",
	connector: "conn",
	viewconnector: "vwconn",
	marigold: "mrgd",
	klaviyo: "klyo",
	sailthru: "sltru"
};

export const getRightsKey = (inputKey) => {
	let abbreviatedList = [];
	inputKey?.forEach((word) => {
		const lowercaseWord = word.toLowerCase();
		// Check if the word exists in the abbreviationMap
		if (abbreviationMap.hasOwnProperty(lowercaseWord)) {
			abbreviatedList.push(abbreviationMap[lowercaseWord]);
		} else {
			// If the word is not in the map, use the first letter
			abbreviatedList.push(lowercaseWord.charAt(0));
		}
	});

	// Join the abbreviations with underscore
	return "ext_" + abbreviatedList.join("_");
};

export const checkRightsIsVisible = (input) => {
	let rights = getAllRights();
	let newInput = rights?.find((str) => str.startsWith(input + "~"));
	if (newInput) {
		// Split the input string using '~' as a delimiter
		const parts = newInput.split("~");
		// Extract the visibility part (first part after the split)
		const visibilityPart = parts[1];
		// Convert the visibility part to a number and check if it is equal to 1
		return visibilityPart === "0";
		// return false;
	} else {
		return false;
	}
};

export const checkRightsIsEditable = (input) => {
	let rights = getAllRights();
	let newInput = rights?.find((str) => str.startsWith(input + "~"));
	if (newInput) {
		// Split the input string using '~' as a delimiter
		const parts = newInput.split("~");
		// Extract the editability part (second part after the split)
		const editabilityPart = parts[2];
		// Convert the editability part to a number and check if it is equal to 1
		return editabilityPart === "0";
	} else {
		return false;
	}
};
function normalize(json) {
	// Sort properties by name and their values
	try {
		if (!json?.property) return json;
		return json?.property
			.map((prop) => ({
				...prop,
				values: {
					value: prop.values.value.slice().sort(), // Ensure values are sorted
				},
			}))
			.sort((a, b) => a.name.localeCompare(b.name));
	} catch (error) {
		return json;
	}
}

export function areJsonEqual(json1, json2) {
	const normalizedJson1 = normalize(json1);
	const normalizedJson2 = normalize(json2);

	return JSON.stringify(normalizedJson1) === JSON.stringify(normalizedJson2);
}
