import React, { useState,useEffect } from "react";
import Chatbot, { createChatBotMessage } from "react-chatbot-kit";
//import { ReactComponent as ButtonIcon } from "./assets/img/111-new.svg";
//import { ReactComponent as ButtonIcon } from "./assets/icons/robot.svg";
import { useSelector } from "react-redux";
import Config from "./assets/Config";
import ActionProvider from "./assets/ActionProvider";
import MessageParser from "./assets/MessageParser";
import Validator from "./assets/Validator";

import "./ChatBotWidget.scss";
import { CustomHeader, CustomInput } from "./ChatBotComponents/CustomComponents";
//import { ReactComponent as ButtonIcon } from "./assets/img/bot_icon_light.png";
import BotIcon from "./assets/img/bot_icon_light.png";
import DarkBotIcon from "./assets/img/bot_icon_dark.png";

function ChatBotWidget() {
  const [showChatbot, toggleChatbot] = useState(false);
  const [minimizeChatbot, setMinimizeChatbot] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
///console.log(theme);

	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
  // console.log(toasterData.isDarkTheme);
  const saveMessages = (messages, HTMLString) => {
    console.log("save:"+localStorage.setItem("chat_messages", JSON.stringify(HTMLString))
    );
  };

  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem("chat_messages"));
    console.log(messages);
    return messages;
  };

  return (
    <div className="Chat">
      <script src="./chatBotUtil.js"></script>
      <div  data-theme={theme}>
      <div className="app-chatbot-container">
        {showChatbot ? (
          minimizeChatbot ? (
            <Chatbot
              config={{
                ...Config,
                customComponents: {
                  header: (props) => (
                    <CustomHeader
                      toggleChatbot={toggleChatbot}
                      setMinimizeChatbot={setMinimizeChatbot}
                    />
                  ),
                /*  customInput: (props) => (
                    <CustomInput {...props} />
                  )
                  */
                },
              }}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              validator={Validator}
              saveMessages={saveMessages}
              messageHistory={loadMessages()}
              headerText="Telluride Offer Helper"
              placeholderText="Ask your question here"
            />
          ) : (
            <Chatbot
              config={{
                ...Config,
                customComponents: {
                  header: (props) => (
                    <CustomHeader
                      toggleChatbot={toggleChatbot}
                      setMinimizeChatbot={setMinimizeChatbot}
                    />
                  ),
                 /* customInput: (props) => (
                    <CustomInput  {...props}/>
                  )
                  */
                }, 
              }}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              validator={Validator}
              saveMessages={saveMessages}
              headerText="Telluride Offer Helper"
              placeholderText="Ask your question here"
            />
          )
        ) : null}
      </div>

      {!showChatbot ? (
        <button
          className="app-chatbot-button"
          onClick={() => {
            toggleChatbot((prev) => !prev);
          }}
        >
          {minimizeChatbot ? (
            <div className="app-chatbot-button-notification-icon"></div>
          ) : null}
          
          {
            theme == 'light' ? (
          <img src={BotIcon} className="app-chatbot-button-icon"/>) :(
            <img src={DarkBotIcon} className="app-chatbot-button-icon"/>
          ) 
         
}
        </button>
      ) : null}
    </div>
    </div>
  );
}

export default ChatBotWidget;
