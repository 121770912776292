import React, { useEffect, useLayoutEffect, useState } from "react";
import {
	BACK,
	PROCEED,
} from "../../../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import "./OfferSetupPopUpComponent.style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	BASE_EARN,
	BASE_REDEEM,
	LOYALTY_CASH,
	MISSION,
	NON_PURCHASE,
	OFFER_DETAILS_STATUS_ACTIVATED,
	OFFER_DETAILS_STATUS_DRAFT,
	OFRI,
	POINTS_EARN,
	PRICE_DISCOUNT,
	SAVE,
	headers,
} from "../../../../constantComponent/Constants";
import {
	GetRequestBodyForCreateUpdateOffer,
	changeDateDashFormat,
	checkRightsIsEditable,
	getRightsFromSession,
	getRightsKey,
	skey,
	trimExtraSpaces,
} from "../../../../utilsComponent/CommonUtil";
import { useErrorBoundary } from "react-error-boundary";
import {
	createUpdateOffer,
	fetchOfferDetails,
	getEntitiesDetails,
	getProgramTypesDetails,
	setErrorToaster,
	updateOfferData,
	updateRuleData,
} from "../../../../../tellurideExtRedux";
import { useNavigate } from "react-router-dom";
import { OFFER, VIEW_OFFER } from "../../../../constantComponent/PathConstants";
import Rules from "../../../rulesComponent/Rules";
import OfferDetails from "../../../offerDetailsComponent/OfferDetails";
import { OFFER_LOADER_SOURCE } from "../../../../LoaderComponents/LoadingConstant";
import Loading from "../../../../LoaderComponents/Loading";
import RewardSection from "../../../rewardComponent/Reward";
import GenericRules from "../../../../utilsComponent/GenericRules/GenericRules";
import {
	INITIAL_DEFAULT_RULES,
	RULE_SCREEN_HEADER,
	ZERO,
	nonpurchase_entites_options,
} from "../../../../utilsComponent/GenericRules/GenericRulesConstants";
import {
	checkFilterEmpty,
	convertJSON,
	convertToInitialJSON,
} from "../../../../utilsComponent/GenericRules/GenericRulesUtil";
import OfferDetailsHeader from "../../../offerDetailsHeaderComponent/OfferDetailsHeader";
import OfferReviewPopUpComponent from "../offerReviewPopupComponent/OfferReviewPopUpComponent";
import OfferSetupHeader from "./OfferSetupHeader";
import OfferSelectionPopupComponent from "../offerSelectionPopupComponent/OfferSelectionPopupComponent";
import { useAuth0 } from "@auth0/auth0-react";
import { CAP, RESPONSE_STATUS_SUCCESS } from "../../../offerSetupConstant";
import DiscountSection from "../../../discountComponent/Discount";
import RedeemSection from "../../../redeemComponent/Redeem";
import PointsValidity from "../../../PointsValidityComponent/PointsValidity";
import DiscountOfferCap from "../../../capComponent/DiscountOfferCap";
import EarnOfferCap from "../../../capComponent/EarnOfferCap";

const OfferSetupPopUpComponent = ({
	theme,
	setPopupShowProcess,
	currentClickedNode,
	setNodeData,
	offerNumber,
	isDisabled,
	...rest
}) => {
	const { user } = useAuth0();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { showBoundary } = useErrorBoundary();
	const offerData = useSelector((state) => state.offerDetailsData);
	const metaData = useSelector((state) => state.metaData);
	const initialRules = useSelector((state) => state.offerDetailsData.ruleData);
	const [offerSetupDisabled, setOfferSetupDisabled] = useState();
	const [updatedOfferName, setUpdatedOfferName] = useState(
		offerData?.offerData?.offerPointsDollarName
	);
	const [offerDetailValues, setValues] = useState(offerData?.offerData);
	let { offerType } = rest;
	if (offerType === undefined) {
		offerType = currentClickedNode?.data?.value?.action?.offerType;
	}
	const Offer_Status = offerData?.offerData?.offerStatus;
	const offerTypeSetup =
		offerData?.offerData?.offerType === POINTS_EARN &&
		offerData?.offerData?.offerAttributes.offerAttribute.some(
			(attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
		)
			? offerData?.offerData?.offerAttributes.offerAttribute.find(
					(attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
			  ).values.value[0]
			: offerData?.offerData?.offerType;
	const [expression, setExpression] = useState(
		Object.keys(initialRules).length > ZERO
			? convertToInitialJSON(initialRules?.rule[0]?.condition)
			: convertToInitialJSON(INITIAL_DEFAULT_RULES)
	);

	useEffect(() => {
		setExpression(
			Object.keys(initialRules).length > ZERO
				? convertToInitialJSON(initialRules?.rule[0]?.condition)
				: convertToInitialJSON(INITIAL_DEFAULT_RULES)
		);
	}, [initialRules]);

	useLayoutEffect(() => {
		setExpression(
			Object.keys(initialRules).length > ZERO
				? convertToInitialJSON(initialRules?.rule[0]?.condition)
				: convertToInitialJSON(INITIAL_DEFAULT_RULES)
		);
	}, [initialRules]);
	useEffect(() => {
		dispatch(updateOfferData(offerDetailValues));
	}, [offerDetailValues]);

	useEffect(() => {
		setUpdatedOfferName(offerData?.offerData?.offerPointsDollarName);
	}, [offerData]);

	useEffect(() => {
		//  Fetch Entities
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		const offerRequestBody = {
			requestorID: OFRI,
			messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
			offerNumber: currentClickedNode?.data?.value?.action?.offerCode
				? currentClickedNode?.data?.value?.action?.offerCode
				: offerNumber
				? offerNumber
				: offerData?.offerNumber
				? offerData?.offerNumber
				: "",
		};
		dispatch(
			fetchOfferDetails(requestHeaders, offerRequestBody, {
				navigate: navigate,
				successRedirect: "",
				errorRedirectionPath: OFFER + VIEW_OFFER,
			})
		);
	}, []);
	useEffect(() => {
		if (offerType) {
			headers.sessionId = skey();
			headers.requestAccess = getRightsFromSession();
			headers.organization = offerData?.authOrganizationName;
			const requestHeaders = {
				headers,
			};
			const entitiesRequestBody = {
				requestorID: OFRI,
				messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
				offerType: offerType,
			};
			dispatch(
				getEntitiesDetails(showBoundary, entitiesRequestBody, requestHeaders)
			);
			// Program Type change
			const programTypesRequestBody = {
				requestorID: OFRI,
				messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
			};
			dispatch(getProgramTypesDetails(programTypesRequestBody, requestHeaders));
		}
	}, [offerType]);
	useEffect(() => {
		if (!checkFilterEmpty(expression[0].filters)) {
			dispatch(updateRuleData(convertJSON(expression)));
		}
	}, [dispatch, expression]);
	useEffect(() => {
		if (offerTypeSetup) {
			const isEditable = checkRightsIsEditable(
				getRightsKey([
					"loyalty",
					"detail",
					"setup",
					offerTypeSetup.split(" ").join(""),
				])
			);

			if (Offer_Status === OFFER_DETAILS_STATUS_DRAFT && isEditable) {
				setOfferSetupDisabled(false);
			} else {
				setOfferSetupDisabled(true);
			}
		}
	}, [offerType, Offer_Status]);
	const handleClickOnSave = async () => {
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		const requestBody = GetRequestBodyForCreateUpdateOffer(
			user,
			offerData,
			updatedOfferName
		);
		if (offerData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT) {
			await dispatch(
				createUpdateOffer(
					requestHeaders,
					requestBody,
					true,
					false,
					navigate,
					true
				)
			);
		}

		rest.setComponentName({
			name: () => (
				<OfferReviewPopUpComponent
					theme={theme}
					currentClickedNode={currentClickedNode}
					setNodeData={setNodeData}
					setPopupShowProcess={setPopupShowProcess}
					offerType={offerType}
					setComponentName={rest.setComponentName}
					isDisabled={isDisabled}
					offerNumber={offerNumber ? offerNumber : offerData?.offerNumber}
					SourceComponent={OfferSetupPopUpComponent}
				/>
			),
			displayName: "offerReview",
		});
	};
	return (
		<Loading source={OFFER_LOADER_SOURCE}>
			<>
				{" "}
				<section className="offer-setup-wrapper">
					<OfferSetupHeader
						updatedOfferName={updatedOfferName}
						setUpdatedOfferName={setUpdatedOfferName}
						disabled={offerSetupDisabled}
					/>
					<OfferDetails
						theme={theme}
						isTabletModeOn={true}
						disabled={offerSetupDisabled}
					/>
					{[PRICE_DISCOUNT].includes(offerType) ? (
						<DiscountSection theme={theme} disabled={offerSetupDisabled} />
					) : null}

					{[POINTS_EARN, BASE_EARN, MISSION, NON_PURCHASE].includes(
						offerType
					) ? (
						<RewardSection theme={theme} disabled={offerSetupDisabled} />
					) : null}

					{[LOYALTY_CASH, BASE_REDEEM].includes(offerType) ? (
						<RedeemSection theme={theme} disabled={offerSetupDisabled} />
					) : null}
					<div className="rule-screen-container content-section">
						<h2 className="rule-screen-header setup-page-section-title">
							{RULE_SCREEN_HEADER}
						</h2>
						<GenericRules
							expression={expression}
							setExpression={setExpression}
							isSetDisable={
								offerType === LOYALTY_CASH || isDisabled
									? true
									: false || offerSetupDisabled
							}
							hideButtons={offerType === LOYALTY_CASH ? true : false}
							entitesOptions={metaData?.entitiesResponse.entities}
						/>
					</div>
					{[BASE_EARN, POINTS_EARN, MISSION, NON_PURCHASE].includes(
						offerType
					) ? (
						<PointsValidity theme={theme} disabled={offerSetupDisabled} />
					) : null}

					{[PRICE_DISCOUNT].includes(offerType) ? (
						<DiscountOfferCap theme={theme} disabled={offerSetupDisabled} />
					) : null}

					{[POINTS_EARN, LOYALTY_CASH, MISSION, NON_PURCHASE].includes(
						offerType
					) ? (
						<EarnOfferCap theme={theme} disabled={offerSetupDisabled} />
					) : null}
				</section>
				<footer className="offer-setup-modal-footer">
					{offerData?.offerStatus === OFFER_DETAILS_STATUS_ACTIVATED && (
						<button
							className="btn btn-secondary ml-10"
							onClick={() => {
								rest.setComponentName({
									name: () => (
										<OfferReviewPopUpComponent
											theme={theme}
											currentClickedNode={currentClickedNode}
											setNodeData={setNodeData}
											setPopupShowProcess={setPopupShowProcess}
											offerType={offerType}
											setComponentName={rest.setComponentName}
											isDisabled={isDisabled}
											offerNumber={
												offerNumber ? offerNumber : offerData?.offerNumber
											}
											SourceComponent={OfferSetupPopUpComponent}
										/>
									),
									displayName: "offerReview",
								});
							}}
						>
							{BACK}
						</button>
					)}

					{offerData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT && (
						<button
							className="btn btn-primary continue-setup-btn"
							// disabled={
							// 	// selectedSubclub.code && !isDisabled ? false : true
							// }
							onClick={() => {
								handleClickOnSave();
							}}
						>
							{SAVE} and {PROCEED}
						</button>
					)}
				</footer>
			</>
		</Loading>
	);
};

export default OfferSetupPopUpComponent;
