import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import SmallGenericTable from "../../utilsComponent/SmallGenericTable/SmallGenericTable";
import { popupConfig } from "../../segmentSetupComponent/segmentContentComponent/SegmentContentConstants";
import { useDispatch, useSelector } from "react-redux";
import { getConnectorTableList } from "../../../tellurideExtRedux";
import { useEffect } from "react";
import { headers } from "../../constantComponent/Constants";
import { getRightsFromSession, skey } from "../../utilsComponent/CommonUtil";
import "./ConnectorsListComponent.style.scss";
import { connector_colums } from "../ConnectorConstant";
import { useState } from "react";
const ConnectorsListComponent = ({
  theme,
  setIsShowCreatePopup,
  setCurrentClickedData,
  setIsExistingData
}) => {
  const connectorListData = useSelector(
    (state) => state.metaData.connectorListResponse.instances
  );
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const metaData = useSelector((state) => state.metaData);

  const dispatch = useDispatch();

  useEffect(() => {
    getTableList();
  }, []);

  // useEffect(() => {
  //   console.log(connectorListData);
  // }, [connectorListData]);

  function getTableList() {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      organization: offerDetailsPageData?.authOrganizationName,
      Type: "",
      subtype: "",
    };
    dispatch(getConnectorTableList(requestHeaders, requestBody));
  }

  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <section className="display-flex connector-section">
        <div className="connector-list-component">
          <div className="connectors-table">
            <div className="connectors-header setup-page-section-title">
              Connectors
            </div>
            <div className="table-view">
              {/* Change props here, and send smallgenericbody */}
              <SmallGenericTable
                Columns={connector_colums}
                tableData={connectorListData}
                isDataLoading={metaData?.connectorListLoading}
                isRowSelectable={false}
                isRowMultiSelectable={false}
                rowClickHandler={(connector) => {
                  setIsShowCreatePopup(true);
                  setIsExistingData(true);
                  setCurrentClickedData(connector);
                }}
                config={{
                  ...popupConfig,
                  theme: theme,
                  // selectedDataKey: "groupId",
                  isDisabled: false,
                  border: false,
                }}
                isShowPagination={false}
              />
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default ConnectorsListComponent;
