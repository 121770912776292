import "./SegmentContent.style.scss";

import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { DARK, headers, OFRI } from "../../constantComponent/Constants";
import { searchEmailSegmentContent } from "../../../tellurideExtRedux/segmentation/SegmentDetailsAction";
import { getRightsFromSession, skey } from "../../utilsComponent/CommonUtil";
import {
  email_segment_columns,
  popupConfig,
  CREATED_BY,
  CREATE_DATE,
  SEGMENT_CONTENTS,
} from "./SegmentContentConstants";
import SmallGenericTable from "../../utilsComponent/SmallGenericTable/SmallGenericTable";

const EmailSegmentContent = (props) => {
  const offerData = useSelector((state) => state.offerDetailsData);
  const segmentDetailsPageData = useSelector(
    (state) => state.segmentDetailsData
  );
  const securityData = useSelector((state) => state.securityData);
  const segmentData = segmentDetailsPageData?.segmentDetailsResponse;
  const searchSegmentContentLoading =
    segmentDetailsPageData?.searchSegmentContentLoading;
  const searchSegmentContentError =
    segmentDetailsPageData?.searchSegmentContentError;
  let segmentContentList = segmentData.groupDetailsList;
  const searchSegmentContentResponse =
    segmentDetailsPageData?.searchSegmentContentResponse;
  const searchSegmentContentData =
    searchSegmentContentResponse.groupDetailsList;
  const dispatch = useDispatch();
  const [searchInputQuery, setSearchInputQuery] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [list, setList] = useState(
    segmentContentList ? segmentContentList : []
  );
  //theme
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  const handleChangeSearchQuery = (e) => {
    setSearchInputQuery(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchInputQuery) {
      handleContentSearch(searchInputQuery);
    } else {
      setList(segmentContentList ? segmentContentList : []);
      setIsDataLoading(false);
    }
  };
  const handleContentSearch = (searchInputQuery) => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const segmentContentSearchRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
      name: segmentData.name,
      emailAddress: searchInputQuery,
    };
    setIsDataLoading(true);
    dispatch(
      searchEmailSegmentContent(requestHeaders, segmentContentSearchRequestBody)
    );
  };

  useEffect(() => {
    if (searchSegmentContentLoading === false && searchSegmentContentError) {
      setList(
        searchInputQuery ? [] : segmentContentList ? segmentContentList : []
      );
      setIsDataLoading(false);
    }
  }, [
    searchSegmentContentError,
    searchSegmentContentLoading,
    searchSegmentContentData,
  ]);

  useEffect(() => {
    if (
      searchSegmentContentLoading === false &&
      searchSegmentContentData &&
      searchInputQuery
    ) {
      setList(searchSegmentContentData);
      setIsDataLoading(false);
    } else if (!searchInputQuery) {
      setList(segmentContentList ? segmentContentList : []);
      setIsDataLoading(false);
    }
  }, [searchSegmentContentData, searchSegmentContentLoading]);
  useEffect(() => {
    if (!searchInputQuery) {
      setList(segmentContentList ? segmentContentList : []);
      setIsDataLoading(false);
    }
  }, [searchInputQuery]);

  return (
    <div className="segment_content_top_margin">
      <div className="segment-content-title setup-page-section-title">
        {SEGMENT_CONTENTS}
      </div>
      <div className="segment-content-main-div">
        <div className="segment-content-section-inner">
          <div className="mb-16 segment-content-div">
            <div className="upload_right_align">
              <div className="search-bar">
                <form onSubmit={handleSubmit}>
                  <input
                    type="search"
                    className="search-input"
                    placeholder="Search Email"
                    value={searchInputQuery}
                    onChange={handleChangeSearchQuery}
                  />
                </form>
                <div className="search-query-icon-container">
                  <span className="vertical-line"></span>
                  <FiSearch
                    className={`search-icon ${
                      searchInputQuery.length === 0 && "disabled"
                    }`}
                    onClick={() => {
                      if (searchInputQuery !== "") {
                        handleContentSearch(searchInputQuery);
                      } else {
                        setList(segmentContentList ? segmentContentList : []);
                        setIsDataLoading(false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <SmallGenericTable
            Columns={email_segment_columns}
            tableData={list}
            isDataLoading={isDataLoading}
            isRowSelectable={false}
            isRowMultiSelectable={false}
            config={{
              ...popupConfig,
              theme: theme,
              selectedDataKey: "id",
              border: false,
              isDisabled: true,
            }}
            isShowPagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailSegmentContent;
