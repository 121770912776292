export const GET_ENTITIES_REQUEST = "GET_ENTITIES_REQUEST";
export const GET_ENTITIES_SUCCESS = "GET_ENTITIES_SUCCESS";
export const GET_ENTITIES_FAILURE = "GET_ENTITIES_FAILURE";

export const GET_ATTRIBUTES_REQUEST = "GET_ATTRIBUTES_REQUEST";
export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS";
export const GET_ATTRIBUTES_FAILURE = "GET_ATTRIBUTES_FAILURE";
export const SET_ATTRIBUTE_LIST = "SET_ATTRIBUTE_LIST";
export const SET_BLANK_SESSION_ID_ERROR = "SET_BLANK_SESSION_ID_ERROR";
export const SET_SESSION_TIMEOUT_POPUP = "SET_SESSION_TIMEOUT_POPUP"

export const CREATE_ATTRIBUTE_REQUEST = "CREATE_ATTRIBUTE_REQUEST";
export const CREATE_ATTRIBUTE_SUCCESS = "CREATE_ATTRIBUTE_SUCCESS";
export const CREATE_ATTRIBUTE_FAILURE = "CREATE_ATTRIBUTE_FAILURE";
export const CREATE_ATTRIBUTE_SUCCESS_FAILURE = "CREATE_ATTRIBUTE_SUCCESS_FAILURE";

export const GET_ATTRIBUTE_LIST_REQUEST = "GET_ATTRIBUTE_LIST_REQUEST";
export const GET_ATTRIBUTE_LIST_SUCCESS = "GET_ATTRIBUTE_LIST_SUCCESS";
export const GET_ATTRIBUTE_LIST_FAILURE = "GET_ATTRIBUTE_LIST_FAILURE";
export const GET_ATTRIBUTE_LIST_SUCCESS_FAILURE = "GET_ATTRIBUTE_LIST_SUCCESS_FAILURE";

export const GET_ATTRIBUTE_DETAILS_REQUEST = "GET_ATTRIBUTE_DETAILS_REQUEST";
export const GET_ATTRIBUTE_DETAILS_SUCCESS = "GET_ATTRIBUTE_DETAILS_SUCCESS";
export const GET_ATTRIBUTE_DETAILS_FAILURE = "GET_ATTRIBUTE_DETAILS_FAILURE";
export const GET_ATTRIBUTE_DETAILS_SUCCESS_FAILURE = "GET_ATTRIBUTE_DETAILS_SUCCESS_FAILURE";

export const SET_ATTRIBUTE_DETAILS = "SET_ATTRIBUTE_DETAILS";
export const SET_ATTRIBUTE_OPTIONS = "SET_ATTRIBUTE_OPTIONS";
export const SET_ATTRIBUTE_VALIDATION = "SET_ATTRIBUTE_VALIDATION";

export const SET_ATTRIBUTE_DETAILS_LOADING = "SET_ATTRIBUTE_DETAILS_LOADING";
export const RESET_ATTRIBUTE_DETAILS_RESPONSE = "RESET_ATTRIBUTE_DETAILS_RESPONSE";
export const RESET_CREATE_ATTRIBUTE_RESPONSE = "RESET_CREATE_ATTRIBUTE_RESPONSE";


export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";
export const GET_CATEGORIES_SUCCESS_FAILURE = "GET_CATEGORIES_SUCCESS_FAILURE";

export const GET_SUB_CATEGORIES_REQUEST = "GET_SUB_CATEGORIES_REQUEST";
export const GET_SUB_CATEGORIES_SUCCESS = "GET_SUB_CATEGORIES_SUCCESS";
export const GET_SUB_CATEGORIES_FAILURE = "GET_SUB_CATEGORIES_FAILURE";
export const GET_SUB_CATEGORIES_SUCCESS_FAILURE = "GET_SUB_CATEGORIES_SUCCESS_FAILURE";

export const GET_CONTENT_ATTRS_REQUEST = "GET_CONTENT_ATTRS_REQUEST";
export const GET_CONTENT_ATTRS_SUCCESS = "GET_CONTENT_ATTRS_SUCCESS";
export const GET_CONTENT_ATTRS_FAILURE = "GET_CONTENT_ATTRS_FAILURE";
export const GET_CONTENT_ATTRS_SUCCESS_FAILURE = "GET_CONTENT_ATTRS_SUCCESS_FAILURE";


export const RESET_CONTENT_METADATA = "RESET_CONTENT_METADATA";


export const GET_OFFER_TYPES_REQUEST = "GET_OFFER_TYPES_REQUEST";
export const GET_OFFER_TYPES_SUCCESS = "GET_OFFER_TYPES_SUCCESS";
export const GET_OFFER_TYPES_FAILURE = "GET_OFFER_TYPES_FAILURE";
export const GET_OFFER_TYPES_SUCCESS_FAILURE = "GET_OFFER_TYPES_SUCCESS_FAILURE";

export const GET_REPORT_LINK_REQUEST = "GET_REPORT_LINK_REQUEST";
export const GET_REPORT_LINK_SUCCESS = "GET_REPORT_LINK_SUCCESS";
export const GET_REPORT_LINK_FAILURE = "GET_REPORT_LINK_FAILURE";
export const GET_REPORT_LINK_SUCCESS_FAILURE = "GET_REPORT_LINK_SUCCESS_FAILURE";

export const GET_CONNECTOR_TABLE_REQUEST = "GET_CONNECTOR_TABLE_REQUEST";
export const GET_CONNECTOR_TABLE_SUCCESS = "GET_CONNECTOR_TABLE_SUCCESS";
export const GET_CONNECTOR_TABLE_FAILURE = "GET_CONNECTOR_TABLE_FAILURE";
export const GET_CONNECTOR_TABLE_SUCCESS_FAILURE = "GET_CONNECTOR_TABLE_SUCCESS_FAILURE"

export const GET_ADD_CONNECTOR_OPTIONS_REQUEST = "GET_ADD_CONNECTOR_OPTIONS_REQUEST";
export const GET_ADD_CONNECTOR_OPTIONS_SUCCESS = "GET_ADD_CONNECTOR_OPTIONS_SUCCESS";
export const GET_ADD_CONNECTOR_OPTIONS_FAILURE = "GET_ADD_CONNECTOR_OPTIONS_FAILURE";
export const GET_ADD_CONNECTOR_OPTIONS_SUCCESS_FAILURE = "GET_ADD_CONNECTOR_OPTIONS_SUCCESS_FAILURE";

export const CREATE_CONNECTOR_REQUEST = "CREATE_CONNECTOR_REQUEST";
export const CREATE_CONNECTOR_SUCCESS = "CREATE_CONNECTOR_SUCCESS";
export const CREATE_CONNECTOR_FAILURE = "CREATE_CONNECTOR_FAILURE";
export const CREATE_CONNECTOR_SUCCESS_FAILURE = "CREATE_CONNECTOR_SUCCESS_FAILURE";

export const GET_PROGRAM_TYPES_REQUEST = "GET_PROGRAM_TYPES_REQUEST";
export const GET_PROGRAM_TYPES_SUCCESS = "GET_PROGRAM_TYPES_SUCCESS";
export const GET_PROGRAM_TYPES_FAILURE = "GET_PROGRAM_TYPES_FAILURE";
export const IS_SYW_OFFERCODE_EXISTS = "IS_SYW_OFFERCODE_EXISTS";

// export const GET_LIST_STATIC=[
//     {
//         "title": "Message",
//         "key": "1",
//         "header": "Enhance member engagement and rewards experience",
//         "description": "",
//         "disabled": "false",
//         "icon": "messageIcon",
//         "category": "MARKETING",
//         "subCategory": "DEFAULT",
//         "offerType": "Message only",
//         "offerSubType": "MESSAGE",
//         "rightKey": [
//             "popup",
//             "campaigns",
//             "content",
//             "createcontent",
//             "messageoffer"
//         ]
//     },
//     {
//         "title": "Custom",
//         "key": "2",
//         "header": "Create Customised Content",
//         "description": "",
//         "disabled": "false",
//         "icon": "customIcon",
//         "category": "MARKETING",
//         "subCategory": "DEFAULT",
//         "offerType": "Custom",
//         "offerSubType": "CUST",
//         "status": "coming soon",
//         "rightKey": [
//             "popup",
//             "campaigns",
//             "content",
//             "createcontent",
//             "customoffer"
//         ]
//     },
//     {
//         "title": "Point Offer",
//         "key": "3",
//         "header": "Create Point Offer Content",
//         "description": "",
//         "disabled": "true",
//         "status": "coming soon",
//         "icon": "giftIcon",
//         "category": "MARKETING",
//         "subCategory": "DEFAULT",
//         "offerType": "CONTENT",
//         "offerSubType": "PNTO",
//         "rightKey": [
//             "popup",
//             "campaigns",
//             "content",
//             "createcontent",
//             "pointoffer"
//         ]
//     },
//     {
//         "title": "Coupon Offer",
//         "key": "4",
//         "header": "Create Coupon Offer Content",
//         "description": "",
//         "disabled": "true",
//         "status": "coming soon",
//         "icon": "ticketIcon",
//         "category": "MARKETING",
//         "subCategory": "DEFAULT",
//         "offerType": "CONTENT",
//         "offerSubType": "CPNO",
//         "rightKey": [
//             "popup",
//             "campaigns",
//             "content",
//             "createcontent",
//             "cuoponoffer"
//         ]
//     },
//     {
//         "title": "Price Discount Offer",
//         "key": "5",
//         "header": "Create Price Discount Offer Content",
//         "description": "",
//         "disabled": "true",
//         "status": "coming soon",
//         "icon": "percentIcon",
//         "category": "MARKETING",
//         "subCategory": "DEFAULT",
//         "offerType": "CONTENT",
//         "offerSubType": "PRID",
//         "rightKey": [
//             "popup",
//             "campaigns",
//             "content",
//             "createcontent",
//             "pricediscountoffer"
//         ]
//     }
// ]
