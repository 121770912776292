import axios from "axios";
import { setBlankSessionIdError } from "../metaData/MetaDataAction";
import { ERROR_MESSAGE, setBlankSession, setSessionExpire, setToaster } from "../reduxUtil";
import { setErrorToaster } from "../toaster/ToasterAction";
import { GET_REPORT_TOKEN_FAILURE, GET_REPORT_TOKEN_REQUEST, GET_REPORT_TOKEN_SUCCESS, GET_REPORT_TOKEN_SUCCESS_FAILURE, SET_SESSION_TIMEOUT_POPUP } from "./ReportDetilasType";

export const fetchReportToken = (
    requestHeaders,
    requestBody,
  ) => {
    return async (dispatch) => {
      dispatch(fetchReportTokenRequest());
      try {
        const resp = await axios.get(
          process.env.REACT_APP_URL_DOMAIN +
            process.env.REACT_APP_REPORT_TOKEN,
          requestHeaders
        );
  
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          dispatch(fetchReportTokenSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          dispatch(fetchReportTokenSuccess(resp.data));
        }
      } catch (err) {
        dispatch(fetchReportTokenFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      }
    };
  };
  
  const fetchReportTokenRequest = () => {
    return {
      type: GET_REPORT_TOKEN_REQUEST,
    };
  }
  
  const fetchReportTokenSuccess = (apiData) => {
    return {
      type: GET_REPORT_TOKEN_SUCCESS,
      payload: apiData,
    };
  };
  
  const fetchReportTokenFailure = (error) => {
    return {
      type: GET_REPORT_TOKEN_FAILURE,
      payload: error,
    };
  };
  const fetchReportTokenSuccessFailure = (error) => {
    return {
      type: GET_REPORT_TOKEN_SUCCESS_FAILURE,
      payload: error,
    };
  };

  export const setSessionExpirePopUp = () => {
    return {
      type: SET_SESSION_TIMEOUT_POPUP,
    };
  };