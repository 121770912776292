import React, { useEffect, useState } from "react";
import JsonEditor from "../../../../utilsComponent/GenericEditor/JsonEditor";
import { rightDiagonalArrow } from "../../../../utilsComponent/SidebarIcons";
import {
  getRightsFromSession,
  skey,
} from "../../../../utilsComponent/CommonUtil";
import { useDispatch, useSelector } from "react-redux";
import { testApiCall, testApiCallFailure } from "../../../../../tellurideExtRedux/requirements/RequirementsAction";
import { miniloading } from "../../../../LoaderComponents/loadingDesignConstant";

const UpdateRequirementPlayGroundBody = ({ setOpenPlayGround }) => {
  const disabled = false;
  const dispatch = useDispatch();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const requirementData = useSelector((state) => state.requirementData);
  const [validationErrors, setValidationErrors] = useState({
    ruleJson: {
      status: false,
      msg: "",
    },
    actionJson: {
      status: false,
      msg: "",
    },
  });
  const [requestJson, setRequestJson] = useState(JSON.stringify({"requestorID":"OFRI","messageVersion":"03","offerNumber":"00-1248088"}));
  const [responseJson, setResponseJson] = useState("{}");
  function sendRequestHandler() {
    let headers = {
      "Content-Type": "application/json",
      accept: "application/json",
    };
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    dispatch(testApiCall(requestHeaders, requestJson));
  }
  useEffect(() => {
    console.log(requirementData?.testApiResponse);
    if (requirementData?.testApiResponse) {
      setResponseJson(
        JSON.stringify(requirementData?.testApiResponse, null, 2)
      );
    }
  }, [requirementData?.testApiResponse]);
  return (
    <div className="playground-body">
      <div className="playground-container">
        <div className="playground-request">
          <div className="header-section">
            <span>Request</span>
            <div className="header-btn">
              <button
                className="btn btn-primary playground-btn"
                onClick={() => {
                  if(!requirementData?.testApiLoading){
                    sendRequestHandler()
                  }
                }}
                disabled={requirementData?.testApiLoading}
              >
                {requirementData?.testApiLoading ? (
                  miniloading()
                ) : (
                  <span>Send request</span>
                )}
              </button>
              <button
                className="btn btn-secondary playground-btn"
                onClick={() => {
                    dispatch(testApiCallFailure(""))
                    setOpenPlayGround(false)}}
              >
                Close
              </button>
            </div>
          </div>
          <div className="event-proc-main-div">
            <aside className={`info-block ${disabled ? "disabled" : ""}`}>
              <section class="file-marker">
                <div className="box-container">
                  <div className="box-title">Request Json</div>
                  <div className="box-contents">
                    <JsonEditor
                      value={requestJson}
                      height="270px"
                      onChange={(value) => setRequestJson(value)}
                      //   theme={theme}
                      setValidationErrors={setValidationErrors}
                      jsonKey="ruleJson"
                      // isDisabled={disabled}
                    />
                  </div>
                </div>
              </section>
            </aside>
          </div>
        </div>
        <div className="playground-response">
          <div className="header-section">
            <span>Reponse</span>
          </div>
          <div className="event-proc-main-div">
            <aside className={`info-block ${disabled ? "disabled" : ""}`}>
              <section class="file-marker">
                <div className="box-container">
                  <div className="box-title">Response Json</div>
                  <div className="box-contents">
                    <JsonEditor
                      value={responseJson}
                      height="270px"
                      //   onChange={(value) => setResponseJson(value)}
                      //   theme={theme}
                      setValidationErrors={setValidationErrors}
                      jsonKey="ruleJson"
                      isDisabled={true}
                    />
                  </div>
                </div>
              </section>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateRequirementPlayGroundBody;
