import {
  giftIcon,
  messageIcon,
  percentIcon,
  ticketIcon,
} from "../../utilsComponent/SidebarIcons";
import { images } from "../../imagesComponent/Images";

export const CREATE_CONTENT_MODAL_TITLE = "Start setting up Content...";
export const CONTENT_OFFER_TITLE = "What is the Content Type";
export const CREATE_OFFER_POINTS_TITLE = "Points earn offer";
export const CREATE_OFFER_POINTS_HEADER =
  "Create offer to award points to member based on qualifying activity";
export const CREATE_OFFER_POINTS_DESCRIPTION =
  "e.g. Award 10% points on spend of over $50, Award 150,000 in points for making a purchase on Mother's day";
export const CREATE_OFFER_PRICE_TITLE = "Price discount offer";
export const CREATE_OFFER_PRICE_HEADER =
  "Create offer to give price discount to member based on qualifying activity";
export const CREATE_OFFER_PRICE_DESCRIPTION =
  "e.g. 5% discount on Mother's day; $10 off on spend of $50 and more";
export const CONTENT_NAME_LABEL = "Content Name";
export const CONTENT_TYPES = [
  {
    title: "Message",
    key: "1",
    header: "Create Message Content",
    description: "",
    disabled: false,
    icon: messageIcon,
  },
  {
    title: "Point Offer",
    key: "2",
    header: "Create Point Offer Content",
    description: "",
    disabled: true,
    status: "coming soon",
    icon: giftIcon,
  },
  {
    title: "Coupon Offer",
    key: "3",
    header: "Create Coupon Offer Content.",
    description: "",
    disabled: true,
    status: "coming soon",
    icon: ticketIcon,
  },
  {
    title: "Price Discount Offer",
    key: "4",
    header: "Create Price Discount Offer Content",
    description: "",
    disabled: true,
    status: "coming soon",
    icon: percentIcon,
  },
];

export const SCREEN_NAME_CONTENT="CONTENT";

export const CONTENT_OFFER_COLUMNS =  [
  {
    title: "Offer ID",
    key: "id",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "id", isAllowed: true },
  },
  {
    title: "Offer Name",
    key: "name",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 140,
    initialWidth: 140,
    defaultWidth: 140,
    resizable: true,
    reqBodyKey: { attrName: "name", isAllowed: true },
  },
  // {
  //   title: "Type",
  //   key: "type",
  //   type: "select",
  //   visible: true,
  //   filter: true,
  //   sort: true,
  //   advanceFilter: true,
  //   multi: true,
  //   options: [
  //     "Points Earn",
  //     "Price Discount",
  //     "Loyalty Cash",
  //     "Base Earn",
  //     "Base Redeem",
  //     "Custom Offer",
  //     "Mission",
  //     "NonPurchase PointsEarn",
  //     "Event Offer",
  //   ],
  //   minWidth: 85,
  //   initialWidth: 85,
  //   defaultWidth: 85,
  //   resizable: true,
  //   reqBodyKey: { attrName: "type", isAllowed: true },
  //   rights: [
  //     {
  //       key: "Points Earn",
  //       rights: ["filter","loyalty","viewoffer","offertype","pointsearn"],
  //     },
  //     {
  //       key: "Price Discount",
  //       rights: ["filter","loyalty","viewoffer","offertype","pricediscount"],
  //     },
  //     {
  //       key: "Loyalty Cash",
  //       rights: ["filter","loyalty","viewoffer","offertype","loyaltycash"],
  //     },
  //     {
  //       key: "Base Earn",
  //       rights: ["filter","loyalty","viewoffer","offertype","baseearn"],
  //     },
  //     {
  //       key: "Base Redeem",
  //       rights: ["filter","loyalty","viewoffer","offertype","baseredeem"],
  //     },
  //     {
  //       key: "Custom Offer",
  //       rights: ["filter","loyalty","viewoffer","offertype","customoffer"],
  //     },
  //     {
  //       key: "Mission",
  //       rights: ["filter","loyalty","viewoffer","offertype","mission"],
  //     },
  //     {
  //       key: "NonPurchase PointsEarn",
  //       rights: ["filter","loyalty","viewoffer","offertype","nonpurchasepointsearn"],
  //     },
  //     {
  //       key:"Event Offer",
  //       rights: ["filter","loyalty","viewoffer","offertype","eventoffer"]
  //     }
  //   ],
  // },
  {
    title: "Start Date",
    key: "startDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "offerStartDate", isAllowed: true },
  },
  {
    title: "End Date",
    key: "endDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 90,
    initialWidth: 90,
    defaultWidth: 90,
    resizable: true,
    reqBodyKey: { attrName: "offerEndDate", isAllowed: true },
  },
];