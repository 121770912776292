import { React, useState } from "react";
import "./SegmentPopupComponent.style.scss";
import { FaRegTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { miniloading } from "../../../../LoaderComponents/loadingDesignConstant";
import {
	CREATED_BY,
	EVENT_USE_TITLE,
	MEMBER_COUNT,
	MODIFY,
	POPUP_HEADER_SEGMENT,
	PROCEED,
	SEGMENT_USE_TITLE,
	START,
	campaign_segment_columns,
	popupConfig,
} from "../../CampaignBuilderConstants";
import { motion } from "framer-motion/dist/framer-motion";
import Skeleton from "react-loading-skeleton";
import { emptyData } from "../../../../constantComponent/DesignConstant";
import {
	CANCEL,
	DARK,
	OFRI,
	headers,
} from "../../../../constantComponent/Constants";
import {
	getRightsFromSession,
	skey,
} from "../../../../utilsComponent/CommonUtil";
import {
	getGenericTableDetails,
	getSegmentsList,
} from "../../../../../tellurideExtRedux";
import { updateNodeData } from "../../../../utilsComponent/GenericFlow/GenericFlowUtil";
import SmallGenericTable from "../../../../utilsComponent/SmallGenericTable/SmallGenericTable";
const SegmentPopupComponent = ({
	theme,
	setPopupShowProcess,
	currentClickedNode,
	setNodeData,
	isDisabled,
}) => {
	const segmentDetails = useSelector((state) => state.segmentDetailsData);
	const offerData = useSelector((state) => state.offerDetailsData);
  const [searchInput, setSearchInput] = useState("");
	const [triggerList, setTriggerList] = useState(null);
	const [selectedSegment, setSelectedSegment] = useState({
		groupId: currentClickedNode?.data?.value?.action?.groupId,
		groupName: currentClickedNode?.data?.value?.action?.groupName,
		memberCount: currentClickedNode?.data?.value?.action?.memberCount,
		createdBy: currentClickedNode?.data?.value?.action?.memberCount,
		countInTheList: currentClickedNode?.data?.value?.action?.countInTheList,
	});
	const [segmentSelectionValue, setSegmentSelectionValue] = useState({});
	// const securityData = useSelector((state) => state.securityData);
	const [segmentEmailSelectionValue, setSegmentEmailSelectionValue] = useState(currentClickedNode?.data?.value?.action?.groupType ? currentClickedNode?.data?.value?.action?.groupType : "MEMBER");
	const [selectedDataValue, setSelectedDataValue] = useState({});
	let tableLoading = segmentDetails.getSegmentListLoading;
	let tableResponse = segmentDetails.getSegmentListResponse;
	const [filterListBy, setFilterListBy] = useState(segmentEmailSelectionValue === "EMAIL" ? "Email" : "Member");
	useEffect(() => {
		if(segmentEmailSelectionValue) {
			setSelectedSegment((prev) => {
				return {
					...prev,
					groupType: segmentEmailSelectionValue
				}
			})
		}
		setFilterListBy(segmentEmailSelectionValue === "EMAIL" ? "Email" : "Member");
	},[segmentEmailSelectionValue])
	useEffect(() => {
		const segmentNodeData = {
			displayData: {
				groupName: selectedSegment.groupName,
				memberCount: selectedSegment.memberCount,
			},
			value: {
				action: {
					groupId: selectedSegment.groupId,
					groupName: selectedSegment.groupName,
					memberCount: selectedSegment.memberCount,
					createdBy: selectedSegment.memberCount,
					countInTheList: selectedSegment.countInTheList,
					groupType: segmentEmailSelectionValue

				},
			},
		};
		setSegmentSelectionValue(segmentNodeData);
	}, [selectedSegment]);

	function handleSearchChange(e) {
		setSearchInput(e.target.value);
	}

	function rowClickHandler(slectedData) {
		setSelectedDataValue(slectedData);
		setSelectedSegment((prev) => {
			return {
				...prev,
				groupId: slectedData.groupId,
				groupName: slectedData.registrationCode,
				memberCount: slectedData.countInTheList,
				countInTheList: slectedData.countInTheList,
				createdBy: slectedData.createdBy,
				groupType: segmentEmailSelectionValue
			};
		});
	}

	return (
		<section className="segment-popup-component">
			<>
				<div
					className="search-bar-container-campaign"
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<form
						onSubmit={(e) => {
              e.preventDefault();
              setTriggerList((prev) => !Boolean(prev));
						}}
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<input
							type="text"
							placeholder="Search Segments"
							className="attribute-search"
							value={searchInput}
							onChange={handleSearchChange}
							style={{
								width: "31rem",
							}}
						/>
					</form>
				</div>
				<div className="campaign-radiobutton-section flex">
					<div className="flex ml-5 mb-10">
						<input
						type="radio"
						className={`offertype-radio-input
						${isDisabled && "disabled"}`}
						// checked={offer.key === offerType ? true : false}
						disabled={isDisabled}
						name="segmentEmailSelection"
						value="MEMBER"
						checked={
							segmentEmailSelectionValue === "MEMBER"
						}
						onChange={(e) => {
								setSelectedSegment("");
								setSegmentEmailSelectionValue(e.target.value)
							}
						}
						/>
						<p className="mt-3">MEMBER</p>
					</div>
					<div className="flex ml-5 mb-10">
						<input
						type="radio"
						className={`offertype-radio-input
						${isDisabled && "disabled"}`}
						// checked={offer.key === offerType ? true : false}
						disabled={isDisabled}
						name="segmentEmailSelection"
						value="EMAIL"
						checked={
							segmentEmailSelectionValue === "EMAIL"
						}
						onChange={(e) => {
								setSelectedSegment("")
								setSegmentEmailSelectionValue(e.target.value)
							}
						}
						/>
						<p className="mt-3">EMAIL</p>
					</div>
				</div>
				<div className="campaign-type-section trigger-campaign">
					<div className="action-content-table">
						<SmallGenericTable
							Columns={campaign_segment_columns}
							isDataLoading={tableLoading}
							selectedData={[selectedSegment.groupId]}
							isRowSelectable={true}
							isRowMultiSelectable={false}
							rowClickHandler={rowClickHandler}
							config={{
								...popupConfig,
								theme: theme,
								selectedDataKey: "groupId",
								isDisabled: isDisabled,
								border: false,
							}}
							initialPagination={{
								recordPerPage: 10,
								currentPage: 1,
							}}
							API_URL={process.env.REACT_APP_GET_SEGMENTS_LIST}
							RESPONSE_BODY_KEY={"groupList"}
							isShowPagination={true}
							smallGenericTableRquestBody={{
								requestorID: OFRI,
								messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
								search: {
									name: "",
									description: "",
									type: filterListBy ? filterListBy : "",
									lastModifiedBy: "",
									searchQuery: searchInput,
								},
								sort: [],
							}}
							triggerList={triggerList}
							filterListBy={filterListBy}
						/>
					</div>
				</div>
				<footer className="campaign-modal-footer">
					{/* <button
              className="btn btn-secondary"
              onClick={() => {
                setPopupShowProcess(false);
                setNodeData(null);
              }}
            >
              {CANCEL}
            </button> */}
					<button
						className="btn btn-primary continue-setup-btn"
						disabled={
							selectedSegment.groupId && isDisabled === false ? false : true
						}
						onClick={() => {
							updateNodeData(
								currentClickedNode,
								setNodeData,
								"SEGMENT",
								setPopupShowProcess,
								segmentSelectionValue
							);
						}}
					>
						{PROCEED}
					</button>
				</footer>
			</>
		</section>
	);
};

export default SegmentPopupComponent;
