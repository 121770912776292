import React, { useState, useEffect, useCallback } from "react";
import { ZERO } from "../GenericRules/GenericRulesConstants.js";
import { TableAdvanceSearchHeader } from "./GenericTableDesignConstant.jsx";
import moment from "moment";
import "./Table.style.scss";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
	DEFAULT_SEARCH_INPUT_PLACEHOLDER,
	RESET,
	SAVED_FILTERS,
} from "./GenericTableConstants.js";
import { getTableList } from "./GenericTableUtil";
import { useRef } from "react";
import { TableColumns } from "./GenericTableColumns";
import { TableBody } from "./GenericTableBody";
import { Pagination } from "./GenericTablePagination";
import {
	resetGenericTableData,
	setGenericTableSavedFilter,
} from "../../../tellurideExtRedux/index.js";
import { LOCAL, REDUX, SESSION } from "../../constantComponent/Constants.js";
function GenericTable({
	COLUMNS,
	FILTER_SHOW_LIMIT,
	SHOW_HEADER,
	API_URL,
	TABLE_SRC,
	SHOW_HEADER_DASHBOARD,
	HEADER_TITLE,
	dateDuration,
	SHOW_TOOLTIP,
	ADVANCE_SEARCH_PLACEHOLDER = DEFAULT_SEARCH_INPUT_PLACEHOLDER,
	ROW_CLICKABLE,
	ROW_CLICK_HANDLER,
	DATE_TYPE_OPTIONS,
	ADVANCE_SEARCH_HEADER,
	REQUEST_BODY_KEY,
	RESPONSE_BODY_KEY,
	IS_SEARCH_ALLOWED, //added by checks if searching feature is allowed or not (searchQuery to be sent or not)
	refresh = null,
	CSTM_REQ_BODY,
	SAVE_SEARCHED_FILTER = {
		isSaved: true,
		storage: REDUX,
	},
	SELECTED_VALUE,
	INITIAL_PAGINATION = {
		recordPerPage: 50,
		currentPage: 1,
	},
	INITIAL_SEARCH = []

}) {
	const dispatch = useDispatch();
	const offerData = useSelector((state) => state.offerDetailsData);
	const tableData = useSelector((state) => state.genericTableList);
	const securityData = useSelector((state) => state.securityData);

	const tableLoading = tableData.genericTableDataLoading;
	const tableResponse = tableData.genericTableDataResponse;
	const tableError = tableData.genericTableDataError;

	const [list, SetList] = useState([]);
	const [columns, setColumns] = useState(
		COLUMNS.map((item) => ({
			...item,
			// eslint-disable-next-line react-hooks/rules-of-hooks
			ref: useRef(),
		}))
	);
	const [dateType, setDateType] = useState("LiveDate");
	const [dateRange, setDateRange] = useState([
		{
			startDate: moment().subtract(6, "day").valueOf(),
			endDate: moment().valueOf(),
			key: "selection",
		},
	]);

	const [reset, setReset] = useState({
		status: false,
		id: null,
	});
	const [search, setSearch] = useState(INITIAL_SEARCH);
	const [toggleFilter, setToggleFilter] = useState(false);
	const [sort, setSort] = useState(
		COLUMNS.map(({ key }) => {
			return {
				name: key,
				order: RESET,
				priority: null,
			};
		})
	);
	const [columnFilter, setColumnFilter] = useState({
		columnName: "",
		status: true,
		value: "",
	});
	const [filter, setFilter] = useState([]);
	const [pagination, setPagination] = useState({
		recordPerPage: INITIAL_PAGINATION.recordPerPage ? INITIAL_PAGINATION.recordPerPage : 50,
		currentPage: INITIAL_PAGINATION.currentPage ? INITIAL_PAGINATION.currentPage : 1,
		totalPages: 0,
		totalRecords: 0,
	});
	const [extraFiltersPopover, setExtraFiltersPopover] = useState(false);
	const [searchInputQuery, setSearchInputQuery] = useState("");
	const [fetchList, setFetchList] = useState(null);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [layoutLoading, setLayoutLoading] = useState(true);
	const [tableSorted, setTableSorted] = useState(null);
	const [activeIndex, setActiveIndex] = useState(null);
	const tableElement = useRef(null);
	const tableView = useRef(null);
	const paginationRef = useRef(null);
	let currentTableSavedFilters;

	// Get saved filters from storage
	try {
		switch (SAVE_SEARCHED_FILTER.storage) {
			case LOCAL:
				currentTableSavedFilters = JSON.parse(
					localStorage.getItem(`${TABLE_SRC}-${SAVED_FILTERS}`)
				);
				break;
			case SESSION:
				currentTableSavedFilters = JSON.parse(
					sessionStorage.getItem(`${TABLE_SRC}-${SAVED_FILTERS}`)
				);
				break;
			case REDUX:
			default:
				currentTableSavedFilters = tableData[`${TABLE_SRC}-${SAVED_FILTERS}`];
				break;
		}
	} catch (error) {
		// If there is an error in parsing the saved filters, set it to null
		currentTableSavedFilters = null;
	}
	//Function to handle storage change
	function handleSessionStorageChange(event) {
		if (
			event.storageArea === sessionStorage &&
			event.key === `${TABLE_SRC}-${SAVED_FILTERS}`
		) {
			sessionStorage.removeItem(`${TABLE_SRC}-${SAVED_FILTERS}`);
		}
	}
	function handleLocalStorageChange(event) {
		if (
			event.storageArea === localStorage &&
			event.key === `${TABLE_SRC}-${SAVED_FILTERS}`
		) {
			localStorage.removeItem(`${TABLE_SRC}-${SAVED_FILTERS}`);
		}
	}

	// Set the saved filters to the state
	useEffect(() => {
		if (SAVE_SEARCHED_FILTER.isSaved) {
			setIsDataLoading(true);
			if (currentTableSavedFilters) {
				try {
					let savedFilters = currentTableSavedFilters;
					setFilter(savedFilters.filters);
					setSearch(savedFilters.search);
					setSort(savedFilters.sort);
					setDateRange(() => {
						return [
							{
								startDate: new Date(savedFilters?.dateRange[0]?.startDate),
								endDate: new Date(savedFilters?.dateRange[0]?.endDate),
								key: "selection",
							},
						];
					});
					setDateType(savedFilters.dateType);
					setPagination(savedFilters.pagination);
				} catch (_error) {
					if (SAVE_SEARCHED_FILTER.storage === LOCAL) {
						localStorage.removeItem(`${TABLE_SRC}-${SAVED_FILTERS}`);
					} else if (SAVE_SEARCHED_FILTER.storage === SESSION) {
						sessionStorage.removeItem(`${TABLE_SRC}-${SAVED_FILTERS}`);
					} else {
						dispatch(resetGenericTableData());
					}
					setFilter([]);
					setSearch([]);
					setSort(
						COLUMNS.map(({ key }) => {
							return {
								name: key,
								order: RESET,
								priority: null,
							};
						})
					);
					setDateRange([
						{
							startDate: moment().subtract(6, "day").valueOf(),
							endDate: moment().valueOf(),
							key: "selection",
						},
					]);
					setDateType("LiveDate");
					setPagination({
						recordPerPage: 50,
						currentPage: 1,
						totalPages: 0,
						totalRecords: 0,
					});
				}
				setIsDataLoading(false);
				setFetchList((prev) => !prev);
			} else {
				setIsDataLoading(false);
				setFetchList((prev) => !prev);
			}
			switch (SAVE_SEARCHED_FILTER.storage) {
				case LOCAL:
					window.addEventListener("storage", handleLocalStorageChange);
					break;
				case SESSION:
					window.addEventListener("storage", handleSessionStorageChange);
					break;
				default:
					break;
			}
		}
		window.addEventListener("resize", handleResize);

		// Remove event listeners
		return () => {
			dispatch(resetGenericTableData());
			window.removeEventListener("storage", handleLocalStorageChange);
			window.removeEventListener("storage", handleSessionStorageChange);
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	useEffect(() => {
		const controller = new AbortController();
		// If the saved filters are enabled, save the filters to the storage
		if (SAVE_SEARCHED_FILTER.isSaved) {
			if (fetchList !== null) {
				switch (SAVE_SEARCHED_FILTER.storage) {
					case LOCAL:
						localStorage.setItem(
							`${TABLE_SRC}-${SAVED_FILTERS}`,
							JSON.stringify({
								filters: filter,
								search: search,
								sort: sort,
								dateRange: dateRange,
								dateType: dateType,
								dateDuration: dateDuration,
								pagination: pagination,
							})
						);
						break;
					case SESSION:
						sessionStorage.setItem(
							`${TABLE_SRC}-${SAVED_FILTERS}`,
							JSON.stringify({
								filters: filter,
								search: search,
								sort: sort,
								dateRange: dateRange,
								dateType: dateType,
								dateDuration: dateDuration,
								pagination: pagination,
							})
						);
						break;
					case REDUX:
					default:
						dispatch(
							setGenericTableSavedFilter(`${TABLE_SRC}-${SAVED_FILTERS}`, {
								filters: filter,
								search: search,
								sort: sort,
								dateRange: dateRange,
								dateType: dateType,
								dateDuration: dateDuration,
								pagination: pagination,
							})
						);
						break;
				}
			}
			if (isDataLoading) {
				return;
			}
		}
		// Fetch the table data
		getTableList(
			API_URL,
			filter,
			search,
			pagination,
			sort,
			dispatch,
			controller,
			offerData?.authOrganizationName,
			dateRange,
			dateType,
			REQUEST_BODY_KEY,
			columns,
			IS_SEARCH_ALLOWED,
			CSTM_REQ_BODY,
			securityData
		);
		return () => {
			controller.abort();
		};
	}, [fetchList, dateDuration, refresh]);

	//Get the response from the API and set the list
	useEffect(() => {
		if (tableLoading === false && tableResponse) {
			if (tableResponse?.[RESPONSE_BODY_KEY]) {
				SetList(tableResponse[RESPONSE_BODY_KEY]);
				setPagination((prev) => {
					return {
						currentPage: parseInt(tableResponse.pagination.currentPage),
						recordPerPage:
							parseInt(tableResponse.pagination.recordPerPage) ||
							prev.recordPerPage,
						totalPages: parseInt(tableResponse.pagination.totalPages),
						totalRecords: parseInt(tableResponse.pagination.totalRecords),
					};
				});
			}
			setIsDataLoading(false);
		}
	}, [TABLE_SRC, tableLoading, tableResponse]);

	//Below useEffect is used to handle the state of popover of extra filters
	useEffect(() => {
		if (
			[...filter, ...search].slice(FILTER_SHOW_LIMIT).length === ZERO &&
			extraFiltersPopover &&
			search.length === ZERO
		) {
			setExtraFiltersPopover(false);
		}
	}, [FILTER_SHOW_LIMIT, extraFiltersPopover, filter, search, search.length]);

	//Below two useEffects are used to handle the reset of the table
	useEffect(() => {
		if (setSort && SAVE_SEARCHED_FILTER.isSaved ? reset.id : true) {
			setSort((prev) => {
				return prev.map((col) => {
					if (col.priority > reset.id) {
						return {
							...col,
							priority: col.priority - 1,
						};
					} else {
						return col;
					}
				});
			});
		}
	}, [reset, setSort]);
	useEffect(() => {
		if (tableSorted !== null) {
			setFetchList((prev) => !prev);
		}
	}, [tableSorted]);

	//Below useEffect is used to handle the error in the table
	useEffect(() => {
		if (tableError) {
			SetList([]);
			setIsDataLoading(false);
		}
	}, [tableLoading, tableError, tableResponse]);

	//Below function is used to handle the resizing of the columns
	function handleResize() {
		if (!tableView.current) return;

		let tableViewWidth = tableView.current.getBoundingClientRect().width;
		let diff =
			tableViewWidth -
			columns.reduce((acc, col) => {
				if (col.visible === true) {
					return acc + col.defaultWidth;
				}
				return acc;
			}, 0);
		let extraWidth = Math.floor(
			diff / columns.filter((col) => col.visible === true).length - 5
		);
		if (diff > 0) {
			setColumns((cols) => {
				return cols.map((col) => {
					if (col.visible === true) {
						return {
							...col,
							minWidth: col.defaultWidth + extraWidth,
							initialWidth: col.defaultWidth + extraWidth,
						};
					} else {
						return col;
					}
				});
			});
		} else {
			setColumns((cols) => {
				return cols.map((col) => {
					if (col.visible === true) {
						return {
							...col,
							minWidth: col.initialWidth,
							initialWidth: col.defaultWidth,
						};
					} else {
						return col;
					}
				});
			});
		}
		setLayoutLoading(false);
	}

	//Below useEffect is used to handle the resizing of the columns
	useEffect(() => {
		handleResize();
	}, [list]);

	//Below are helper functions to handle the resizing of the columns
	const mouseDown = useCallback((index) => {
		setActiveIndex(index);
	}, []);
	const mouseMove = useCallback(
		(e) => {
			setColumns((columns) => {
				return columns.map((col, i) => {
					if (i === activeIndex) {
						const width = e.clientX - col.ref.current.getBoundingClientRect().x;
						if (width > col.initialWidth) {
							return {
								...col,
								minWidth: width,
							};
						}
						return col;
					}
					return col;
				});
			});
		},
		[activeIndex, columns]
	);
	const removeListeners = useCallback(() => {
		window.removeEventListener("mousemove", mouseMove);
		window.removeEventListener("mouseup", removeListeners);
	}, [mouseMove]);

	const mouseUp = useCallback(() => {
		setActiveIndex(null);
		removeListeners();
	}, [setActiveIndex, removeListeners]);

	useEffect(() => {
		if (
			columns.some((col) => (col.resizable ? true : false)) === true &&
			activeIndex !== null
		) {
			window.addEventListener("mousemove", mouseMove);
			window.addEventListener("mouseup", mouseUp);
		}
		return () => {
			removeListeners();
		};
	}, [activeIndex, mouseMove, mouseUp, removeListeners]);

	return (
		<div className={`tableview`} ref={tableView}>
			<div className={`table-container`} ref={tableElement}>
				<div className="table-advance-search-header">
					{SHOW_HEADER_DASHBOARD && (
						<div className="header-container header-container-dashboard-size">
							<div className="header-bar-container">
								<div className="header-bar">{HEADER_TITLE}</div>
							</div>
						</div>
					)}
					{SHOW_HEADER && (
						<TableAdvanceSearchHeader
							FILTER_SHOW_LIMIT={FILTER_SHOW_LIMIT}
							columns={columns}
							extraFiltersPopover={extraFiltersPopover}
							filter={filter}
							searchInputQuery={searchInputQuery}
							setExtraFiltersPopover={setExtraFiltersPopover}
							setFetchList={setFetchList}
							setFilter={setFilter}
							setSearch={setSearch}
							setSearchInputQuery={setSearchInputQuery}
							setToggleFilter={setToggleFilter}
							toggleFilter={toggleFilter}
							search={search}
							advanceSearchPlaceholder={ADVANCE_SEARCH_PLACEHOLDER}
							dateRange={dateRange}
							setDateRange={setDateRange}
							dateTypeOptions={DATE_TYPE_OPTIONS}
							dateType={dateType}
							setDateType={setDateType}
							ADVANCE_SEARCH_HEADER={ADVANCE_SEARCH_HEADER}
						/>
					)}
					<TableColumns
						columns={columns}
						columnFilter={columnFilter}
						filter={filter}
						setColumnFilter={setColumnFilter}
						setFetchList={setFetchList}
						setFilter={setFilter}
						setReset={setReset}
						setSort={setSort}
						sort={sort}
						setTableSorted={setTableSorted}
						mouseDown={mouseDown}
						mouseUp={mouseUp}
						mouseMove={mouseMove}
						activeIndex={activeIndex}
						layoutLoading={layoutLoading}
					/>
				</div>
				<TableBody
					list={list}
					columns={columns}
					isDataLoading={isDataLoading || tableLoading}
					SHOW_TOOLTIP={SHOW_TOOLTIP}
					ROW_CLICKABLE={ROW_CLICKABLE}
					ROW_CLICK_HANDLER={ROW_CLICK_HANDLER}
					layoutLoading={layoutLoading}
					selectedValue={SELECTED_VALUE}
				/>
				<Pagination
					pagination={pagination}
					setFetchList={setFetchList}
					setPagination={setPagination}
					paginationRef={paginationRef}
					dataFound={list && list.length > ZERO ? true : false}
				/>
			</div>
		</div>
	);
}

export default React.memo(GenericTable);
