import React, { useEffect } from "react";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable";
import {
	COLUMNS,
	DATE_TYPE_OPTIONS,
	OFFER_TABLE_REQ_BODY_KEY,
	OFFER_TABLE_RES_BODY_KEY,
	VIEW_OFFER,
	VIEW_OFFER_PLACEHOLDER,
} from "./TableViewConstants";
import { DETAILS, CUSTOM, OFFER } from "../../constantComponent/PathConstants";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utilsComponent/CommonUtil";
import { getDefaultFilter } from "../../utilsComponent/TableUtil/GenericTableUtil";
import { REDUX, TYPE } from "../../constantComponent/Constants";
const TableView = ({ setRedirectionHeader }) => {
	const advanceSearchHeader = {
		searchBar: true,
		globalFilter: true,
		dateType: true,
		dateRange: true,
	};
	const navigate = useNavigate();
	function handleRowClick(row) {
		if (row.type === "Custom Offer") {
			navigate({
				pathname:
					"/" +
					window.location.pathname.split("/")[1] +
					OFFER +
					CUSTOM +
					DETAILS,
				search: `?offerid=${row.id}`,
			});
		} else {
			navigate({
				pathname:
					"/" + window.location.pathname.split("/")[1] + OFFER + DETAILS,
				search: `?offerid=${row.id}`,
			});
		}
	}
	function generateCustomRequestBody(tableReqBody, dateType, dateRange) {
		let requestBody = {
			...tableReqBody,
			[OFFER_TABLE_REQ_BODY_KEY]: {
				...tableReqBody[OFFER_TABLE_REQ_BODY_KEY],
				dateType: dateType,
				fromDate: formatDate(dateRange[0].startDate),
				toDate: formatDate(dateRange[0].endDate),
				type: tableReqBody[OFFER_TABLE_REQ_BODY_KEY][TYPE]
					? tableReqBody[OFFER_TABLE_REQ_BODY_KEY][TYPE]
					: getDefaultFilter(COLUMNS, TYPE),
			},
		};
		return requestBody;
	}
	useEffect(() => {
		if (setRedirectionHeader) {
			setRedirectionHeader("Configure Offers");
		}
	}, [setRedirectionHeader]);
	return (
		<GenericTable
			COLUMNS={COLUMNS}
			FILTER_SHOW_LIMIT={1}
			SHOW_HEADER={true}
			ADVANCE_SEARCH_HEADER={advanceSearchHeader}
			ADVANCE_SEARCH_PLACEHOLDER={VIEW_OFFER_PLACEHOLDER}
			API_URL={process.env.REACT_APP_GET_OFFER_LIST}
			TABLE_SRC={VIEW_OFFER}
			SHOW_HEADER_DASHBOARD={false}
			SHOW_TOOLTIP={false}
			ROW_CLICKABLE={true}
			ROW_CLICK_HANDLER={handleRowClick}
			DATE_TYPE_OPTIONS={DATE_TYPE_OPTIONS}
			REQUEST_BODY_KEY={OFFER_TABLE_REQ_BODY_KEY}
			RESPONSE_BODY_KEY={OFFER_TABLE_RES_BODY_KEY}
			IS_SEARCH_ALLOWED={true}
			CSTM_REQ_BODY={generateCustomRequestBody}
			CSTM_REQ_BODY_KEY={OFFER_TABLE_REQ_BODY_KEY}
			SAVE_SEARCHED_FILTER={{
				isSaved: true, //  true || false => default : true
				storage: REDUX, // redux  || local || session  => default : "redux"
			}}
		/>
	);
};
export default TableView;
