import axios from "axios";
import {
  API_INVOKE_TEST_FAILURE,
  API_INVOKE_TEST_REQUEST,
  API_INVOKE_TEST_SUCCESS,
  GET_REQUIREMENTS_FAILURE,
  GET_REQUIREMENTS_REQUEST,
  GET_REQUIREMENTS_SUCCESS,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
  UPDATE_REQUIREMENT_RESPONSE,
} from "./RequirementsType";
import {
  ERROR_MESSAGE,
  logoutAuth0,
  setBlankSession,
  setSessionExpire,
  setToaster,
} from "../reduxUtil";
import { setErrorToaster, setSuccessToaster } from "../toaster/ToasterAction";

//API call for fetching requirements:
export const fetchRequirements = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(fetchRequirementsRequest());
    axios
      .post(
        process.env.REACT_APP_AIS_DOMAIN + process.env.REACT_APP_AIS_ASSIST,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          // sessionStorage.setItem("offerNumber","")
          dispatch(fetchRequirementsFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          // setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          // sessionStorage.setItem("offerNumber",resp.data?.offerData?.offerNumber)
          if (requestBody?.parameters?.roleType === "Modifier") {
            dispatch(setSuccessToaster("Requirements updated successfully"));
          }
          dispatch(fetchRequirementsSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(fetchRequirementsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        // setSessionExpire(err, setSessionExpirePopUp, dispatch);
        // setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

const fetchRequirementsRequest = () => {
  return {
    type: GET_REQUIREMENTS_REQUEST,
  };
};
const fetchRequirementsSuccess = (data) => {
  return {
    type: GET_REQUIREMENTS_SUCCESS,
    payload: data,
  };
};

const fetchRequirementsSuccessFailure = (error) => {
  return {
    type: GET_REQUIREMENTS_FAILURE,
    payload: error,
  };
};

const fetchRequirementsFailure = (error) => {
  return {
    type: GET_REQUIREMENTS_FAILURE,
    payload: error,
  };
};
export const updateRequirementsResponse = (data) => {
  return {
    type: UPDATE_REQUIREMENT_RESPONSE,
    payload: data,
  };
};

//API call for updateRequirements:
export const testApiCall = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(testApiCallRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_OFFER_DETAILS,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          // sessionStorage.setItem("offerNumber","")
          dispatch(testApiCallFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          // sessionStorage.setItem("offerNumber",resp.data?.offerData?.offerNumber)
          dispatch(testApiCallSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(testApiCallFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

const testApiCallRequest = () => {
  return {
    type: API_INVOKE_TEST_REQUEST,
  };
};

const testApiCallSuccess = (apiData) => {
  return {
    type: API_INVOKE_TEST_SUCCESS,
    payload: apiData,
  };
};

export const testApiCallFailure = (error) => {
  return {
    type: API_INVOKE_TEST_FAILURE,
    payload: error,
  };
};

export const setBlankSessionIdError = () => {
  return {
    type: SET_BLANK_SESSION_ID_ERROR,
  };
};
export const setSessionExpirePopUp = () => {
  return {
    type: SET_SESSION_TIMEOUT_POPUP,
  };
};
