/* eslint-disable jsx-a11y/anchor-is-valid */
import { AiFillPicture } from "react-icons/ai";
import { images } from "../../imagesComponent/Images";
import FilesDragAndDrop from "../FilesDragAndDropUtil/FilesDragAndDrop";
import { miniloading } from "../../LoaderComponents/loadingDesignConstant";
import Select from "react-select";
import { getCustomStyles } from "../../contentSetupComponent/contentDetailsComponent/ContentDetailsUtil";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import DatePicker from "react-date-picker";
import moment from "moment";
import { changeDateDashFormat, changeDateTimeDashFormat } from "../CommonUtil";
import { useState } from "react";
import TimeKeeper from "react-timekeeper";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

export default function InputField(
  input,
  value,
  setInputFieldsValue,
  handleViewOffer,
  startEndDateRef = null
) {
  const [time, setTime] = useState(
    value
      ? new Date(value).toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      : "00:00:00"
  ); // Initial time state
  const [showTimeKeeper, setShowTimeKeeper] = useState(false); // State for TimeKeeper visibility
  const [datePickerOpen, setDatePickerOpen] = useState(false); // State for DatePicker visibility

  const handleDateChange = (value) => {
    setInputFieldsValue((prev) => ({
      ...prev,
      [input.fieldname]: changeDateDashFormat(
        new Date(value).toLocaleDateString("en-US")
      ),
    }));
    setShowTimeKeeper(true); // Show TimeKeeper when DatePicker value is changed
  };

  const handleTimeChange = (newTime) => {
    setTime(newTime.formatted24.split(":").join(":")); // Update state with new time
    setInputFieldsValue((prev) => ({
      ...prev,
      [`${input.fieldname}_time`]: newTime.formatted24.split(":").join(":"),
    }));
  };

  // const handleBlur = (value, input) => {
  //   const validationSource = input?.validation?.dataValidationSource;
  //   if (value && validationSource && validationFunctions[validationSource]) {
  //     validationFunctions[validationSource](value);
  //   }
  // };

  switch (input.inputType) {
    case "text":
      return (
        <div className="flex column">
          <div className="flex">
            <fieldset
              className={`text-box dynamic-field ${input.fieldname}`}
              disabled={input.disabled}
            >
              <legend
                className={input?.validation?.required ? "required" : null}
              >
                {input.displayName}
              </legend>
              <input
                type="text"
                value={value}
                name={input.fieldname}
                className={`width-95`}
                maxLength={input?.validation?.maxLength}
                minLength={input?.validation?.minLength}
                readOnly={input?.validation?.isReadOnly}
                onChange={(e) => {
                  setInputFieldsValue((prev) => {
                    return {
                      ...prev,
                      [e.target.name]: e.target.value,
                    };
                  });
                }}
                onClick={() => {
                  if(input?.validation?.attributes) {
                    handleViewOffer("Loyalty");
                  }
                }}
                disabled={input?.disabled}
                pattern={input?.validation?.pattern}
                required={input?.validation?.required}
                // onBlur={(e) => handleBlur(e.target.value, input)}
              />
              <small className="error-message">
                {input?.validationStatus?.status === true &&
                  input?.validationStatus?.touched === true &&
                  input?.validationStatus?.message}
              </small>
            </fieldset>
            {input?.validation?.attributes && (
              <div className="date-display" ref={startEndDateRef}>
                {input?.validation?.attributes.map((value) => {
                  return <div className={`error-message fs-12 ${value}`}></div>;
                })}
              </div>
            )}
          </div>
        </div>
      );
    case "textArea":
      return (
        <fieldset
          className="text-box bodyline-text-box dynamic-field"
          disabled={input?.disabled}
        >
          <legend className={input?.validation?.required ? "required" : null}>
            {input.displayName}
          </legend>
          <textarea
            value={value}
            className={`input-text-textarea`}
            rows={input?.rows}
            cols={input?.cols}
            name={input?.fieldname}
            maxLength={input?.validation?.maxLength}
            minLength={input?.validation?.minLength}
            onChange={(e) => {
              setInputFieldsValue((prev) => {
                return {
                  ...prev,
                  [e.target.name]: e.target.value,
                };
              });
            }}
            pattern={input?.validation?.pattern}
            required={input?.validation?.required}
          />
          <small className="error-message text-area">
            {input?.validationStatus?.status === true &&
              input?.validationStatus?.touched === true &&
              input?.validationStatus?.message}
          </small>
        </fieldset>
      );
    case "image":
      return (
        <div className="dynamic-field">
          {!value?.imageId && (
            <FilesDragAndDrop
              onUpload={input.onUpload}
              isSetDisable={input?.disabled || input?.uploading}
              uploadText={input?.uploadText || "Drag & drop Images"}
              acceptFileTypes={
                input?.validation?.imageType
                  ? input?.validation?.imageType
                      .map((imgType) => `.${imgType}`)
                      .join(",")
                  : ".jpg,.jpeg,.png"
              }
              title={input?.displayName}
              name={input?.fieldname}
              key={input?.fieldname}
              sourceComponent={"CONTENT"}
              maxFileSize={input?.validation?.imageSize || 1048576}
              required={input?.validation?.required}
            />
          )}
          {input?.uploading === true && (
            <div className="uploaded-images">{miniloading("Uploading...")}</div>
          )}
          {value?.imageId && (
            <>
              <div className="uploaded-images">
                <legend className="content-label">
                  {input?.displayName} :{" "}
                </legend>
                <div className="image-thumbnail">
                  <AiFillPicture />
                </div>
                <div className="flex">
                  <a
                    onClick={() => input.onDownload(value)}
                    className="truncate"
                  >
                    {value?.imageName}
                  </a>
                  <p className="flex center">
                    <img
                      onClick={() => {
                        if (!input?.disabled) {
                          input?.onDelete(value);
                          setInputFieldsValue((prev) => {
                            return {
                              ...prev,
                              [input?.fieldname]: {},
                            };
                          });
                        }
                      }}
                      className={
                        input?.disabled === true
                          ? "image-delete-icon disabled"
                          : "image-delete-icon"
                      }
                      src={images.trashImage}
                      alt="delete"
                    />
                  </p>
                </div>
              </div>
            </>
          )}
          {input?.uploading !== true && (
            <small className="error-message dynamic-image">
              {input?.validationStatus?.status === true &&
                input?.validationStatus?.touched === true &&
                input?.validationStatus?.message}
            </small>
          )}
        </div>
      );
    case "list":
      return (
        <fieldset
          className="content-fieldset-dropdown dropdown dynamic-field"
          disabled={input?.disabled}
        >
          <legend className={input?.validation?.required ? "required" : null}>
            {input?.displayName}
          </legend>
          <Select
            styles={getCustomStyles(input?.theme)}
            className="content-dropdown"
            value={
              value
                ? {
                    label: value,
                    value: value,
                  }
                : null
            }
            name={input?.fieldname}
            onChange={(e) => {
              setInputFieldsValue((prev) => {
                return {
                  ...prev,
                  [input?.fieldname]: e.value,
                };
              });
            }}
            options={input?.options.map((option) => {
              return {
                label: option.label,
                value: option.value,
              };
            })}
            isDisabled={input?.disabled}
            isSearchable={false}
            placeholder={`Select...`}
            menuPlacement="auto"
            required={input?.validation?.required}
          />
          <small className="error-message dynamic-dropdown">
            {input?.validationStatus?.status === true &&
              input?.validationStatus?.touched === true &&
              input?.validationStatus?.message}
          </small>
        </fieldset>
      );
    case "dateTime":
      return (
        <fieldset className="text-box dynamic-field" disabled={input.disabled}>
          <legend className={input?.validation?.required ? "required" : null}>
            {input.displayName}
          </legend>{" "}
          <DateTimePicker
            // format="y-MM-dd h:mm:ss"
            onChange={(value) => {
              setInputFieldsValue((prev) => {
                const formattedValue = moment(value).isValid()
                  ? moment(value).format("YYYY-MM-DD HH:mm:ss")
                  : null;
                return {
                  ...prev,
                  [input.fieldname]: formattedValue,
                };
              });
            }}
            calendarIcon={
              <img src={images.calendarIcon} className="calendar-icon" />
            }
            clearIcon={null}
            value={value ? moment(value).format("YYYY-MM-DD HH:mm:ss")
              : null}
            className="top-230"
            disabled={input?.disabled}
          />
          <small className="error-message top-30">
            {input?.validationStatus?.status === true &&
              input?.validationStatus?.touched === true &&
              input?.validationStatus?.message}
          </small>
        </fieldset>
      );
    case "date":
      return (
        <fieldset className="text-box dynamic-field" disabled={input.disabled}>
          <legend className={input?.validation?.required ? "required" : null}>
            {input.displayName}
          </legend>
          <DatePicker
            calendarIcon={
              <img src={images.calendarIcon} className="calendar-icon" />
            }
            clearIcon={null}
            format="y-MM-dd"
            onChange={(value) => {
              setInputFieldsValue((prev) => {
                return {
                  ...prev,
                  [input.fieldname]: changeDateDashFormat(
                    new Date(value).toLocaleDateString("en-US")
                  ),
                };
              });
            }}
            value={
              value ? moment(value, "YYYY-MM-DD").toLocaleString("en-US") : null
            }
            disabled={input?.disabled}
            className={"top-230"}
          />
          <small className="error-message">
            {input?.validationStatus?.status === true &&
              input?.validationStatus?.touched === true &&
              input?.validationStatus?.message}
          </small>
        </fieldset>
      );
    default:
      return "";
  }
}
