import "./SegmentDetailsHeader.style.scss";
import { images } from "../../imagesComponent/Images";
import { TfiStatsUp } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	SAVE,
	ACTIVATE,
	EDIT,
	uploadFileHeader,
	OFRI,
	DISABLED,
	ENABLED,
	CANCEL,
	RequestAccess,
} from "../../constantComponent/Constants";
import {
	SEGMENTS,
	VIEW_SEGMENT,
	DETAILS,
	EDIT_SEGMENT,
} from "../../constantComponent/PathConstants";
import {
	createUpdateSegment,
	setSegmentMode,
} from "../../../tellurideExtRedux";
import DeactivateConfirmationComponent from "../../modelComponent/deactivateConfirmationComponent/DeactivateConfirmationComponent";
import ActivateConfirmationComponent from "../../modelComponent/ConfirmationPopUpComponent/ConfirmationPopUpComponent";
import moment from "moment";
import {
	miniloading,
	miniloadingWithoutMessage,
} from "../../LoaderComponents/loadingDesignConstant";
import { useAuth0 } from "@auth0/auth0-react";
import {
	changeDateDashFormat,
	checkRightsIsEditable,
	checkRightsIsVisible,
	getAllRights,
	getRightsFromSession,
	getRightsKey,
	skey,
	trimExtraSpaces,
} from "../../utilsComponent/CommonUtil";
import { SEGMENT_SAVE } from "../../errorsComponent/ErrorConstant";

const SegmentDetailsHeader = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const offerData = useSelector((state) => state.offerDetailsData);
	const segmentDetailsData = useSelector((state) => state.segmentDetailsData);
	const segmentData = JSON.parse(
		JSON.stringify(segmentDetailsData.segmentDetailsResponse)
	);
	const UpdatedSegmentData = JSON.parse(
		JSON.stringify(segmentDetailsData.updateSegmentDetailsResponse)
	);
	const [offerNameEditable, setOfferNameEditable] = useState(false);
	const [clickedBtn, setClickedBtn] = useState("");
	const [isSetDisable, setDisable] = useState();
	const [offerDetailValues, setValues] = useState(segmentData?.offerData);
	const [updatedOfferName, setUpdatedOfferName] = useState(
		segmentData?.offerName
	);
	const [showDeactivateConfirmationPopup, setShowDeactivateConfirmationPopup] =
		useState(false);
	const [showActivateConfirmationPopup, setShowActivateConfirmationPopup] =
		useState(false);
	const { user } = useAuth0();
	const securityData = useSelector((state) => state.securityData);
	const [showOfferPopup, setShowOfferPopup] = useState();

	const handleEditClickOfferName = () => {
		setOfferNameEditable(true);
	};
	const handleOfferNameChange = (e) => {
		setUpdatedOfferName(e.target.value);
	};
	const saveOfferName = () => {
		setOfferNameEditable(!offerNameEditable);
	};
	function showErrorToaster(obj) {
		const inputStartDate = document.getElementById("input-start-date");
		if (!inputStartDate.value) {
			const backdrop = document.querySelector(".backdrop");
			backdrop.classList.add("show");
			const startDate = document.getElementById("start-date");
			startDate.classList.add("error-start-date");
			setTimeout(() => {
				hideErrorToaster(); // after 5 seconds hide error toaster message
			}, 5000);
		}
		return;
	}
	// hide error toaster message
	function hideErrorToaster(obj) {
		const backdrop = document.querySelector(".backdrop");
		backdrop.classList.remove("show");
		return;
	}

	const handleClickOnCancel = (e) => {
		navigate(
			"/" + window.location.pathname.split("/")[1] + SEGMENTS + VIEW_SEGMENT
		);
	};

	function saveSegment() {
		uploadFileHeader.sessionId = skey();
		uploadFileHeader.organization = offerData?.authOrganizationName;
		uploadFileHeader.requestAccess = getRightsFromSession();
		const headers = uploadFileHeader;
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		let requestBody = new FormData();
		requestBody.append("requestorID", OFRI);
		requestBody.append(
			"messageVersion",
			process.env.REACT_APP_MESSAGE_VERSION_01
		);
		requestBody.append("name", UpdatedSegmentData.name);
		requestBody.append("id", UpdatedSegmentData.id);
		requestBody.append("description", UpdatedSegmentData.description);
		requestBody.append("type", UpdatedSegmentData.groupEntity);

		requestBody.append("organization", offerData?.organizationName);
		requestBody.append("userId", user ? user.email : "");
		if (props.uploadedFile != undefined && props.uploadedFile != "") {
			requestBody.append("file", props.uploadedFile);
			requestBody.append("action", UpdatedSegmentData.action);
		}
		dispatch(
			createUpdateSegment(
				requestHeaders,
				requestBody,
				true,
				DISABLED,
				SEGMENT_SAVE,
				navigate,
				window.location.pathname.includes(DETAILS) ? true : false
			)
		);
		segmentDetailsData.isNewSegmentFlow = false;
		props.setUploadedFile("");
	}

	return (
		<>
			<div className="segment-navigation-top-div">
				<div className="empty-space-div"></div>
				<div className="navigation-text">
					<p className="offer-name setup-name-header">{segmentData.name}</p>
				</div>
				<div className="navigation-buttons">
					<button
						className="save-button btn btn-primary"
						onClick={handleClickOnCancel}
					>
						{CANCEL}
					</button>

					{segmentDetailsData.segmentMode === DISABLED ? (
						<>
							{checkRightsIsVisible(
								getRightsKey(["button", "segmentation", "detail", "modify"])
							) ? (
								<button
									className="save-button btn btn-primary"
									onClick={(e) => {
										dispatch(setSegmentMode(ENABLED));
									}}
									disabled={
										!checkRightsIsEditable(
											getRightsKey([
												"button",
												"segmentation",
												"detail",
												"modify",
											])
										)
									}
								>
									Modify
								</button>
							) : null}
						</>
					) : segmentDetailsData.segmentEdited ? (
						<>
							{checkRightsIsVisible(
								getRightsKey(["button", "segmentation", "detail", "save"])
							) ? (
								<button
									className="save-button btn btn-primary"
									onClick={(e) => {
										saveSegment();
									}}
									disabled={
										!checkRightsIsEditable(
											getRightsKey(["button", "segmentation", "detail", "save"])
										)
									}
								>
									{SAVE}
								</button>
							) : null}
						</>
					) : (
						<button className="save-button btn btn-secondary disabled">
							{SAVE}
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default SegmentDetailsHeader;
