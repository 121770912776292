import {
	GET_OFFER_DETAILS_REQUEST,
	GET_OFFER_DETAILS_SUCCESS,
	GET_OFFER_DETAILS_FAILURE,
	GET_OFFER_DETAILS_SUCCESS_FAILURE,
	SET_COMMUNICATE_DATA,
	SET_OFFER_DATA,
	SET_REWARD_DATA,
	SET_RULE_DATA,
	SET_CAP_DATA,
	SET_EVENT_OFFER_RULE_DATA,
	SET_EVENT_OFFER_ACTION_DATA,
	CREATE_UPDATE_OFFER_REQUEST,
	CREATE_UPDATE_OFFER_SUCCESS,
	CREATE_UPDATE_OFFER_FAILURE,
	CREATE_UPDATE_OFFER_SUCCESS_FAILURE,
	UPDATE_OFFER_STATUS_REQUEST,
	UPDATE_OFFER_STATUS_SUCCESS,
	UPDATE_OFFER_STATUS_SUCCESS_FAILURE,
	UPDATE_OFFER_STATUS_FAILURE,
	GET_OFFER_LIST_REQUEST,
	GET_OFFER_LIST_SUCCESS,
	GET_OFFER_LIST_SUCCESS_FAILURE,
	GET_OFFER_LIST_FAILURE,
	RESET_VALIDATION_ERRORS,
	UPDATE_VALIDATION_ERRORS,
	UPLOAD_IMAGE_CONTENT_REQUEST,
	UPLOAD_IMAGE_CONTENT_SUCCESS,
	UPLOAD_IMAGE_CONTENT_SUCCESS_FAILURE,
	UPLOAD_IMAGE_CONTENT_FAILURE,
	DELETE_IMAGE_CONTENT_REQUEST,
	DELETE_IMAGE_CONTENT_SUCCESS,
	DELETE_IMAGE_CONTENT_SUCCESS_FAILURE,
	DELETE_IMAGE_CONTENT_FAILURE,
	SET_LINKED_DATA,
	PREVIEW_IMAGE_CONTENT_REQUEST,
	PREVIEW_IMAGE_CONTENT_SUCCESS,
	PREVIEW_IMAGE_CONTENT_SUCCESS_FAILURE,
	PREVIEW_IMAGE_CONTENT_FAILURE,
	LOAD_IMAGE_CONTENT_REQUEST,
	LOAD_IMAGE_CONTENT_SUCCESS,
	LOAD_IMAGE_CONTENT_SUCCESS_FAILURE,
	LOAD_IMAGE_CONTENT_FAILURE,
	SEND_EMAIL_REQUEST,
	SEND_EMAIL_SUCCESS,
	SEND_EMAIL_SUCCESS_FAILURE,
	SEND_EMAIL_FAILURE,
	SET_OFFER_CHANGE_STATUS,
	SET_ORGANZTATION_NAME,
	SET_UPLOAD_IMAGE,
	SET_BLANK_SESSION_ID_ERROR,
	SET_SESSION_TIMEOUT_POPUP,
	SET_AUTH_ORGANZTATION_NAME,
	SET_OFFER_VALIDATION_ERRORS,
	SET_OFFER_ATTRIBUTE_DATA,
	CREATE_UPDATE_CONTENT_OFFER_REQUEST,
	CREATE_UPDATE_CONTENT_OFFER_SUCCESS,
	CREATE_UPDATE_CONTENT_OFFER_SUCCESS_FAILURE,
	CREATE_UPDATE_CONTENT_OFFER_FAILURE,
	RESET_UPLOAD_IMAGE_DATA,
	SET_UPDATE_STATUS_LOADED,
	SET_EVENT_PROCESSING_ERROR,
	GET_ACTION_VERSION_JSON_REQUEST,
	GET_ACTION_VERSION_JSON_SUCCESS,
	GET_ACTION_VERSION_JSON_FAILURE,
	SET_ACTION_JSON,
	RESET_ACTION_JSON_RESPONSE,
	GET_REWARD_OFFER_CODE_REQUEST,
	GET_REWARD_OFFER_CODE_SUCCESS,
	GET_REWARD_OFFER_CODE_FAILURE,
	GET_REWARD_OFFER_CODE_SUCCESS_FAILURE,
} from "./OfferDetailsType";

const initialState = {
	offerDetailsLoaded: false,
	offerDetailsResponse: "",
	offerDetailsPageError: "",
	offerData: "",
	offerStatus: "",
	createUpdateOfferResponseStatus: "",
	rewardData: "",
	ruleData: "",
	communicateData: "",
	offerAttributes: "",
	capData: "",
	eventRuleData: "",
	eventActionData: "",
	updateOfferDetailResponse: "",
	upateOfferData: "",
	updateRewardData: "",
	updateRuleData: "",
	updateCommunicateData: "",
	updateOfferAttributes: "",
	updateCapData: "",
	updateEventRuleData: "",
	updateEventActionData: "",
	createUpdateOfferLoading: false,
	createUpdateOfferResponse: "",
	createUpdateOfferPageError: "",
	apiError: "",
	apiSuccessError: "",
	offerName: "",
	updateOfferStatusResponse: "",
	updateOfferStatusLoading: false,
	offerDataValidationErrors: [],
	getOfferListResponse: "",
	getOfferListLoading: false,
	imageUploadStatusLoading: "",
	imageUploadStatusResponse: "",
	linkedOffers: "",
	uploadImageContentResponse: "",
	uploadImageContentLoading: false,
	uploadImageContentError: "",
	deleteImageContentLoading: false,
	deleteImageResponse: "",
	loadImageContentLoading: false,
	loadImageContentResponse: "",
	previewImageContentLoading: false,
	previewImageContantResponse: "",
	updateLinkedOfferData: "",
	sendEmailLoading: false,
	sendEmailResponse: "",
	getOfferListError: "",
	previewImageContantSuccessError: "",
	isOfferChanged: false,
	organizationName: "",
	offerNumber: "",
	responseLoaded: 1,
	imageUploadJson: "",
	blankSessionIdError: false,
	showExpiredSessionPopup: false,
	authOrganizationName: "",
	offerValidationErrors: {
		createOffer: {
			discount: false,
			rules: false,
			rewards: false,
			offerDetails: false,
			communication: false,
			pointValidity: false,
		},
	},
	createUpdateContentOfferLoading: false,
	createUpdateContentOfferResponse: "",
	createUpdateContentOfferPageError: "",
	createUpdateContentOfferResponseStatus: "",
	eventProccessingOfferError: false,
	actionVersionJsonLoading: false,
	actionVersionJsonResponse: "",
	actionVersionJsonError: "",
	uiActionJson: {},
	rewardOfferCodeResponse: "",
	rewardOfferCodeLoading: false,
	rewardOfferCodeErrorFlag: true,
	rewardOfferCodeError: ""
};

export const OfferDetailsReducer = (state = initialState, action) => {
	let capDataExpression =
		action?.payload?.actions?.action &&
		action?.payload?.actions?.action.length > 0
			? action?.payload?.actions?.action[0]?.actionProperties?.actionProperty?.filter(
					({ propertyType }) => propertyType === "CAP"
			  )[0]
			: null;
	switch (action.type) {
		case GET_OFFER_DETAILS_REQUEST:
			return {
				...state,
				createUpdateOfferResponseStatus: "",
				offerDetailsLoaded: false,
				offerResponseStatus: "",
				responseLoaded: 2,
				isOfferChanged: false,
				updateCommunicateData: "",
				updateLinkedOfferData: "",
				updateOfferData: "",
				updateRewardData: "",
				capData: "",
				updateCapData: "",
				updateRuleData: "",
				updateOfferAttributes: "",
			};
		case GET_OFFER_DETAILS_SUCCESS:
			return {
				...state,
				createUpdateOfferResponseStatus: "",
				createUpdateOfferResponse: "",
				createUpdateOfferPageError: "",
				offerDataValidationErrors: [],
				offerDetailsLoaded: true,
				offerDetailsResponse: action.payload,
				updateOfferDetailResponse: action.payload,
				offerData: action.payload.offerData,
				ruleData: action.payload.rules,
				rewardData: action?.payload?.actions,
				offerAttributes: action?.payload?.offerAttributes,
				capData: capDataExpression,
				updateOfferData: action.payload.offerData,
				updateRewardData: action.payload.actions,
				updateRuleData: action.payload.rules,
				offerStatus: action.payload.offerData.offerStatus,
				offerName: action.payload.offerData.offerPointsDollarName,
				linkedOffers: action.payload.linkedOffers,
				updateLinkedOfferData: action.payload.linkedOffers,
				updateOfferAttributes: action.payload.offerAttributes,
				updateCapData: capDataExpression,
				apiSuccessError: "",
				// communicateData: "",
				responseLoaded: 3,
				imageUploadJson: "",
				uploadImageContentResponse: "",
				uploadImageContentError: "",
				createUpdateContentOfferResponse: "",
			};
		case GET_OFFER_DETAILS_FAILURE:
			return {
				...state,
				createUpdateOfferResponseStatus: "",
				offerDetailsLoaded: true,
				offerDetailsPageError: action.payload,
				apiError: action.payload,
				imageUploadJson: "",
			};
		case GET_OFFER_DETAILS_SUCCESS_FAILURE:
			return {
				...state,
				createUpdateOfferResponseStatus: "",
				offerDetailsLoaded: true,
				offerDetailsPageError: action.payload,
				apiSuccessError: action.payload,
				imageUploadJson: "",
			};

		case SET_REWARD_DATA:
			return {
				...state,
				updateRewardData: action.payload,
			};
		case SET_RULE_DATA:
			return {
				...state,
				updateRuleData: action.payload,
			};
		case SET_OFFER_DATA:
			return {
				...state,
				updateOfferData: action.payload,
			};
		case SET_LINKED_DATA:
			return {
				...state,
				updateLinkedOfferData: action.payload,
			};
		case SET_COMMUNICATE_DATA:
			return {
				...state,
				updateCommunicateData: action.payload,
			};
		case SET_OFFER_ATTRIBUTE_DATA:
			return {
				...state,
				updateOfferAttributes: action.payload,
			};
		case SET_CAP_DATA:
			return {
				...state,
				updateCapData: action.payload,
			};
		case SET_EVENT_OFFER_RULE_DATA:
			return {
				...state,
				updateEventRuleData: action.payload,
			};
		case SET_EVENT_OFFER_ACTION_DATA:
			return {
				...state,
				updateEventActionData: action.payload,
			};
		case CREATE_UPDATE_OFFER_REQUEST:
			return {
				...state,
				createUpdateOfferLoading: true,
				offerDetailsLoaded: false,
				createUpdateOfferResponseStatus: "",
			};
		case CREATE_UPDATE_OFFER_SUCCESS:
			return {
				...state,
				createUpdateOfferResponse: action.payload,
				createUpdateOfferLoading: false,
				createUpdateOfferResponseStatus: action.payload.status,
				offerNumber: action.payload.offerNumber,
			};
		case CREATE_UPDATE_OFFER_SUCCESS_FAILURE:
			return {
				...state,
				offerDetailsLoaded: true,
				createUpdateOfferResponse: action.payload,
				createUpdateOfferLoading: false,
				apiSuccessError: action.payload,
			};
		case CREATE_UPDATE_OFFER_FAILURE:
			return {
				...state,
				offerDetailsLoaded: true,
				createUpdateOfferLoading: false,
				createUpdateOfferPageError: action.payload,
				apiError: action.payload,
			};
		case UPDATE_VALIDATION_ERRORS:
			return {
				...state,
				offerDataValidationErrors: [action.payload],
			};
		case RESET_VALIDATION_ERRORS:
			return {
				...state,
				offerDataValidationErrors: [],
			};
		case UPDATE_OFFER_STATUS_REQUEST:
			return {
				...state,
				updateOfferStatusLoading: true,
				updateOfferStatusResponse: "",
			};
		case UPDATE_OFFER_STATUS_SUCCESS:
			return {
				...state,
				updateOfferStatusResponse: action.payload,
				updateOfferStatusLoading: false,
				offerStatus: action.payload.offerInfoResponse[0].offerStatus,
			};
		case UPDATE_OFFER_STATUS_SUCCESS_FAILURE:
			return {
				...state,
				//updateOfferStatusResponse: action.payload,
				updateOfferStatusLoading: false,
				//offerStatus: action.payload.status,
				apiSuccessError: action.payload,
			};
		case UPDATE_OFFER_STATUS_FAILURE:
			return {
				...state,
				updateOfferStatusLoading: false,
				//createUpdateOfferPageError: action.payload,
				apiError: action.payload,
			};
		case GET_OFFER_LIST_REQUEST:
			return {
				...state,
				getOfferListLoading: true,
				getOfferListError: null,
				// responseLoaded: 1,
				// offerDetailsLoaded: false,
				//getOfferListResponse: "",
			};
		case SET_UPDATE_STATUS_LOADED:
			return {
				...state,
				updateOfferStatusLoading: false,
				responseLoaded: 3,
				offerDetailsLoaded: true,
				//getOfferListResponse: "",
			};
		case GET_OFFER_LIST_SUCCESS:
			return {
				...state,
				getOfferListResponse: action.payload,
				getOfferListLoading: false,
				getOfferListError: null,
				apiSuccessError: "",
				responseLoaded: 3,
				//offerStatus: action.payload.offerInfoResponse[0].offerStatus,
			};
		case GET_OFFER_LIST_SUCCESS_FAILURE:
			return {
				...state,
				//updateOfferStatusResponse: action.payload,
				getOfferListLoading: false,
				//offerStatus: action.payload.status,
				apiSuccessError: action.payload,
				getOfferListError: action.payload,
				responseLoaded: 3,
			};
		case GET_OFFER_LIST_FAILURE:
			return {
				...state,
				getOfferListLoading: false,
				//createUpdateOfferPageError: action.payload,
				getOfferListError: action.payload,
				apiError: action.payload,
				responseLoaded: 3,
			};
		case UPLOAD_IMAGE_CONTENT_REQUEST:
			return {
				...state,
				uploadImageContentLoading: true,
				uploadImageContentError: "",
			};
		case UPLOAD_IMAGE_CONTENT_SUCCESS:
			return {
				...state,
				uploadImageContentLoading: false,
				uploadImageContentResponse: action.payload,
				uploadImageContentError: "",
			};
		case UPLOAD_IMAGE_CONTENT_SUCCESS_FAILURE:
			return {
				...state,
				uploadImageContentLoading: false,
				apiSuccessError: action.payload,
				uploadImageContentError: true,
			};
		case UPLOAD_IMAGE_CONTENT_FAILURE:
			return {
				...state,
				uploadImageContentLoading: false,
				apiError: action.payload,
				uploadImageContentError: true,
			};
		case DELETE_IMAGE_CONTENT_REQUEST:
			return {
				...state,
				deleteImageContentLoading: true,
			};
		case DELETE_IMAGE_CONTENT_SUCCESS:
			return {
				...state,
				deleteImageContentLoading: false,
				deleteImageResponse: action.payload,
			};
		case DELETE_IMAGE_CONTENT_SUCCESS_FAILURE:
			return {
				...state,
				deleteImageContentLoading: false,
				apiSuccessError: action.payload,
			};
		case DELETE_IMAGE_CONTENT_FAILURE:
			return {
				...state,
				deleteImageContentLoading: false,
				apiError: action.payload,
			};
		case LOAD_IMAGE_CONTENT_REQUEST:
			return {
				...state,
				loadImageContentLoading: true,
			};
		case LOAD_IMAGE_CONTENT_SUCCESS:
			return {
				...state,
				loadImageContentLoading: false,
				loadImageContentResponse: action.payload,
			};
		case LOAD_IMAGE_CONTENT_SUCCESS_FAILURE:
			return {
				...state,
				loadImageContentLoading: false,
				apiSuccessError: action.payload,
			};
		case LOAD_IMAGE_CONTENT_FAILURE:
			return {
				...state,
				loadImageContentLoading: false,
				apiError: action.payload,
			};
		case PREVIEW_IMAGE_CONTENT_REQUEST:
			return {
				...state,
				previewImageContentLoading: true,
			};
		case PREVIEW_IMAGE_CONTENT_SUCCESS:
			return {
				...state,
				previewImageContentLoading: false,
				previewImageContantResponse: action.payload,
			};
		case PREVIEW_IMAGE_CONTENT_SUCCESS_FAILURE:
			return {
				...state,
				previewImageContentLoading: false,
				previewImageContantSuccessError: action.payload,
				apiSuccessError: action.payload,
			};
		case PREVIEW_IMAGE_CONTENT_FAILURE:
			return {
				...state,
				previewImageContentLoading: false,
				apiError: action.payload,
			};
		case SEND_EMAIL_REQUEST:
			return {
				...state,
				sendEmailLoading: true,
			};
		case SEND_EMAIL_SUCCESS:
			return {
				...state,
				sendEmailLoading: false,
				sendEmailResponse: action.payload,
			};
		case SEND_EMAIL_SUCCESS_FAILURE:
			return {
				...state,
				sendEmailLoading: false,
				apiSuccessError: action.payload,
			};
		case SEND_EMAIL_FAILURE:
			return {
				...state,
				sendEmailLoading: false,
				apiError: action.payload,
			};
		case SET_OFFER_CHANGE_STATUS:
			return {
				...state,
				isOfferChanged: action.payload,
			};
		case SET_ORGANZTATION_NAME:
			return {
				...state,
				organizationName: action.payload,
			};
		case SET_AUTH_ORGANZTATION_NAME:
			return {
				...state,
				authOrganizationName: action.payload,
			};
		case SET_UPLOAD_IMAGE:
			return {
				...state,
				imageUploadJson: action.payload,
			};
		case SET_BLANK_SESSION_ID_ERROR:
			return {
				...state,
				blankSessionIdError: true,
			};
		case SET_SESSION_TIMEOUT_POPUP:
			return {
				...state,
				showExpiredSessionPopup: true,
			};
		case SET_OFFER_VALIDATION_ERRORS:
			return {
				...state,
				offerValidationErrors: action.payload,
			};
		case CREATE_UPDATE_CONTENT_OFFER_REQUEST:
			return {
				...state,
				createUpdateContentOfferLoading: true,
				offerDetailsLoaded: false,
				createUpdateContentOfferResponseStatus: "",
			};
		case CREATE_UPDATE_CONTENT_OFFER_SUCCESS:
			return {
				...state,
				createUpdateContentOfferResponse: action.payload,
				createUpdateContentOfferLoading: false,
				createUpdateContentOfferResponseStatus: action.payload.status,
				offerNumber: action.payload.offerNumber,
			};
		case CREATE_UPDATE_CONTENT_OFFER_SUCCESS_FAILURE:
			return {
				...state,
				offerDetailsLoaded: true,
				createUpdateContentOfferResponse: action.payload,
				createUpdateContentOfferLoading: false,
				apiSuccessError: action.payload,
			};
		case CREATE_UPDATE_CONTENT_OFFER_FAILURE:
			return {
				...state,
				offerDetailsLoaded: true,
				createUpdateContentOfferLoading: false,
				createUpdateContentOfferPageError: action.payload,
				apiError: action.payload,
			};
		case RESET_UPLOAD_IMAGE_DATA:
			return {
				...state,
				uploadImageContentResponse: "",
				uploadImageContentLoading: false,
				uploadImageContentError: "",
			};
		case SET_EVENT_PROCESSING_ERROR:
			return {
				...state,
				eventProccessingOfferError: action.payload,
			};
		case GET_ACTION_VERSION_JSON_REQUEST:
			return {
				...state,
				actionVersionJsonLoading: true,
			};
		case GET_ACTION_VERSION_JSON_SUCCESS:
			return {
				...state,
				actionVersionJsonLoading: false,
				actionVersionJsonResponse: action.payload,
			};
		case GET_ACTION_VERSION_JSON_FAILURE:
			return {
				...state,
				actionVersionJsonLoading: false,
				actionVersionJsonError: action.payload,
			};
		case SET_ACTION_JSON:
			return {
				...state,
				uiActionJson: action.payload,
			};
		case RESET_ACTION_JSON_RESPONSE:
			return {
				...state,
				actionVersionJsonResponse: "",
				actionVersionJsonLoading: false,
				actionVersionJsonError: "",
			};
		case GET_REWARD_OFFER_CODE_REQUEST:
			return {
				...state,
				rewardOfferCodeLoading: true,
			}
		case GET_REWARD_OFFER_CODE_SUCCESS:
			return {
				...state,
				rewardOfferCodeLoading: false,
				rewardOfferCodeErrorFlag: false,
				rewardOfferCodeResponse: action.payload,
			}
		case GET_REWARD_OFFER_CODE_FAILURE:
			return {
				...state,
				rewardOfferCodeResponse: "",
				// createUpdateContentOfferResponse: action.payload,
				rewardOfferCodeLoading: false,
				rewardOfferCodeErrorFlag: true,
				apiError: action.payload,
			}
		case GET_REWARD_OFFER_CODE_SUCCESS_FAILURE:
			return {
				...state,
				rewardOfferCodeResponse: "",
				rewardOfferCodeLoading: false,
				rewardOfferCodeErrorFlag: true,
				rewardOfferCodeError: action.payload,
				apiSuccessError: action.payload,
			}
		default:
			return state;
	}
};
