import React, { useEffect, useState } from "react";
import "../../LoaderComponents/Loading.style.scss";
import { REPORT_REQUEST_ACCESS } from "../../constantComponent/Constants";
import { getRightsFromSession, skey } from "../../utilsComponent/CommonUtil";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportToken } from "../../../tellurideExtRedux/reportsDetails/ReportDetailsAction";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { REPORT_LOADER_SOURCE } from "../../LoaderComponents/LoadingConstant";
import Loading from "../../LoaderComponents/Loading";
import "./ReportWrapper.styles.scss";
import Error from "../../errorsComponent/Error";
const ReportsWrapper = () => {
  const dispatch = useDispatch();
  const { reportToken, dashboardId } = useSelector(
    (state) => state.reportTokenData
  );
  const [loaded, setLoaded] = useState();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      accept: "application/json",
      // requestAccess: "sec_offerdetails_access~0~0",
      //orgName:"acme"
    };
    headers.sessionId = skey();
    // headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    headers.reporttype = "superset";
    headers.sessionid = skey();
    headers.requestaccess = REPORT_REQUEST_ACCESS;
    const requestHeaders = {
      headers,
    };
    dispatch(fetchReportToken(requestHeaders));
  }, []);
  useEffect(() => {
    if (reportToken) {
      const embed = async () => {
        await embedDashboard({
          id: dashboardId, // given by the Superset embedding UI
          supersetDomain: process.env.REACT_APP_SUPERSET_DOMAIN,
          mountPoint: document.getElementById("dashboard"), // html element in which iframe render
          fetchGuestToken: () => reportToken,
          dashboardUiConfig: {
            hideTitle: true,
            hideChartControls: true,
            hideTab: true,
          },
        });
      };

      var dash = document.getElementById("dashboard");
      if (dash) {
        embed();

        var ifrm = dash?.childNodes[0];
        if (ifrm) {
          ifrm.style.width = "0%";
          ifrm.style.height = "0%";
        }
      }
      fetch(ifrm.src, { method: "GET" })
        .then((response) => {
          if (response.ok) {
            // If the response is OK, set the iframe src
            setLoaded(true);
            console.log("Iframe successful");
          } else {
            // Handle non-200 HTTP status codes
            setLoaded(false);
            console.error(
              "Failed to load iframe content, status:",
              response.status
            );
            // showErrorMessage();
          }
        })
        .catch((error) => {
          // Handle network errors or other issues
          setLoaded(false);

          console.error("Network error while fetching iframe content:", error);
          // showErrorMessage();
        });
    }
  }, [reportToken, dashboardId]);
  useEffect(() => {
    if (loaded !== undefined && loaded === true) {
      var dash = document.getElementById("dashboard");
      if (dash) {
        var ifrm = dash?.childNodes[0];
        if (ifrm) {
          ifrm.style.width = "100%";
          ifrm.style.height = "100%";
        }
      }
    }
  }, [loaded]);
  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <Loading source={REPORT_LOADER_SOURCE}>
        {loaded === undefined && (
          <div id="report-loader" className="loader-position">
            <div className="custom-loader" />
          </div>
        )}
        {loaded === false && <Error />}
        {loaded !== false && (
          <div className="report-container">
            <div id="dashboard" class="frame-size" />
          </div>
        )}
      </Loading>
    </ErrorBoundary>
  );
};

export default ReportsWrapper;
