import React from "react";
import { rightDiagonalArrow } from "../../../../utilsComponent/SidebarIcons";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";
import { DotLoading } from "../../../../LoaderComponents/loadingDesignConstant";

const UpdateRequirementText = () => {
    const requirementData = useSelector((state) => state.requirementData);
  return (
    <div className="requirement-display-container">
      <div className="header-section">
        <span>Detailed requirements</span>
      </div>

      <div className="scrollable-section">
        <p>
          <Markdown>{requirementData?.requirementsResponse?.response?.workflowSummary}</Markdown>
        </p>
      </div>
    </div>
  );
};

export default UpdateRequirementText;
