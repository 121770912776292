import { useEffect, useState } from "react";
import "./ContentHeader.style.scss";
import { images } from "../../imagesComponent/Images";
import { TfiStatsUp } from "react-icons/tfi";

import {
  OFFER_DETAILS_STATUS_ACTIVATED,
  OFFER_DETAILS_STATUS_ACTIVATED_MESSAGE,
  OFFER_DETAILS_STATUS_CHANGE_SUCCESS,
  OFFER_DETAILS_STATUS_DEACTIVATED,
  OFFER_DETAILS_STATUS_DEACTIVATED_MESSAGE,
  OFFER_DETAILS_STATUS_DRAFT,
  OFFER_DETAILS_STATUS_DRAFT_MESSAGE,
  headers,
  OFRP,
  SAVE,
  ACTIVATE,
  EDIT,
  DEACTIVATE,
  OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE,
  OFRI,
  offer_status,
  SUCCESS,
  CANCEL,
} from "../../constantComponent/Constants";

import moment from "moment";
//import { convertUTCDateToLocalDate } from "../offerDetailsComponent/OfferDetailsUtil";
import {
  miniloading,
  miniloadingWithoutMessage,
} from "../../LoaderComponents/loadingDesignConstant";

import {
  changeDateDashFormat,
  checkRightsIsEditable,
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
  trimExtraSpaces,
} from "../../utilsComponent/CommonUtil";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { StatsPopover } from "./ContentDesignConstant";
import {
  createUpdateContentOffer,
  createUpdateOffer,
  fetchDataForRewardsOfferCode,
  fetchDataForRewardsOfferCodeFailure,
  fetchOfferDetails,
  setErrorToaster,
  setSourceCancelBtn,
  updateOfferStatus,
} from "../../../tellurideExtRedux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DeactivateConfirmationComponent from "../../modelComponent/deactivateConfirmationComponent/DeactivateConfirmationComponent";
import { useAuth0 } from "@auth0/auth0-react";
import { convertUTCDateToLocalDate } from "../contentDetailsComponent/ContentDetailsUtil";
import ActivateConfirmationComponent from "../../modelComponent/activateConfirmationComponent/ActivateConfirmationComponent";
import ConfirmationPopUpComponent from "../../modelComponent/ConfirmationPopUpComponent/ConfirmationPopUpComponent";
import {
  CAMPAIGN,
  CONTENT,
  DETAILS,
  VIEW,
} from "../../constantComponent/PathConstants";
import { showSetupCreationConfirmationPopup } from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBarUtil";
import OfferDetails from "../../offerSetupComponent/offerDetailsComponent/OfferDetails";
const queryString = require("query-string");

const ContentHeader = ({
  contentBuilderData,
  contentDetails,
  isDisabled,
  deletedImages,
  inputFieldsValue,
  validation,
  setValidation,
}) => {
  const [contentNameEditable, setContentNameEditable] = useState(false);
  const [clickedBtn, setClickedBtn] = useState("");
  const [updatedContentName, setUpdatedContentName] = useState("");
  // const [showDeactivateConfirmationPopup, setShowDeactivateConfirmationPopup] =
  //   useState(false);
  // const [showActivateConfirmationPopup, setShowActivateConfirmationPopup] =
  //   useState(false);

  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const offerNumber = queryString.parse(window.location.search).offerid;
  const ContentOfferData = offerDetailsPageData.offerDetailsResponse;
  const securityData = useSelector((state) => state.securityData);

  const [statsPopoverVisible, setStatsPopoverVisible] = useState(false);
  const { user } = useAuth0();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState({
    key: "",
    visibility: false,
  });
  const [isValidationsValid, setIsValidationsValid] = useState(true);
  const metaData = useSelector((state) => state.metaData);
  useEffect(() => {
    offerDetailsPageData.rewardOfferCodeResponse = "";
  }, []);
  useEffect(() => {
    if (
      offerDetailsPageData.offerDetailsResponse &&
      offerDetailsPageData.offerDetailsResponse?.status === SUCCESS
    ) {
      setUpdatedContentName(ContentOfferData?.offerData?.offerPointsDollarName);
    }
  }, [ContentOfferData]);

  const [activatedClicked, setActivatedClicked] = useState(false);
  // show error toaster message if start date input is empty on Activate button click.
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEditClickContentName = () => {
    setContentNameEditable(true);
  };
  const handleContentNameChange = (e) => {
    setUpdatedContentName(e.target.value);
  };
  const saveContentName = () => {
    setContentNameEditable(!contentNameEditable);
  };
  const getMemberGroupJson = () => {
    // Iterate through the rules and extract codes
    const codes = new Set();
    if (
      offerDetailsPageData?.rewardOfferCodeResponse?.rules?.rule?.length > 0
    ) {
      offerDetailsPageData?.rewardOfferCodeResponse?.rules?.rule?.forEach(
        (rule) => {
          Object.keys(rule.condition).forEach((conditionType) => {
            rule.condition[conditionType].forEach((condition) => {
              codes.add(condition.value);
            });
          });
        }
      );

      // Create the desired output JSON
      return Array.from(codes).map((code) => ({ code }));
    }
    return "";
  };
  function showErrorToaster(obj) {
    const inputStartDate = document.getElementById("input-start-date");
    if (!inputStartDate.value) {
      const backdrop = document.querySelector(".backdrop");
      backdrop.classList.add("show");
      const startDate = document.getElementById("start-date");
      startDate.classList.add("error-start-date");
      setTimeout(() => {
        hideErrorToaster(); // after 5 seconds hide error toaster message
      }, 5000);
    }
    return;
  }
  // hide error toaster message
  function hideErrorToaster(obj) {
    const backdrop = document.querySelector(".backdrop");
    backdrop.classList.remove("show");
    return;
  }
  const handleClickOnSave = (activateClicked) => {
    // --------------------------------------------------------------------------------
    if (
      (offerDetailsPageData?.rewardOfferCodeErrorFlag &&
        metaData?.isSywOfferCodeExists) ||
      (!inputFieldsValue.sywrOfferCode && metaData?.isSywOfferCodeExists)
    ) {
      dispatch(setErrorToaster("Please Enter Valid Rewards Offer ID"));
      return;
    } else {
      continueSave(activateClicked);
    }

    // --------------------------------------------------------------------------------
  };
  const checkValidations = () => {
    let isValid = true;
    if (
      offerDetailsPageData?.rewardOfferCodeResponse &&
      inputFieldsValue?.sywrOfferCode
    ) {
      if (
        moment(
          offerDetailsPageData?.rewardOfferCodeResponse?.offerData
            ?.offerStartDate
        ).format("YYYY-MM-DD h:mm:ss") >
        moment(contentDetails?.startDate).format("YYYY-MM-DD h:mm:ss")
      ) {
        isValid = false;
        dispatch(
          setErrorToaster(
            "Content Offer Start Date must be equal to or greater than Reward Offer Start Date"
          )
        );
        return isValid;
      }
      if (
        moment(
          offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerEndDate
        ).format("YYYY-MM-DD h:mm:ss") <
        moment(contentDetails?.endDate).format("YYYY-MM-DD h:mm:ss")
      ) {
        isValid = false;
        dispatch(
          setErrorToaster(
            "Content Offer End Date must be equal to or less than Reward Offer End Date"
          )
        );
        return isValid;
      }
      let memberGroup = getMemberGroupJson();
      if (!memberGroup) {
        isValid = false;
        dispatch(
          setErrorToaster("Rewards Offer Code should contain Member Groups")
        );
        return isValid;
      }
    }
    return isValid;
  };

  const continueSave = (activateClicked) => {
    setActivatedClicked(activateClicked);
    let isValid = checkValidations();
    if (
      contentBuilderData.category &&
      contentBuilderData.subCategory &&
      isValid
    ) {
      const validationStatus = Object.keys(validation).some(
        (key) => validation[key].status === true
      );
      if (validationStatus) {
        const validationObj = {};
        Object.keys(validation).forEach((key) => {
          validationObj[key] = {
            ...validation[key],
            touched: true,
          };
        });
        setValidation(validationObj);
        dispatch(setErrorToaster("Please fill the required input fields."));
        return;
      }
      if (inputFieldsValue?.redemptionStart &&
        moment(contentDetails?.startDate).format("YYYY-MM-DD h:mm:ss") >
        moment(inputFieldsValue?.redemptionStart).format("YYYY-MM-DD h:mm:ss")
      ) {
        dispatch(
          setErrorToaster(
            "Redemption Start Date must be equal to or greater than Content Offer Start Date"
          )
        );
        return;
      }
      if (inputFieldsValue?.redemptionEnd &&
        moment(contentDetails?.endDate).format("YYYY-MM-DD h:mm:ss") <
        moment(inputFieldsValue?.redemptionEnd).format("YYYY-MM-DD h:mm:ss")
      ) {
        dispatch(
          setErrorToaster(
            "Redemption End Date must be equal to or less than Content Offer End Date"
          )
        );
        return;
      }
      headers.sessionId = skey();
      headers.requestAccess = getRightsFromSession();
      headers.organization = offerDetailsPageData?.authOrganizationName;
      const requestHeaders = {
        headers,
      };

      const requestBody = {
        requestorID: OFRI,
        messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
        offerData: {
          offerCategory: contentBuilderData.category,
          offerSubCategory: contentBuilderData.subCategory,
          offerNumber: offerNumber,
          offerType: offerDetailsPageData.offerData?.offerType,
          offerPointsDollarName: trimExtraSpaces(updatedContentName),
          offerDescription: contentDetails.description,
          linkedOfferStatus: "N",
          isContentOffer: "Y",
          deleteImages: {
            imageIds: deletedImages.map((image) => {
              return {
                ...image,
                partnerId: offerDetailsPageData?.authOrganizationName,
              };
            }),
          },
          offerStartDate: changeDateDashFormat(contentDetails.startDate),
          offerEndDate: changeDateDashFormat(contentDetails.endDate),
          businessInitiative: offerDetailsPageData?.authOrganizationName,
          isContentOffer: "Y",
        },
        offerAttributes: {
          offerAttribute: [],
        },
        actions: {
          action: [
            {
              actionName: "prepareOfferMetaData",
              actionType: "MESSAGE",
              actionJson: {
                ...getHeadlines(),
                ...getBodylines(),
                imageId: contentBuilderData.imageId,
                imageName: contentBuilderData.imageName,
                imagePath: contentBuilderData.imagePath,
                disclaimerLegalCopy: contentBuilderData.disclaimerLegalCopy,
                callToActionURL: contentBuilderData.callToActionURL,
                ...getDynamicFields(),
                category: contentBuilderData.category,
                subCategory: contentBuilderData.subCategory,
                programType: contentDetails?.programType,
                org: offerDetailsPageData?.authOrganizationName
              },
            },
          ],
        },
      };

      // Program Type changes
      if (contentDetails?.programType) {
        requestBody.offerAttributes.offerAttribute.push({
          name: "PROGRAM_TYPE",
          values: {
            value: [contentDetails?.programType],
          },
        });
      }

      dispatch(
        createUpdateContentOffer(
          requestHeaders,
          requestBody,
          true,
          activateClicked,
          navigate,
          null,
          window.location.pathname.includes(DETAILS) ? true : false
        )
      );
    }
  };

  function getHeadlines() {
    let headlines = {};
    contentBuilderData.headlines.map((headline, index) => {
      headlines[`headline${index + 1}`] = headline.value;
    });
    return headlines;
  }

  function getBodylines() {
    let bodylines = {};
    contentBuilderData.bodylines.map((bodyline, index) => {
      bodylines[`bodyline${index + 1}`] = bodyline.value;
    });
    return bodylines;
  }
  function getDynamicFields() {
    if (inputFieldsValue?.filterCriteria) {
      delete inputFieldsValue?.filterCriteria;
    }
    return inputFieldsValue;
  }

  // const handleDeactivateButtonClick = (deactivateStatus) => {
  //   if (deactivateStatus) {
  //     updateContentStatusHandller(OFFER_DETAILS_STATUS_DEACTIVATED);
  //     setShowDeactivateConfirmationPopup(!deactivateStatus);
  //   } else {
  //     setShowDeactivateConfirmationPopup(deactivateStatus);
  //   }
  // };
  // const handleActivateButtonClick = (activateStatus) => {
  //   if (activateStatus) {
  //     const validationStatus = Object.keys(validation).some(
  //       (key) => validation[key].status === true
  //     );
  //     if (validationStatus) {
  //       const validationObj = {};
  //       Object.keys(validation).forEach((key) => {
  //         validationObj[key] = {
  //           ...validation[key],
  //           touched: true,
  //         };
  //       });
  //       setValidation(validationObj);
  //       setShowActivateConfirmationPopup(!activateStatus);
  //       return;
  //     }
  //     updateContentStatusHandller(OFFER_DETAILS_STATUS_ACTIVATED);
  //     setShowActivateConfirmationPopup(!activateStatus);
  //   } else {
  //     setShowActivateConfirmationPopup(activateStatus);
  //   }
  // };
  // const handleSaveActivateButtonClick = (activateStatus) => {
  //   if (activateStatus) {
  //     handleClickOnSave(true);
  //     // updateContentStatusHandller(OFFER_DETAILS_STATUS_ACTIVATED);
  //     setShowActivateConfirmationPopup(!activateStatus);
  //   } else {
  //     setShowActivateConfirmationPopup(activateStatus);
  //   }
  // };

  const saveAndUpdateStatusHandler = (status) => {
    handleClickOnSave(true);
  };

  const updateStatusHandler = (status) => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const updateOfferStatusRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
      source: "TELL",
      modifiedBy: user?.email,
      modifiedTS: "2023-06-27 20:44:29",
      offersInfo: {
        offerInfo: [
          {
            offerNumber:
              offerDetailsPageData.offerDetailsResponse.offerData.offerNumber,
            offerStatus: status,
          },
        ],
      },
      additionalAttributes: {
        additionalAttribute: [
          {
            name: "RealTimeFlag",
            values: {
              value: ["Y"],
            },
          },
        ],
      },
    };

    dispatch(
      updateOfferStatus(
        requestHeaders,
        updateOfferStatusRequestBody,
        navigate,
        offerDetailsPageData.offerData
      )
    );
  };

  // function validateOfferRewardsNumber() {
  // 	headers.sessionId = skey();
  // 	headers.requestAccess = getRightsFromSession();
  // 	headers.organization = offerDetailsPageData?.authOrganizationName;
  // 	const requestHeaders = {
  // 		headers,
  // 	};
  // 	const offerRequestBody = {
  // 		requestorID: OFRI,
  // 		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
  // 		offerNumber: inputFieldsValue?.sywrOfferCode || "",
  // 	};

  // 	return dispatch(
  // 		fetchDataForRewardsOfferCode(requestHeaders, offerRequestBody, {})
  // 	);
  // }
  const setStartEndDateValues = () => {
    const startDateMessageDiv = document.querySelector(
      ".error-message.fs-12.startDate"
    );
    const endDateMessageDiv = document.querySelector(
      ".error-message.fs-12.endDate"
    );
    const mainDiv = document.querySelector(".date-display");
    if (mainDiv) {
      mainDiv.style.display = "none";
    }
    if (
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData
        ?.offerStartDate &&
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerEndDate
    ) {
      if (mainDiv) {
        mainDiv.style.display = "flex";
      }
    }
    if (
      startDateMessageDiv &&
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerStartDate
    ) {
      startDateMessageDiv.textContent =
        "Start Date : " +
          offerDetailsPageData?.rewardOfferCodeResponse?.offerData
            ?.offerStartDate || "";
    }
    if (
      endDateMessageDiv &&
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerEndDate
    ) {
      endDateMessageDiv.textContent =
        "End Date : " +
          offerDetailsPageData?.rewardOfferCodeResponse?.offerData
            ?.offerEndDate || "";
    }
  };
  return (
    <>
      <div className="content-navigation-top-div">
        <div className="empty-space-div"></div>
        <div className="navigation-text">
          {contentNameEditable && isDisabled !== true ? (
            <>
              <input
                className="input-content-name"
                placeholder="Enter new content name"
                maxLength="50"
                onChange={handleContentNameChange}
                type="text"
                autoFocus
                value={updatedContentName}
                onBlur={() => {
                  if (updatedContentName.trim() === "") {
                    setUpdatedContentName(
                      trimExtraSpaces(
                        ContentOfferData?.offerData?.offerPointsDollarName
                      )
                    );
                  }
                  saveContentName();
                }}
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
              />
            </>
          ) : (
            <p className="content-name setup-name-header">
              {" "}
              {updatedContentName}{" "}
            </p>
          )}
          {!contentNameEditable && isDisabled !== true ? (
            <>
              <img
                onClick={handleEditClickContentName}
                src={images.penIcon}
                className="pen-icon"
              />
            </>
          ) : null}
          {!contentNameEditable && (
            <div
              className={`status-container ${
                offerDetailsPageData?.offerDetailsResponse?.offerData
                  ?.offerStatus === OFFER_DETAILS_STATUS_ACTIVATED
                  ? moment(
                      offerDetailsPageData?.offerDetailsResponse?.offerData
                        ?.offerEndDate
                    ).diff(convertUTCDateToLocalDate(new Date()), "days") <= -1
                    ? "EXPIRED"
                    : offerDetailsPageData?.offerDetailsResponse?.offerData
                        ?.offerStatus
                  : offerDetailsPageData?.offerDetailsResponse?.offerData
                      ?.offerStatus
              }`}
            >
              {" "}
              <span className="content-status-value">
                {offerDetailsPageData?.offerDetailsResponse?.offerData
                  ?.offerStatus === OFFER_DETAILS_STATUS_ACTIVATED
                  ? moment(
                      offerDetailsPageData?.offerDetailsResponse?.offerData
                        ?.offerEndDate
                    ).diff(convertUTCDateToLocalDate(new Date()), "days") <= -1
                    ? OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE
                    : offer_status[
                        offerDetailsPageData?.offerDetailsResponse?.offerData
                          ?.offerStatus
                      ]
                  : offer_status[
                      offerDetailsPageData?.offerDetailsResponse?.offerData
                        ?.offerStatus
                    ]}
              </span>
            </div>
          )}
        </div>

        {statsPopoverVisible === true && (
          <StatsPopover setStatsPopover={setStatsPopoverVisible} />
        )}
        <div className="navigation-buttons">
          {offerDetailsPageData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT && (
            <>
              {checkRightsIsVisible(
                getRightsKey([
                  "button",
                  "campaign",
                  "content",
                  "detail",
                  "save",
                ])
              ) ? (
                <button
                  className="save-button btn btn-primary"
                  onClick={handleClickOnSave}
                  disabled={
                    (offerDetailsPageData?.rewardOfferCodeErrorFlag &&
                      metaData?.isSywOfferCodeExists) ||
                    !contentBuilderData.category ||
                    !contentBuilderData.subCategory ||
                    !checkRightsIsEditable(
                      getRightsKey([
                        "button",
                        "campaign",
                        "content",
                        "detail",
                        "save",
                      ])
                    )
                  }
                >
                  {" "}
                  {offerDetailsPageData?.rewardOfferCodeLoading
                    ? miniloadingWithoutMessage()
                    : SAVE}{" "}
                </button>
              ) : null}
            </>
          )}

          {offerDetailsPageData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT &&
            offerDetailsPageData?.offerStatus !==
              OFFER_DETAILS_STATUS_ACTIVATED && (
              <>
                {checkRightsIsVisible(
                  getRightsKey([
                    "button",
                    "campaign",
                    "content",
                    "detail",
                    "edit",
                  ])
                ) ? (
                  <button
                    className="save-button btn btn-primary"
                    onClick={(e) => {
                      setClickedBtn(EDIT);
                      updateStatusHandler(OFFER_DETAILS_STATUS_DRAFT);
                    }}
                    disabled={
                      clickedBtn === EDIT ||
                      !checkRightsIsEditable(
                        getRightsKey([
                          "button",
                          "campaign",
                          "content",
                          "detail",
                          "edit",
                        ])
                      )
                    }
                  >
                    {offerDetailsPageData.updateContentStatusLoading &&
                    clickedBtn === EDIT
                      ? miniloadingWithoutMessage()
                      : EDIT}
                  </button>
                ) : null}
              </>
            )}
          {(offerDetailsPageData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT ||
            offerDetailsPageData?.offerStatus ===
              OFFER_DETAILS_STATUS_DEACTIVATED) && (
            <>
              {checkRightsIsVisible(
                getRightsKey([
                  "button",
                  "campaign",
                  "content",
                  "detail",
                  "active",
                ])
              ) ? (
                <button
                  className="activate-button btn btn-primary"
                  onClick={(e) => {
                    setShowConfirmationPopup({
                      key: "activate",
                      visibility: true,
                    });
                  }}
                  disabled={
                    (offerDetailsPageData?.rewardOfferCodeErrorFlag &&
                      metaData?.isSywOfferCodeExists) ||
                    clickedBtn === ACTIVATE ||
                    !contentBuilderData.category ||
                    !contentBuilderData.subCategory ||
                    !checkRightsIsEditable(
                      getRightsKey([
                        "button",
                        "campaign",
                        "content",
                        "detail",
                        "active",
                      ])
                    )
                      ? true
                      : false
                  }
                >
                  {offerDetailsPageData.updateContentStatusLoading ||
                  offerDetailsPageData?.rewardOfferCodeLoading
                    ? miniloadingWithoutMessage()
                    : ACTIVATE}
                </button>
              ) : null}
            </>
          )}
          {offerDetailsPageData?.offerStatus ===
            OFFER_DETAILS_STATUS_ACTIVATED && (
            <>
              {checkRightsIsVisible(
                getRightsKey([
                  "button",
                  "campaign",
                  "content",
                  "detail",
                  "deactive",
                ])
              ) ? (
                <button
                  className="activate-button btn btn-primary"
                  onClick={(e) => {
                    setClickedBtn(DEACTIVATE);
                    setShowConfirmationPopup({
                      key: "deactivate",
                      visibility: true,
                    });
                  }}
                  disabled={
                    clickedBtn === DEACTIVATE ||
                    !checkRightsIsEditable(
                      getRightsKey([
                        "button",
                        "campaign",
                        "content",
                        "detail",
                        "deactive",
                      ])
                    )
                  }
                >
                  {offerDetailsPageData.updateContentStatusLoading &&
                  clickedBtn === DEACTIVATE
                    ? miniloadingWithoutMessage()
                    : DEACTIVATE}
                </button>
              ) : null}
            </>
          )}
          <button
            className="activate-button btn btn-secondary"
            onClick={(e) => {
              if (
                offerDetailsPageData.isOfferChanged &&
                offerDetailsPageData?.offerStatus ===
                  OFFER_DETAILS_STATUS_DRAFT &&
                checkRightsIsEditable(
                  getRightsKey([
                    "content",
                    "detail",
                    "setup",
                    (offerDetailsPageData.offerData?.offerType)
                      .split(" ")
                      .join(""),
                  ])
                )
              ) {
                dispatch(
                  setSourceCancelBtn({
                    state: true,
                    path: CAMPAIGN + CONTENT,
                  })
                );
                showSetupCreationConfirmationPopup();
              } else {
                navigate(
                  "/" +
                    window.location.pathname.split("/")[1] +
                    CAMPAIGN +
                    CONTENT
                );
              }
            }}
          >
            {CANCEL}
          </button>
        </div>
      </div>
      {/* <AnimatePresence>
        {showDeactivateConfirmationPopup && (
          <DeactivateConfirmationComponent
            setClickedBtn={setClickedBtn}
            onDeactiveClick={handleDeactivateButtonClick}
          />
        )}
      </AnimatePresence> */}
      <AnimatePresence>
        {showConfirmationPopup.visibility && (
          <ConfirmationPopUpComponent
            setClickedBtn={setClickedBtn}
            updateStatusHandler={updateStatusHandler}
            saveAndUpdateStatusHandler={saveAndUpdateStatusHandler}
            secondaryBtnText={
              offerDetailsPageData.isOfferChanged &&
              offerDetailsPageData.offerStatus === OFFER_DETAILS_STATUS_DRAFT
                ? "Cancel"
                : "No"
            }
            saveActivate={
              offerDetailsPageData.isOfferChanged &&
              offerDetailsPageData.offerStatus === OFFER_DETAILS_STATUS_DRAFT
                ? true
                : false
            }
            isOfferChanged={
              offerDetailsPageData.offerStatus === OFFER_DETAILS_STATUS_DRAFT
                ? offerDetailsPageData.isOfferChanged
                : false
            }
            statusKey={showConfirmationPopup.key}
            setShowConfirmationPopup={setShowConfirmationPopup}
          />
        )}
      </AnimatePresence>
    </>
  );
};
export default ContentHeader;
