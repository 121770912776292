import React from "react";
import InputField from "./InputField";

function GetInputFields({
	INPUT_FIELDS,
	inputFieldsValue,
	setInputFieldsValue,
	handleViewOffer,
	startEndDateRef = null
}) {
	return (
		<div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
			{INPUT_FIELDS.map((input) =>
				InputField(
					input,
					inputFieldsValue[input.fieldname],
					setInputFieldsValue,
					handleViewOffer,
					startEndDateRef
				)
			)}
		</div>
	);
}
export default React.memo(GetInputFields);
