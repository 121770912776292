import axios from "axios";
import { setErrorToaster, setSuccessToaster } from "../toaster/ToasterAction";
import {
  GET_ENTITIES_REQUEST,
  GET_ENTITIES_SUCCESS,
  GET_ENTITIES_FAILURE,
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS,
  GET_ATTRIBUTES_FAILURE,
  SET_ATTRIBUTE_LIST,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
  CREATE_ATTRIBUTE_REQUEST,
  CREATE_ATTRIBUTE_SUCCESS,
  CREATE_ATTRIBUTE_FAILURE,
  CREATE_ATTRIBUTE_SUCCESS_FAILURE,
  GET_ATTRIBUTE_LIST_REQUEST,
  GET_ATTRIBUTE_LIST_SUCCESS,
  GET_ATTRIBUTE_LIST_FAILURE,
  GET_ATTRIBUTE_LIST_SUCCESS_FAILURE,
  GET_ATTRIBUTE_DETAILS_REQUEST,
  GET_ATTRIBUTE_DETAILS_SUCCESS,
  GET_ATTRIBUTE_DETAILS_FAILURE,
  GET_ATTRIBUTE_DETAILS_SUCCESS_FAILURE,
  SET_ATTRIBUTE_DETAILS,
  SET_ATTRIBUTE_OPTIONS,
  SET_ATTRIBUTE_DETAILS_LOADING,
  RESET_ATTRIBUTE_DETAILS_SUCCESS,
  RESET_ATTRIBUTE_DETAILS_RESPONSE,
  RESET_CREATE_ATTRIBUTE_RESPONSE,
  SET_ATTRIBUTE_VALIDATION,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_SUCCESS_FAILURE,
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_FAILURE,
  GET_SUB_CATEGORIES_SUCCESS_FAILURE,
  GET_CONTENT_ATTRS_REQUEST,
  GET_CONTENT_ATTRS_SUCCESS,
  GET_CONTENT_ATTRS_FAILURE,
  GET_CONTENT_ATTRS_SUCCESS_FAILURE,
  RESET_CONTENT_METADATA,
  GET_OFFER_TYPES_REQUEST,
  GET_OFFER_TYPES_SUCCESS,
  GET_OFFER_TYPES_FAILURE,
  GET_OFFER_TYPES_SUCCESS_FAILURE,
  GET_REPORT_LINK_REQUEST,
  GET_REPORT_LINK_SUCCESS,
  GET_REPORT_LINK_FAILURE,
  GET_REPORT_LINK_SUCCESS_FAILURE,
  GET_CONNECTOR_TABLE_SUCCESS_FAILURE,
  GET_CONNECTOR_TABLE_FAILURE,
  GET_CONNECTOR_TABLE_SUCCESS,
  GET_CONNECTOR_TABLE_REQUEST,
  GET_ADD_CONNECTOR_OPTIONS_REQUEST,
  GET_ADD_CONNECTOR_OPTIONS_SUCCESS,
  GET_ADD_CONNECTOR_OPTIONS_FAILURE,
  GET_ADD_CONNECTOR_OPTIONS_SUCCESS_FAILURE,
  CREATE_CONNECTOR_SUCCESS,
  CREATE_CONNECTOR_FAILURE,
  CREATE_CONNECTOR_SUCCESS_FAILURE,
  CREATE_CONNECTOR_REQUEST,
  // GET_LIST_STATIC,
  GET_PROGRAM_TYPES_SUCCESS,
  GET_PROGRAM_TYPES_REQUEST,
  GET_PROGRAM_TYPES_FAILURE,
  IS_SYW_OFFERCODE_EXISTS,
} from "./MetaDataType";
import {
  ERROR_MESSAGE,
  setBlankSession,
  setSessionExpire,
  setToaster,
} from "../reduxUtil";
import {
  ATTRIBUTE_CREATE,
  CONNECTOR_CREATE,
  CONNECTOR_UPDATED,
} from "../../tellurideExtComponents/errorsComponent/ErrorConstant";
import {
  ATTRIBUTE,
  CONFIGURE,
  VIEW,
} from "../../tellurideExtComponents/constantComponent/PathConstants";
import { dummy_connector_data } from "../../tellurideExtComponents/connectorsSetupComponent/ConnectorConstant";
import { isExists } from "date-fns";
export const getEntitiesDetails = (
  showBoundary,
  requestBody,
  requestHeaders
) => {
  return async (dispatch) => {
    dispatch(getEntitiesRequest());
    try {
      let { data } = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_METADATA_GET_ENTITIES,
        requestBody,
        requestHeaders
      );
      dispatch(getEntitiesSuccess(data));
    } catch (err) {
      //showBoundary(err)
      dispatch(getEntitiesFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getEntitiesRequest = () => {
  return {
    type: GET_ENTITIES_REQUEST,
  };
};

export const getEntitiesSuccess = (apiData) => {
  return {
    type: GET_ENTITIES_SUCCESS,
    payload: apiData,
  };
};

export const getEntitiesFailure = (apiData) => {
  return {
    type: GET_ENTITIES_FAILURE,
    payload: apiData,
  };
};
export const getProgramTypesDetails = (
  requestBody,
  requestHeaders
) => {
  return async (dispatch) => {
    dispatch(getProgramTypesRequest());
    try {
      let { data } = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_METADATA_GET_PROGRAM_TYPES,
        requestBody,
        requestHeaders
      );
      dispatch(getProgramTypesSuccess(data));
    } catch (err) {
      dispatch(getProgramTypesFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getProgramTypesRequest = () => {
  return {
    type: GET_PROGRAM_TYPES_REQUEST,
  };
};

export const getProgramTypesSuccess = (apiData) => {
  return {
    type: GET_PROGRAM_TYPES_SUCCESS,
    payload: apiData,
  };
};

export const getProgramTypesFailure = (apiData) => {
  return {
    type: GET_PROGRAM_TYPES_FAILURE,
    payload: apiData,
  };
};

export const getAttributesDetails = (requestHeaders, requestBody) => {
  return async (dispatch) => {
    dispatch(getAttributesRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_METADATA_GET_ATTRIBUTES,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (
          resp.data !== undefined &&
          resp.data !== "" &&
          resp.data.errors !== undefined &&
          resp.data.errors.length !== 0
        ) {
          dispatch(getAttributesFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          dispatch(getAttributesSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(getAttributesFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

export const getAttributesRequest = () => {
  return {
    type: GET_ATTRIBUTES_REQUEST,
  };
};

export const getAttributesSuccess = (apiData) => {
  return {
    type: GET_ATTRIBUTES_SUCCESS,
    payload: apiData,
  };
};

export const getAttributesFailure = (apiData) => {
  return {
    type: GET_ATTRIBUTES_FAILURE,
    payload: apiData,
  };
};
export const setAttributeList = (data) => {
  return {
    type: SET_ATTRIBUTE_LIST,
    payload: data,
  };
};
export const setBlankSessionIdError = () => {
  return {
    type: SET_BLANK_SESSION_ID_ERROR,
  };
};
export const setSessionExpirePopUp = () => {
  return {
    type: SET_SESSION_TIMEOUT_POPUP,
  };
};

export const createAttribute = (requestBody, requestHeaders, isShowToaster) => {
  return async (dispatch) => {
    dispatch(getCreateAttributeRequest());
    try {
      let resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_CREATE_ATTRIBUTE,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getCreateAttributeSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getCreateAttributeSuccess(resp.data));
        if (isShowToaster) {
          dispatch(setSuccessToaster(ATTRIBUTE_CREATE));
        }
      }
    } catch (err) {
      //showBoundary(err)
      dispatch(getCreateAttributeFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getCreateAttributeRequest = () => {
  return {
    type: CREATE_ATTRIBUTE_REQUEST,
  };
};

export const getCreateAttributeSuccess = (apiData) => {
  return {
    type: CREATE_ATTRIBUTE_SUCCESS,
    payload: apiData,
  };
};

export const getCreateAttributeFailure = (apiData) => {
  return {
    type: CREATE_ATTRIBUTE_FAILURE,
    payload: apiData,
  };
};
export const getCreateAttributeSuccessFailure = (apiData) => {
  return {
    type: CREATE_ATTRIBUTE_SUCCESS_FAILURE,
    payload: apiData,
  };
};

export const getAttributeList = (
  requestHeaders,
  requestBody,
  apiurl,
  controller
) => {
  return async (dispatch) => {
    dispatch(getAttributeListRequest());

    try {
      const resp = await axios.post(apiurl, requestBody, {
        ...requestHeaders,
        signal: controller.signal,
      });
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getAttributeListSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getAttributeListSuccess(resp.data));
      }
    } catch (err) {
      dispatch(getAttributeListFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getAttributeListRequest = () => {
  return {
    type: GET_ATTRIBUTE_LIST_REQUEST,
  };
};

export const getAttributeListSuccess = (apiData) => {
  return {
    type: GET_ATTRIBUTE_LIST_SUCCESS,
    payload: apiData,
  };
};

export const getAttributeListFailure = (apiData) => {
  return {
    type: GET_ATTRIBUTE_LIST_FAILURE,
    payload: apiData,
  };
};
export const getAttributeListSuccessFailure = (apiData) => {
  return {
    type: GET_ATTRIBUTE_LIST_SUCCESS_FAILURE,
    payload: apiData,
  };
};

export const getSingleAttributeDetails = (
  requestBody,
  requestHeaders,
  navigate
) => {
  return async (dispatch) => {
    dispatch(getSingleAttributeDetailsRequest());
    try {
      let resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_GET_ATTRIBUTE_DETAILS,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        navigate(
          "/" +
            window.location.pathname.split("/")[1] +
            CONFIGURE +
            ATTRIBUTE +
            VIEW
        );
        dispatch(getSingleAttributeDetailsSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getSingleAttributeDetailsSuccess(resp.data));
      }
    } catch (err) {
      navigate(
        "/" +
          window.location.pathname.split("/")[1] +
          CONFIGURE +
          ATTRIBUTE +
          VIEW
      );
      dispatch(getSingleAttributeDetailsFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getSingleAttributeDetailsRequest = () => {
  return {
    type: GET_ATTRIBUTE_DETAILS_REQUEST,
  };
};

export const getSingleAttributeDetailsSuccess = (apiData) => {
  return {
    type: GET_ATTRIBUTE_DETAILS_SUCCESS,
    payload: apiData,
  };
};

export const getSingleAttributeDetailsFailure = (apiData) => {
  return {
    type: GET_ATTRIBUTE_DETAILS_FAILURE,
    payload: apiData,
  };
};
export const getSingleAttributeDetailsSuccessFailure = (apiData) => {
  return {
    type: GET_ATTRIBUTE_DETAILS_SUCCESS_FAILURE,
    payload: apiData,
  };
};
export const setAttributeDetails = (apiData) => {
  return {
    type: SET_ATTRIBUTE_DETAILS,
    payload: apiData,
  };
};
export const setAttributeOptions = (apiData) => {
  return {
    type: SET_ATTRIBUTE_OPTIONS,
    payload: apiData,
  };
};
export const setAttributeValidation = (apiData) => {
  return {
    type: SET_ATTRIBUTE_VALIDATION,
    payload: apiData,
  };
};
export const setAttributeDetailsLoading = (apiData) => {
  return {
    type: SET_ATTRIBUTE_DETAILS_LOADING,
    payload: apiData,
  };
};
export const resetAttributeDetailsResponse = () => {
  return {
    type: RESET_ATTRIBUTE_DETAILS_RESPONSE,
  };
};
export const resetCreateAttributeResponse = () => {
  return {
    type: RESET_CREATE_ATTRIBUTE_RESPONSE,
  };
};

export const getCategories = (_requestBody, requestHeaders) => {
  return async (dispatch) => {
    dispatch(getCategoriesRequest());
    try {
      let resp = await axios.get(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_GET_CATEGORIES,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getCategoriesSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getCategoriesSuccess(resp.data));
      }
    } catch (err) {
      //showBoundary(err)
      dispatch(getCategoriesFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getCategoriesRequest = () => {
  return {
    type: GET_CATEGORIES_REQUEST,
  };
};

export const getCategoriesSuccess = (apiData) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: apiData,
  };
};

export const getCategoriesFailure = (apiData) => {
  return {
    type: GET_CATEGORIES_FAILURE,
    payload: apiData,
  };
};
export const getCategoriesSuccessFailure = (apiData) => {
  return {
    type: GET_CATEGORIES_SUCCESS_FAILURE,
    payload: apiData,
  };
};

export const getSubCategories = (requestBody, requestHeaders) => {
  return async (dispatch) => {
    dispatch(getSubCategoriesRequest());
    try {
      let resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_GET_SUB_CATEGORIES,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getSubCategoriesSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getSubCategoriesSuccess(resp.data));
      }
    } catch (err) {
      //showBoundary(err)
      dispatch(getSubCategoriesFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getSubCategoriesRequest = () => {
  return {
    type: GET_SUB_CATEGORIES_REQUEST,
  };
};

export const getSubCategoriesSuccess = (apiData) => {
  return {
    type: GET_SUB_CATEGORIES_SUCCESS,
    payload: apiData,
  };
};

export const getSubCategoriesFailure = (apiData) => {
  return {
    type: GET_SUB_CATEGORIES_FAILURE,
    payload: apiData,
  };
};
export const getSubCategoriesSuccessFailure = (apiData) => {
  return {
    type: GET_SUB_CATEGORIES_SUCCESS_FAILURE,
    payload: apiData,
  };
};

export const getContentAttrs = (requestBody, requestHeaders) => {
  return async (dispatch) => {
    dispatch(getContentAttrsRequest());
    try {
      let resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_GET_CONTENT_ATTRS,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getContentAttrsSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        dispatch(setSywrOfferCodeExists(false));
      } else {
        dispatch(getContentAttrsSuccess(resp.data));
        if(resp?.data?.fields) {
          let isValueExists = resp?.data?.fields.some(field => field.fieldname === 'sywrOfferCode')
          dispatch(setSywrOfferCodeExists(isValueExists));
        } else {
          dispatch(setSywrOfferCodeExists(false));
        }
      }
    } catch (err) {
      dispatch(getContentAttrsFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      dispatch(setSywrOfferCodeExists(false));
    }
  };
};

export const getContentAttrsRequest = () => {
  return {
    type: GET_CONTENT_ATTRS_REQUEST,
  };
};

export const getContentAttrsSuccess = (apiData) => {
  return {
    type: GET_CONTENT_ATTRS_SUCCESS,
    payload: apiData,
  };
};

export const getContentAttrsFailure = (apiData) => {
  return {
    type: GET_CONTENT_ATTRS_FAILURE,
    payload: apiData,
  };
};
export const getContentAttrsSuccessFailure = (apiData) => {
  return {
    type: GET_CONTENT_ATTRS_SUCCESS_FAILURE,
    payload: apiData,
  };
};
export const resetContentMetadata = () => {
  return {
    type: RESET_CONTENT_METADATA,
  };
};
export const setSywrOfferCodeExists = (apiData) => {
  return {
    type: IS_SYW_OFFERCODE_EXISTS,
    payload: apiData
  }
}
/* START of API
this api use to get the data for popup to show the types
*/

export const getOfferTypeList = (requestHeaders, requestParam) => {
  return async (dispatch) => {
    dispatch(getOfferTypeListRequest());
    try {
      let resp = await axios.get(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_OFFER_TYPE_LIST +
          requestParam.id,

        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getOfferTypeListSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        // if(process.env.REACT_APP_ENVIRONMENT==="qa")
        //   dispatch(getOfferTypeListSuccess(GET_LIST_STATIC));
        // else
          dispatch(getOfferTypeListSuccess(resp.data));
      }
    } catch (err) {
      dispatch(getOfferTypeListFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getOfferTypeListRequest = () => {
  return {
    type: GET_OFFER_TYPES_REQUEST,
  };
};

export const getOfferTypeListSuccess = (apiData) => {
  return {
    type: GET_OFFER_TYPES_SUCCESS,
    payload: apiData,
  };
};

export const getOfferTypeListFailure = (apiData) => {
  return {
    type: GET_OFFER_TYPES_FAILURE,
    payload: apiData,
  };
};
export const getOfferTypeListSuccessFailure = (apiData) => {
  return {
    type: GET_OFFER_TYPES_SUCCESS_FAILURE,
    payload: apiData,
  };
};

/* END of API
 */

/* START of API
this api use to get the report link to show the report 
*/

export const getReportLink = (requestHeaders, requestParam) => {
  return async (dispatch) => {
    dispatch(getReportLinkRequest());
    try {
      let resp = await axios.get(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_REPORT_LINK,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getReportLinkSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getReportLinkSuccess(resp.data));
      }
    } catch (err) {
      dispatch(getReportLinkFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getReportLinkRequest = () => {
  return {
    type: GET_REPORT_LINK_REQUEST,
  };
};

export const getReportLinkSuccess = (apiData) => {
  return {
    type: GET_REPORT_LINK_SUCCESS,
    payload: apiData,
  };
};

export const getReportLinkFailure = (apiData) => {
  return {
    type: GET_REPORT_LINK_FAILURE,
    payload: apiData,
  };
};
export const getReportLinkSuccessFailure = (apiData) => {
  return {
    type: GET_REPORT_LINK_SUCCESS_FAILURE,
    payload: apiData,
  };
};

/* END of API
 */

/* START OF API
 Fetch Connection Table list Details
 */
export const getConnectorTableList = (requestHeaders, requestBody) => {
  return async (dispatch) => {
    dispatch(getConnectorTableListRequest());
    try {
      let resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_CONNECTOR_LIST,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getConnectorTableListSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getConnectorTableListSuccess(resp.data));
      }
    } catch (err) {
      dispatch(getConnectorTableListFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getConnectorTableListRequest = () => {
  return {
    type: GET_CONNECTOR_TABLE_REQUEST,
  };
};

export const getConnectorTableListSuccess = (apiData) => {
  return {
    type: GET_CONNECTOR_TABLE_SUCCESS,
    payload: apiData,
  };
};
export const getConnectorTableListFailure = (apiData) => {
  return {
    type: GET_CONNECTOR_TABLE_FAILURE,
    payload: apiData,
  };
};
export const getConnectorTableListSuccessFailure = (apiData) => {
  return {
    type: GET_CONNECTOR_TABLE_SUCCESS_FAILURE,
    payload: apiData,
  };
};
/* END of API
 */

/* START OF API
 Fetch Add Connection Options
 */
export const getAddConnectorOptions = (requestHeaders, requestBody) => {
  return async (dispatch) => {
    dispatch(getAddConnectorOptionsRequest());
    try {
      let resp = await axios.get(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_ADD_CONNECTOR_OPTIONS,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getAddConnectorOptionsSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getAddConnectorOptionsSuccess(resp.data));
      }
    } catch (err) {
      dispatch(getAddConnectorOptionsFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};
export const getAddConnectorOptionsRequest = () => {
  return {
    type: GET_ADD_CONNECTOR_OPTIONS_REQUEST,
  };
};

export const getAddConnectorOptionsSuccess = (apiData) => {
  return {
    type: GET_ADD_CONNECTOR_OPTIONS_SUCCESS,
    payload: apiData,
  };
};

export const getAddConnectorOptionsFailure = (apiData) => {
  return {
    type: GET_ADD_CONNECTOR_OPTIONS_FAILURE,
    payload: apiData,
  };
};

export const getAddConnectorOptionsSuccessFailure = (apiData) => {
  return {
    type: GET_ADD_CONNECTOR_OPTIONS_SUCCESS_FAILURE,
    payload: apiData,
  };
};
/* END of API
 */

/* START OF API
 Save new Connectior
 */
export const saveConnector = (requestHeaders, requestBody, connectorTableListRequestBody, isExistingData) => {
  return async (dispatch) => {
    dispatch(saveConnectorRequest());
    try {
      let resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_SAVE_CONNECTOR,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(saveConnectorSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(saveConnectorSuccess(resp.data));
        if(isExistingData) {
          dispatch(setSuccessToaster(CONNECTOR_UPDATED))
        } else {
          dispatch(setSuccessToaster(CONNECTOR_CREATE));
        }
        dispatch(getConnectorTableList(requestHeaders, connectorTableListRequestBody))
      }
    } catch (err) {
      dispatch(saveConnectorFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const saveConnectorRequest = () => {
  return {
    type: CREATE_CONNECTOR_REQUEST,
  };
};

export const saveConnectorSuccess = (apiData) => {
  return {
    type: CREATE_CONNECTOR_SUCCESS,
    payload: apiData,
  };
};
export const saveConnectorFailure = (apiData) => {
  return {
    type: CREATE_CONNECTOR_FAILURE,
    payload: apiData,
  };
};
export const saveConnectorSuccessFailure = (apiData) => {
  return {
    type: CREATE_CONNECTOR_SUCCESS_FAILURE,
    payload: apiData,
  };
};
/* END of API
 */
