import React, { useEffect, useState } from "react";
import { rightDiagonalArrow } from "../../../../utilsComponent/SidebarIcons";
import UpdateRequirementText from "./UpdateRequirementText";
import UpdateRequirementInput from "./UpdateRequirementInput";
import { useDispatch, useSelector } from "react-redux";
import { DotLoading } from "../../../../LoaderComponents/loadingDesignConstant";
import { setSuccessToaster } from "../../../../../tellurideExtRedux";

const UpdateRequirementBodySection = ({
  requirementInputText,
  setrequirementInputText,
  setShowUpdateRequirement,
  submitHandler,
  actionJsonvalue,
  setActionJsonValue,
}) => {
  const requirementData = useSelector((state) => state.requirementData);
  const [expandequirementInput, setExpandRequirementInput] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      {requirementData?.requirementsLoading && <DotLoading />}
      <div className="requirement-body">
        <div
          className="requirement-container"
          style={{ height: expandequirementInput && "90%" }}
        >
          {!expandequirementInput && <UpdateRequirementText />}
          <UpdateRequirementInput
            requirementInputText={requirementInputText}
            setrequirementInputText={setrequirementInputText}
            expandequirementInput={expandequirementInput}
            setExpandRequirementInput={setExpandRequirementInput}
          />
        </div>
        <div className="button-container">
          <button
            className="btn btn-secondary"
            onClick={() => {
              if (
                requirementData?.requirementsResponse?.response?.workflowJson
                  ?.actions[0] 
              ) {
                dispatch(setSuccessToaster("Action JSON updated successfully"));
                if (
                  typeof requirementData?.requirementsResponse?.response
                    ?.workflowJson?.actions[0] === "string"
                ) {
                  setActionJsonValue(
                    JSON.stringify(
                      JSON.parse(
                        requirementData?.requirementsResponse?.response
                          ?.workflowJson?.actions[0]
                      ),
                      null,
                      2
                    )
                  );
                } else {
                  setActionJsonValue(
                    JSON.stringify(
                      requirementData?.requirementsResponse?.response
                        ?.workflowJson?.actions[0],
                      null,
                      2
                    )
                  );
                }
              }else{
                setActionJsonValue(actionJsonvalue);
              }
            //   setActionJsonValue(
            //     requirementData?.requirementsResponse?.response?.workflowJson
            //       ?.actions[0]
            //       ? JSON.stringify(
            //           requirementData?.requirementsResponse?.response
            //             ?.workflowJson?.actions[0],
            //           null,
            //           2
            //         )
            //       : actionJsonvalue
            //   );

              setShowUpdateRequirement(false);
            }}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            disabled={!requirementInputText.trim()}
            onClick={() => {
              submitHandler();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateRequirementBodySection;
