import "./SegmentDetails.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import DatePicker from "react-date-picker";
import { images } from "../../imagesComponent/Images";
import moment from "moment";
import {
	SEGMENT_DETAILS_TITLE,
	SEGMENT_NAME_LABEL,
	SEGMENT_TYPE_LABEL,
	SEGMENT_DESCRIPTION_LABEL,
	SEGMENT_COUNT_LABEL,
	SEGMENT_LINKED_OFFERS_LABEL,
	SEGMENT_LAST_UPDATEDBY_LABEL,
	SEGMENT_LAST_UPDATEDTS_LABEL,
} from "./SegmentDetailsConstants";
import {
	convertUTCDateToLocalDate,
	dateHandler,
	getCustomStyles,
	validateDescription,
} from "./SegmentDetailsUtil";
import { updateSegmentDetails } from "../../../tellurideExtRedux";
import { ENABLED } from "../../constantComponent/Constants";
import _ from "lodash";
import isEqual from "react-fast-compare";
const SegmentDetails = (props) => {
	const dispatch = useDispatch();
	const segmentDetailsPageData = useSelector(
		(state) => state.segmentDetailsData
	);
	const [segmentData, setSegmentData] = useState(
		JSON.parse(JSON.stringify(segmentDetailsPageData.segmentDetailsResponse))
	);
	const [updateSegmentData, setUpdateSegmentData] = useState(
		JSON.parse(
			JSON.stringify(segmentDetailsPageData.updateSegmentDetailsResponse)
		)
	);
	const [segmentDetails, setSegmentDetails] = useState({
		description: segmentData.description,
	});
	const [isDisabled, setDisable] = useState(
		segmentDetailsPageData.segmentMode === ENABLED
			? false
			: true || props.disabled
	);

	useEffect(() => {
		setUpdateSegmentData((prev) => {
			return {
				...prev,
				description: segmentDetails.description,
			};
		});
	}, [segmentDetails]);
	useEffect(() => {
		if (
			!isEqual(
				segmentDetailsPageData.updateSegmentDetailsResponse,
				updateSegmentData
			)
		) {
			setUpdateSegmentData(
				JSON.parse(
					JSON.stringify(segmentDetailsPageData.updateSegmentDetailsResponse)
				)
			);
		}
	}, [segmentDetailsPageData.updateSegmentDetailsResponse]);
	useEffect(() => {
		setDisable(
			segmentDetailsPageData.segmentMode === ENABLED
				? false
				: true || props.disabled
		);
	}, [segmentDetailsPageData.segmentMode, props.disabled]);

	useEffect(() => {
		dispatch(updateSegmentDetails(updateSegmentData));
	}, [updateSegmentData]);

	const valueHandler = (e) => {
		const updatedSegmentDetails = {
			...segmentDetails,
			description: e.target.value,
		};
		setSegmentDetails(updatedSegmentDetails);
	};

	useEffect(() => {
		segmentDetailsPageData.isNewSegmentFlow = false;
	}, [segmentDetailsPageData.isNewSegmentFlow]);

	function formatWrapUpdatedBy(updatedByValue) {
		var wrappedUpdatedBy = "";
		if (updatedByValue !== undefined && updatedByValue.length > 0) {
			if (updatedByValue.length > 18) {
				wrappedUpdatedBy =
					updatedByValue.substring(0, updatedByValue.indexOf(".")) +
					"   " +
					updatedByValue.substring(
						updatedByValue.indexOf(".") + 1,
						updatedByValue.length
					);
			} else {
				wrappedUpdatedBy = updatedByValue;
			}
		} else {
			wrappedUpdatedBy = updatedByValue;
		}
		return wrappedUpdatedBy;
	}
	return (
		<>
			<div className="segment-details-main-container">
				<div className="segment-details-title setup-page-section-title">
					{SEGMENT_DETAILS_TITLE}
				</div>
				<section className="segment-details-section">
					<div className="form-div">
						<div className="wrapper-segment-details-tablet">
							<div className="segment-detail-col">
								<div className="segment-code">
									<div className="segment-info-container">
										<label className="segment-info-label details-page-label">
											{SEGMENT_TYPE_LABEL} :{" "}
										</label>
										<span className="segment-info-bold">
											{segmentData.groupEntity}
										</span>
									</div>
								</div>

								<div className="segment-code">
									<div className="segment-info-container">
										<label className="segment-info-label details-page-label">
											{SEGMENT_COUNT_LABEL} :{" "}
										</label>
										<span className="segment-info-bold">
											{segmentData.totalCount}
										</span>
									</div>
								</div>
							</div>
							<div className="segment-detail-col">
								<div className="segment-code">
									<div className="segment-info-container">
										<label className="segment-info-label details-page-label">
											{SEGMENT_LAST_UPDATEDBY_LABEL} :{" "}
										</label>
										<span
											className="segment-info-bold"
											title={segmentData?.updatedBy}
										>
											{segmentData?.updatedBy}
										</span>
									</div>
								</div>

								<div className="segment-code">
									<div className="segment-info-container">
										<label className="segment-info-label details-page-label">
											{SEGMENT_LAST_UPDATEDTS_LABEL} :{" "}
										</label>
										<span className="segment-info-bold">
											{segmentData.lastModifiedDate}
										</span>
									</div>
								</div>
								<div className="segment-code segment-description-desktop">
									<fieldset
										className="text-box"
										disabled={isDisabled === true ? true : false}
									>
										<legend>{SEGMENT_DESCRIPTION_LABEL}</legend>
										<textarea
											id="segmentDescription"
											className="input-text-textarea"
											rows="3"
											cols="50"
											value={segmentDetails.description}
											maxLength="250"
											name="description"
											onChange={valueHandler}
										/>
									</fieldset>
								</div>
							</div>
						</div>
						<div className="segment-code segment-description-tablet">
							<fieldset
								className="text-box"
								disabled={isDisabled === true ? true : false}
							>
								<legend>{SEGMENT_DESCRIPTION_LABEL}</legend>
								<textarea
									id="segmentDescription"
									className="input-text-textarea"
									rows="3"
									cols="50"
									value={segmentDetails.description}
									maxLength="250"
									name="description"
									onChange={valueHandler}
								/>
							</fieldset>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default SegmentDetails;
