export const GET_REQUIREMENTS_REQUEST = "GET_REQUIREMENTS_REQUEST";
export const GET_REQUIREMENTS_SUCCESS = "GET_REQUIREMENTS_SUCCESS";
export const GET_REQUIREMENTS_SUCCESS_FAILURE =
  "GET_REQUIREMENTS_SUCCESS_FAILURE";
export const GET_REQUIREMENTS_FAILURE = "GET_REQUIREMENTS_FAILURE";

export const UPDATE_REQUIREMENTS_REQUEST = "UPDATE_REQUIREMENTS_REQUEST";
export const UPDATE_REQUIREMENTS_SUCCESS = "UPDATE_REQUIREMENTS_SUCCESS";
export const UPDATE_REQUIREMENTS_SUCCESS_FAILURE = "UPDATE_REQUIREMENTS_SUCCESS_FAILURE"
export const UPDATE_REQUIREMENTS_FAILURE = "UPDATE_REQUIREMENTS_FAILURE";
export const SET_BLANK_SESSION_ID_ERROR = "SET_BLANK_SESSION_ID_ERROR";
export const SET_SESSION_TIMEOUT_POPUP = "SET_SESSION_TIMEOUT_POPUP";

export const API_INVOKE_TEST_REQUEST = "API_INVOKE_TEST_REQUEST";
export const API_INVOKE_TEST_SUCCESS = "API_INVOKE_TEST_SUCCESS";
export const API_INVOKE_TEST_FAILURE = "API_INVOKE_TEST_FAILURE";
export const API_INVOKE_TEST_SUCCESS_FAILURE = "API_INVOKE_TEST_SUCCESS_FAILURE"

export const UPDATE_REQUIREMENT_RESPONSE = "UPDATE_REQUIREMENT_RESPONSE";