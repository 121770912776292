export const UPLOAD_FILE_SEGMENT_HEADLINE = "Upload File for Segment";
export const DOWNLOAD_TEMPLATE_HEADLINE = "Download the Template";
export const DOWNLOAD_CSV_TEMPLATE_HEADLINE = "Download the CSV Template";
export const DOWNLOAD_XSLX_TEMPLATE_HEADLINE = "Download the XLSX Template";
export const SEGMENT_UPLOAD_TEXT = "Drag & drop files";
export const SEGMENT_UPLOAD_ADDITIONAL_TEXT =
  "Supported formats: txt. Max Records : 10 Million";
  export const EMAIL_SEGMENT_UPLOAD_ADDITIONAL_TEXT =
  "Supported formats: CSV & XLSX ; Max Records : 3000";  
export const FILE_SUPPORT_TEXT_SEGMENT =
  "Supported file formats: .txt - Max Records:100,000";
export const APPEND = "Append";
export const OVERRIDE = "Override";

export const upload_list_columns = [
  {
    title: "File",
    key: "fileName",
    type: "string",
    visible: true,
    rowConfig: {
			data : "-",
      className : "mw-89"
		}
  },
  {
    title: "Status",
    key: "uploadStatus",
    type: "string",
    visible: true,
    rowConfig: {
			data : "-",
			color: true
		}
  },
  {
    title: "Upload Start Time",
    key: "uploadStartDate",
    type: "string",
    visible: true,
    rowConfig: {
			data : "-",
		}
  },
  {
    title: "Upload End Time",
    key: "uploadEndDate",
    type: "string",
    visible: true,
    rowConfig: {
			data : "-",
		}
  },
  {
    title: "Error File",
    key: "errorFile",
    type: "string",
    visible: true,
    rowConfig: {
			data : "NA",
		}
  },
];

export const popupConfig = {
	tootltip: true,
	theme: "",
	selectedDataKey: "",
	isDisabled: false
}