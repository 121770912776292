import axios from "axios";
import { setErrorToaster, setSuccessToaster } from "..";
import {
	DELETE_IMAGE_SUCCESSFULLY,
	EDITABLE,
	IMAGE_UPLOAD_SUCCESS,
	OFFER_SAVE,
	OFFER_STATUS_UPDATE_SUCCESS_MESSAGE,
	SEND_EMAIL_SUCCESS_MESSAGE,
} from "../../tellurideExtComponents/errorsComponent/ErrorConstant";
import {
	BASE_REDEEM,
	ESLO,
	OFFER_DETAILS_STATUS_ACTIVATED,
	OFFER_DETAILS_STATUS_DRAFT,
	OFRI,
} from "../../tellurideExtComponents/constantComponent/Constants";

import {
	GET_OFFER_DETAILS_REQUEST,
	GET_OFFER_DETAILS_SUCCESS,
	GET_OFFER_DETAILS_FAILURE,
	GET_OFFER_DETAILS_SUCCESS_FAILURE,
	SET_REWARD_DATA,
	SET_RULE_DATA,
	SET_OFFER_DATA,
	SET_LINKED_DATA,
	SET_CAP_DATA,
	CREATE_UPDATE_OFFER_REQUEST,
	CREATE_UPDATE_OFFER_SUCCESS,
	CREATE_UPDATE_OFFER_FAILURE,
	CREATE_UPDATE_OFFER_SUCCESS_FAILURE,
	UPDATE_OFFER_STATUS_REQUEST,
	UPDATE_OFFER_STATUS_SUCCESS,
	UPDATE_OFFER_STATUS_SUCCESS_FAILURE,
	UPDATE_OFFER_STATUS_FAILURE,
	GET_OFFER_LIST_REQUEST,
	GET_OFFER_LIST_SUCCESS,
	GET_OFFER_LIST_SUCCESS_FAILURE,
	GET_OFFER_LIST_FAILURE,
	RESET_VALIDATION_ERRORS,
	UPDATE_VALIDATION_ERRORS,
	UPLOAD_IMAGE_CONTENT_REQUEST,
	UPLOAD_IMAGE_CONTENT_SUCCESS,
	UPLOAD_IMAGE_CONTENT_SUCCESS_FAILURE,
	UPLOAD_IMAGE_CONTENT_FAILURE,
	DELETE_IMAGE_CONTENT_REQUEST,
	DELETE_IMAGE_CONTENT_SUCCESS,
	DELETE_IMAGE_CONTENT_SUCCESS_FAILURE,
	DELETE_IMAGE_CONTENT_FAILURE,
	PREVIEW_IMAGE_CONTENT_REQUEST,
	PREVIEW_IMAGE_CONTENT_SUCCESS,
	PREVIEW_IMAGE_CONTENT_SUCCESS_FAILURE,
	PREVIEW_IMAGE_CONTENT_FAILURE,
	LOAD_IMAGE_CONTENT_REQUEST,
	LOAD_IMAGE_CONTENT_SUCCESS,
	LOAD_IMAGE_CONTENT_SUCCESS_FAILURE,
	LOAD_IMAGE_CONTENT_FAILURE,
	SEND_EMAIL_REQUEST,
	SEND_EMAIL_FAILURE,
	SEND_EMAIL_SUCCESS,
	SEND_EMAIL_SUCCESS_FAILURE,
	SET_OFFER_CHANGE_STATUS,
	SET_ORGANZTATION_NAME,
	SET_UPLOAD_IMAGE,
	SET_BLANK_SESSION_ID_ERROR,
	SET_SESSION_TIMEOUT_POPUP,
	SET_AUTH_ORGANZTATION_NAME,
	SET_OFFER_VALIDATION_ERRORS,
	SET_OFFER_ATTRIBUTE_DATA,
	CREATE_UPDATE_CONTENT_OFFER_REQUEST,
	CREATE_UPDATE_CONTENT_OFFER_SUCCESS,
	CREATE_UPDATE_CONTENT_OFFER_FAILURE,
	CREATE_UPDATE_CONTENT_OFFER_SUCCESS_FAILURE,
	RESET_UPLOAD_IMAGE_DATA,
	SET_UPDATE_STATUS_LOADED,
	SET_EVENT_OFFER_RULE_DATA,
	SET_EVENT_OFFER_ACTION_DATA,
	SET_EVENT_PROCESSING_ERROR,
	GET_ACTION_VERSION_JSON_REQUEST,
	GET_ACTION_VERSION_JSON_SUCCESS,
	GET_ACTION_VERSION_JSON_FAILURE,
	SET_ACTION_JSON,
	RESET_ACTION_JSON_RESPONSE,
	GET_REWARD_OFFER_CODE_REQUEST,
	GET_REWARD_OFFER_CODE_SUCCESS,
	GET_REWARD_OFFER_CODE_FAILURE,
	GET_REWARD_OFFER_CODE_SUCCESS_FAILURE,
} from "./OfferDetailsType";
import {
	CAMPAIGN,
	CONTENT,
	DETAILS,
	OFFER,
	VIEW,
} from "../../tellurideExtComponents/constantComponent/PathConstants";
import {
	ERROR_MESSAGE,
	previewImageError,
	setBlankSession,
	setSessionExpire,
	setToaster,
} from "../reduxUtil";

export const fetchOfferDetails = (requestHeaders, requestBody, redirection) => {
	return (dispatch) => {
		dispatch(fetchOfferDetailsRequest());
		axios
			.post(
				process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_OFFER_DETAILS,
				requestBody,
				requestHeaders
			)
			.then((resp) => {
				if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
					if (redirection.errorRedirectionPath) {
						redirection.navigate(
							"/" +
								window.location.pathname.split("/")[1] +
								redirection.errorRedirectionPath
						);
					}
					// sessionStorage.setItem("offerNumber","")
					dispatch(fetchOfferDetailsSuccessFailure(resp.data));
					dispatch(setErrorToaster(resp.data.errors[0].message));
					setSessionExpire(resp, setSessionExpirePopUp, dispatch);
				} else {
					// sessionStorage.setItem("offerNumber",resp.data?.offerData?.offerNumber)
					dispatch(fetchOfferDetailsSuccess(resp.data));
				}
			})
			.catch((err) => {
				if (redirection.errorRedirectionPath) {
					redirection.navigate(
						"/" +
							window.location.pathname.split("/")[1] +
							redirection.errorRedirectionPath
					);
				}
				dispatch(fetchOfferDetailsFailure(err.message));
				setToaster(err, ERROR_MESSAGE, dispatch);
				setSessionExpire(err, setSessionExpirePopUp, dispatch);
				setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
			});
	};
};

const fetchOfferDetailsRequest = () => {
	return {
		type: GET_OFFER_DETAILS_REQUEST,
	};
};

const fetchOfferDetailsSuccess = (apiData) => {
	return {
		type: GET_OFFER_DETAILS_SUCCESS,
		payload: apiData,
	};
};

const fetchOfferDetailsFailure = (error) => {
	return {
		type: GET_OFFER_DETAILS_FAILURE,
		payload: error,
	};
};
const fetchOfferDetailsSuccessFailure = (error) => {
	return {
		type: GET_OFFER_DETAILS_SUCCESS_FAILURE,
		payload: error,
	};
};

export const updateRewardData = (newValue) => ({
	type: SET_REWARD_DATA,
	payload: newValue,
});
export const updateRuleData = (newValue) => ({
	type: SET_RULE_DATA,
	payload: newValue,
});
export const updateOfferData = (newValue) => ({
	type: SET_OFFER_DATA,
	payload: newValue,
});
export const upadteLinkedOfferData = (newValue) => ({
	type: SET_LINKED_DATA,
	payload: newValue,
});

export const updateOfferAttributeData = (newValue) => ({
	type: SET_OFFER_ATTRIBUTE_DATA,
	payload: newValue,
});

export const updateCapData = (newValue) => ({
	type: SET_CAP_DATA,
	payload: newValue,
});

export const updateEventRuleData = (newValue) => ({
	type: SET_EVENT_OFFER_RULE_DATA,
	payload: newValue,
});

export const updateEventActionData = (newValue) => ({
	type: SET_EVENT_OFFER_ACTION_DATA,
	payload: newValue,
});

export const createUpdateOffer = (
	requestHeaders,
	requestBody,
	isShowToaster,
	isActivateTrue,
	navigate,
	getOfferCallRequired
) => {
	return async (dispatch) => {
		dispatch(createUpdateOfferRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_CREATE_UPDATE_OFFER,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(createUpdateOfferSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(createUpdateOfferSuccess(resp.data));
				if (isActivateTrue === true) {
					updateOfferStatusHandller(
						OFFER_DETAILS_STATUS_ACTIVATED,
						requestHeaders,
						dispatch,
						requestBody?.offerData,
						navigate
					);
				} else if (
					getOfferCallRequired === true 
					// window.location.pathname.includes(DETAILS)
				) {
					// callGetOfferDetails(
					//   requestHeaders,
					//   dispatch,
					//   navigate,
					//   "/offer/view",
					//   requestBody?.offerData?.offerNumber
					//     ? requestBody?.offerData?.offerNumber
					//     : resp?.data?.offerNumber
					// );
					const offerRequestBody = {
						requestorID: OFRI,
						messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
						offerNumber: requestBody?.offerData?.offerNumber
							? requestBody?.offerData?.offerNumber
							: resp?.data?.offerNumber,
					};
					dispatch(
						fetchOfferDetails(requestHeaders, offerRequestBody, {
							navigate: navigate,
							successRedirect: "",
							errorRedirectionPath: OFFER + VIEW,
						})
					);
				}
				if (isShowToaster) {
					dispatch(setSuccessToaster(OFFER_SAVE));
				}
			}
		} catch (err) {
			dispatch(createUpdateOfferFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};
export const updateOfferStatusHandller = (
	statusValue,
	requestHeaders,
	dispatch,
	offerData,
	navigate
) => {
	const updateOfferStatusRequestBody = {
		requestorID: OFRI,
		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
		source: "TELL",
		modifiedBy: offerData?.modifiedBy,
		modifiedTS: "2023-06-27 20:44:29",
		offersInfo: {
			offerInfo: [
				{
					offerNumber: offerData.offerNumber,
					offerStatus: statusValue,
				},
			],
		},
		additionalAttributes: {
			additionalAttribute: [
				{
					name: "RealTimeFlag",
					values: {
						value: ["Y"],
					},
				},
			],
		},
	};

	dispatch(
		updateOfferStatus(
			requestHeaders,
			updateOfferStatusRequestBody,
			navigate,
			offerData
		)
	);
};
export const createUpdateOfferRequest = () => {
	return {
		type: CREATE_UPDATE_OFFER_REQUEST,
	};
};

export const createUpdateOfferSuccess = (apiData) => {
	return {
		type: CREATE_UPDATE_OFFER_SUCCESS,
		payload: apiData,
	};
};
export const createUpdateOfferFailure = (apiData) => {
	return {
		type: CREATE_UPDATE_OFFER_FAILURE,
		payload: apiData,
	};
};
export const createUpdateOfferSuccessFailure = (apiData) => {
	return {
		type: CREATE_UPDATE_OFFER_SUCCESS_FAILURE,
		payload: apiData,
	};
};
export const updateOfferDataValidationErrors = (error) => {
	return {
		type: UPDATE_VALIDATION_ERRORS,
		payload: error,
	};
};
export const resetOfferDataValidationErrors = () => {
	return {
		type: RESET_VALIDATION_ERRORS,
	};
};

// update offer status api start
export const updateOfferStatus = (
	requestHeaders,
	requestBody,
	navigate,
	offerData
) => {
	const controller = new AbortController();
	const offerRequestBody = {
		requestorID: OFRI,
		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
		offerNumber: offerData?.offerNumber ? offerData?.offerNumber : "",
	};
	return async (dispatch) => {
		dispatch(updateOfferStatusRequest());
		try {
			if (
				offerData?.offerType === BASE_REDEEM &&
				requestBody.offersInfo?.offerInfo[0].offerStatus ===
					OFFER_DETAILS_STATUS_ACTIVATED
			) {
				const getOfferListReqBody = {
					requestorID: "OFRI",
					messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
					pagination: {
						recordPerPage: 500000,
						currentPage: 1,
					},
					sort: [],
					offerSearch: {
						id: "",
						name: "",
						description: "",
						type: BASE_REDEEM,
						status: "ACTIVE",
						offerStartDate: "",
						offerEndDate: "",
						lastEditedDate: "",
						createdBy: "",
						searchQuery: "",
					},
				};
				const resp = await axios.post(
					process.env.REACT_APP_URL_DOMAIN +
						process.env.REACT_APP_GET_OFFER_LIST,
					getOfferListReqBody,
					{
						...requestHeaders,
						signal: controller.signal,
					}
				);
				if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
					dispatch(getOfferListSuccessFailure(resp.data));
					dispatch(setErrorToaster(resp.data.errors[0].message));
					setSessionExpire(resp, setSessionExpirePopUp, dispatch);
				} else {
					if (resp?.data?.offerList?.length > 0) {
						dispatch(setUpdateStatusLoaded());
						return dispatch(
							setErrorToaster("Active offer already present for base redeem")
						);
					}
				}
			}

			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_UPDATE_OFFER_STATUS,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(updateOfferStatusSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				// callGetOfferDetails(
				//   requestHeaders,
				//   dispatch,
				//   navigate,
				//   "/offer/view",
				//   offerData?.offerNumber
				// );

				dispatch(
					fetchOfferDetails(requestHeaders, offerRequestBody, {
						navigate: navigate,
						successRedirect: "",
						errorRedirectionPath: OFFER + VIEW,
					})
				);
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(updateOfferStatusSuccess(resp.data));
				// callGetOfferDetails(
				//   requestHeaders,
				//   dispatch,
				//   navigate,
				//   "/offer/view",
				//   offerData?.offerNumber
				// );
				dispatch(
					fetchOfferDetails(requestHeaders, offerRequestBody, {
						navigate: navigate,
						successRedirect: "",
						errorRedirectionPath: OFFER + VIEW,
					})
				);

				const successMessage =
					resp.data?.offerInfoResponse[0]?.offerStatus ===
					OFFER_DETAILS_STATUS_DRAFT
						? EDITABLE
						: resp.data?.offerInfoResponse[0]?.offerStatus?.toLowerCase();
				dispatch(
					setSuccessToaster(
						resp.data?.offerInfoResponse[0]?.offerStatus ===
							OFFER_DETAILS_STATUS_DRAFT
							? successMessage
							: OFFER_STATUS_UPDATE_SUCCESS_MESSAGE + successMessage
					)
				);
			}
		} catch (err) {
			dispatch(updateOfferStatusFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			// callGetOfferDetails(
			//   requestHeaders,
			//   dispatch,
			//   navigate,
			//   "/offer/view",
			//   offerData?.offerNumber
			// );
			dispatch(
				fetchOfferDetails(requestHeaders, offerRequestBody, {
					navigate: navigate,
					successRedirect: "",
					errorRedirectionPath: OFFER + VIEW,
				})
			);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};

export const updateOfferStatusRequest = () => {
	return {
		type: UPDATE_OFFER_STATUS_REQUEST,
	};
};

export const updateOfferStatusSuccess = (apiData) => {
	return {
		type: UPDATE_OFFER_STATUS_SUCCESS,
		payload: apiData,
	};
};
export const updateOfferStatusFailure = (apiData) => {
	return {
		type: UPDATE_OFFER_STATUS_FAILURE,
		payload: apiData,
	};
};
export const updateOfferStatusSuccessFailure = (apiData) => {
	return {
		type: UPDATE_OFFER_STATUS_SUCCESS_FAILURE,
		payload: apiData,
	};
};
// update offer status api end
// get offer list data api start
export const getOfferList = (
	requestHeaders,
	requestBody,
	apiurl,
	controller
) => {
	return async (dispatch) => {
		dispatch(getOfferListRequest());
		try {
			const resp = await axios.post(apiurl, requestBody, {
				...requestHeaders,
				signal: controller.signal,
			});
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(getOfferListSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(getOfferListSuccess(resp.data));
			}
		} catch (err) {
			dispatch(getOfferListFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};
export const getOfferListRequest = () => {
	return {
		type: GET_OFFER_LIST_REQUEST,
	};
};

export const getOfferListSuccess = (apiData) => {
	return {
		type: GET_OFFER_LIST_SUCCESS,
		payload: apiData,
	};
};
export const getOfferListFailure = (apiData) => {
	return {
		type: GET_OFFER_LIST_FAILURE,
		payload: apiData,
	};
};
export const getOfferListSuccessFailure = (apiData) => {
	return {
		type: GET_OFFER_LIST_SUCCESS_FAILURE,
		payload: apiData,
	};
};
// get offer list data api end

//upload Image Content api starts
export const uploadImageContent = (requestHeaders, requestBody) => {
	return async (dispatch) => {
		dispatch(uploadImageContentRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_IMAGE_UPLOAD,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(uploadImageContentSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(uploadImageContentSuccess(resp.data));
				dispatch(setSuccessToaster(IMAGE_UPLOAD_SUCCESS));
			}
		} catch (err) {
			dispatch(uploadImageContentFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};
export const uploadImageContentRequest = () => {
	return {
		type: UPLOAD_IMAGE_CONTENT_REQUEST,
	};
};
export const uploadImageContentSuccess = (apiData) => {
	return {
		type: UPLOAD_IMAGE_CONTENT_SUCCESS,
		payload: apiData,
	};
};
export const uploadImageContentFailure = (apiData) => {
	return {
		type: UPLOAD_IMAGE_CONTENT_FAILURE,
		payload: apiData,
	};
};
export const uploadImageContentSuccessFailure = (apiData) => {
	return {
		type: UPLOAD_IMAGE_CONTENT_SUCCESS_FAILURE,
		payload: apiData,
	};
};
//upload Image Content api ends

//delete Image Content api starts
export const deleteImageContent = (requestHeaders, requestBody) => {
	return async (dispatch) => {
		dispatch(deleteImageContentRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_IMAGE_DELETE,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(deleteImageContentSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(deleteImageContentSuccess(resp.data));
				dispatch(setSuccessToaster(DELETE_IMAGE_SUCCESSFULLY));
			}
		} catch (err) {
			dispatch(deleteImageContentFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};
export const deleteImageContentRequest = () => {
	return {
		type: DELETE_IMAGE_CONTENT_REQUEST,
	};
};
export const deleteImageContentSuccess = (apiData) => {
	return {
		type: DELETE_IMAGE_CONTENT_SUCCESS,
		payload: apiData,
	};
};
export const deleteImageContentFailure = (apiData) => {
	return {
		type: DELETE_IMAGE_CONTENT_FAILURE,
		payload: apiData,
	};
};
export const deleteImageContentSuccessFailure = (apiData) => {
	return {
		type: DELETE_IMAGE_CONTENT_SUCCESS_FAILURE,
		payload: apiData,
	};
};
//delete Image Content api ends

//load Image Content api starts
// LOAD IMAGE
export const loadImageContent = (requestHeaders, requestBody) => {
	return async (dispatch) => {
		dispatch(loadImageContentRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_IMAGE_LOAD,
				requestBody,
				{ responseType: "arraybuffer", ...requestHeaders }
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(loadImageContentSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(loadImageContentSuccess(resp.data));
			}
		} catch (err) {
			dispatch(loadImageContentFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};

export const loadImageContentRequest = () => {
	return {
		type: LOAD_IMAGE_CONTENT_REQUEST,
	};
};
export const loadImageContentSuccess = (apiData) => {
	return {
		type: LOAD_IMAGE_CONTENT_SUCCESS,
		payload: apiData,
	};
};
export const loadImageContentFailure = (apiData) => {
	return {
		type: LOAD_IMAGE_CONTENT_FAILURE,
		payload: apiData,
	};
};
export const loadImageContentSuccessFailure = (apiData) => {
	return {
		type: LOAD_IMAGE_CONTENT_SUCCESS_FAILURE,
		payload: apiData,
	};
};
//load Image Content api ends

//preview Image Content api starts
export const previewImageContent = (requestHeaders, requestBody) => {
	// PREVIEW IMAGE
	return async (dispatch) => {
		dispatch(previewImageContentRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_IMAGE_PREVIEW,
				requestBody,
				{ responseType: "arraybuffer", ...requestHeaders }
			);
			let decodedString = "";
			try {
				decodedString = String.fromCharCode.apply(
					null,
					new Uint16Array(resp.data)
				);
			} catch (e) {}

			let isJSON = false;
			let respJSON = "";
			try {
				respJSON = JSON.parse(decodedString);
				isJSON = true;
			} catch {
				isJSON = false;
			}
			if (isJSON) {
				dispatch(previewImageContentSuccessFailure(respJSON));
				dispatch(setErrorToaster(respJSON.errors[0].message));
				setSessionExpire(respJSON, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(previewImageContentSuccess(resp.data));
			}
		} catch (err) {
			dispatch(previewImageContentFailure(err.message));
			// setToaster(err, ERROR_MESSAGE, dispatch);
			// setSessionExpire(err,setSessionExpirePopUp,dispatch)
			previewImageError(err, setSessionExpirePopUp, ERROR_MESSAGE, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};

export const previewImageContentRequest = () => {
	return {
		type: PREVIEW_IMAGE_CONTENT_REQUEST,
	};
};
export const previewImageContentSuccess = (apiData) => {
	return {
		type: PREVIEW_IMAGE_CONTENT_SUCCESS,
		payload: apiData,
	};
};
export const previewImageContentFailure = (apiData) => {
	return {
		type: PREVIEW_IMAGE_CONTENT_FAILURE,
		payload: apiData,
	};
};
export const previewImageContentSuccessFailure = (apiData) => {
	return {
		type: PREVIEW_IMAGE_CONTENT_SUCCESS_FAILURE,
		payload: apiData,
	};
};
//preview Image Content api ends

export const sendEmail = (requestHeaders, requestBody) => {
	return async (dispatch) => {
		dispatch(sendEmailRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_SEND_EMAIL,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(sendEmailSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(sendEmailSuccess(resp.data));
				dispatch(setSuccessToaster(SEND_EMAIL_SUCCESS_MESSAGE));
			}
		} catch (err) {
			dispatch(sendEmailFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};
export const sendEmailRequest = () => {
	return {
		type: SEND_EMAIL_REQUEST,
	};
};
export const sendEmailSuccess = (apiData) => {
	return {
		type: SEND_EMAIL_SUCCESS,
		payload: apiData,
	};
};
export const sendEmailFailure = (apiData) => {
	return {
		type: SEND_EMAIL_FAILURE,
		payload: apiData,
	};
};
export const sendEmailSuccessFailure = (apiData) => {
	return {
		type: SEND_EMAIL_SUCCESS_FAILURE,
		payload: apiData,
	};
};
export const setOFferChangeStatus = (status) => {
	return {
		type: SET_OFFER_CHANGE_STATUS,
		payload: status,
	};
};

// export const callGetOfferDetails = (
//   requestHeaders,
//   dispatch,
//   navigate,
//   redirectionPath,
//   offerNumber
// ) => {
//   const offerRequestBody = {
//     requestorID: OFRI,
//     messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
//     offerNumber: offerNumber ? offerNumber : "",
//   };
//   dispatch(
//     fetchOfferDetails(requestHeaders, offerRequestBody, {
//       navigate: navigate,
//       successRedirect: "",
//       errorRedirectionPath: redirectionPath,
//     })
//   );
// };

export const setOrganizationName = (authOrgName) => {
	return async (dispatch) => {
		if (authOrgName) {
			dispatch(setStateOrgName(authOrgName));
			dispatch(setStateAuthOrgName(authOrgName));
		} else {
			dispatch(setStateOrgName(ESLO));
		}
	};
};

export const setStateOrgName = (name) => {
	return {
		type: SET_ORGANZTATION_NAME,
		payload: name,
	};
};
export const setStateAuthOrgName = (name) => {
	return {
		type: SET_AUTH_ORGANZTATION_NAME,
		payload: name,
	};
};

export const setUploadImage = (json) => {
	return {
		type: SET_UPLOAD_IMAGE,
		payload: json,
	};
};
export const setBlankSessionIdError = () => {
	return {
		type: SET_BLANK_SESSION_ID_ERROR,
	};
};
export const setSessionExpirePopUp = () => {
	return {
		type: SET_SESSION_TIMEOUT_POPUP,
	};
};
export const setErrorrsForValidations = (errors) => {
	return async (dispatch) => {
		dispatch(setValidationError(errors));
	};
};
export const setValidationError = (apiData) => {
	return {
		type: SET_OFFER_VALIDATION_ERRORS,
		payload: apiData,
	};
};
export const createUpdateContentOffer = (
	requestHeaders,
	requestBody,
	isShowToaster,
	isActivateTrue,
	navigate,
	isCreatedFromOffer,
	isGetDetailsCallRequired
) => {
	return async (dispatch) => {
		dispatch(createUpdateContentOfferRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_CREATE_UPDATE_CONTENT_OFFER,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(createUpdateContentOfferSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				// sessionStorage.setItem(
				// 	"offerNumber",
				// 	resp.data.linkedOffers[0].offerNumber
				// );
				if (isCreatedFromOffer) {
					navigate({
						pathname:
							"/" +
							window.location.pathname.split("/")[1] +
							"/campaign/content" +
							"/details",
						search: `?offerid=${resp.data.linkedOffers[0].offerNumber}`,
					});
				}
				dispatch(createUpdateContentOfferSuccess(resp.data));

				if (isActivateTrue === true) {
					updateOfferStatusHandller(
						OFFER_DETAILS_STATUS_ACTIVATED,
						requestHeaders,
						dispatch,
						requestBody.offerData,
						navigate
					);
				} else if (isGetDetailsCallRequired) {
					// callGetOfferDetails(
					//   requestHeaders,
					//   dispatch,
					//   navigate,
					//   "/campaign/content",
					//   requestBody?.offerData?.offerNumber
					//     ? requestBody?.offerData?.offerNumber
					//     : resp.data.linkedOffers[0].offerNumber
					// );

					const offerRequestBody = {
						requestorID: OFRI,
						messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
						offerNumber: requestBody?.offerData?.offerNumber
							? requestBody?.offerData?.offerNumber
							: resp.data.linkedOffers[0].offerNumber,
					};
					dispatch(
						fetchOfferDetails(requestHeaders, offerRequestBody, {
							navigate: navigate,
							successRedirect: "",
							errorRedirectionPath: CAMPAIGN + CONTENT,
						})
					);
				}
				if (isShowToaster) {
					dispatch(setSuccessToaster(OFFER_SAVE));
				}
			}
		} catch (err) {
			dispatch(createUpdateContentOfferFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};
export const createUpdateContentOfferRequest = () => {
	return {
		type: CREATE_UPDATE_CONTENT_OFFER_REQUEST,
	};
};

export const createUpdateContentOfferSuccess = (apiData) => {
	return {
		type: CREATE_UPDATE_CONTENT_OFFER_SUCCESS,
		payload: apiData,
	};
};
export const createUpdateContentOfferFailure = (apiData) => {
	return {
		type: CREATE_UPDATE_CONTENT_OFFER_FAILURE,
		payload: apiData,
	};
};
export const createUpdateContentOfferSuccessFailure = (apiData) => {
	return {
		type: CREATE_UPDATE_CONTENT_OFFER_SUCCESS_FAILURE,
		payload: apiData,
	};
};
export const resetUploadImageData = (apiData) => {
	return {
		type: RESET_UPLOAD_IMAGE_DATA,
		payload: apiData,
	};
};
export const setUpdateStatusLoaded = () => {
	return {
		type: SET_UPDATE_STATUS_LOADED,
	};
};
export const setEventProcessingErrorStatus = (payload) => {
	return {
		type: SET_EVENT_PROCESSING_ERROR,
		payload: payload,
	};
};

export const getActionVersionJson = (requestHeaders, requestBody) => {
	return async (dispatch) => {
		dispatch(fetchActionVersionJsonRequest());
		try {
			const resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN +
					process.env.REACT_APP_GET_ACTION_VERSION_JSON,
				requestBody,
				requestHeaders
			);
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(fetchActionVersionJsonFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(fetchActionVersionJsonSuccess(resp.data));
				// dispatch(setSuccessToaster(SEND_EMAIL_SUCCESS_MESSAGE));
			}
		} catch (err) {
			dispatch(fetchActionVersionJsonFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			// setSessionExpire(err, setSessionExpirePopUp, dispatch);
			// setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};

const fetchActionVersionJsonRequest = () => {
	return {
		type: GET_ACTION_VERSION_JSON_REQUEST,
	};
};

const fetchActionVersionJsonSuccess = (apiData) => {
	return {
		type: GET_ACTION_VERSION_JSON_SUCCESS,
		payload: apiData,
	};
};

const fetchActionVersionJsonFailure = (error) => {
	return {
		type: GET_ACTION_VERSION_JSON_FAILURE,
		payload: error,
	};
};

export const setActionVersionJson = (json) => {
	return {
		type: SET_ACTION_JSON,
		payload: json,
	};
};
export const resetActionVersionJsonResponse = () => {
	return {
		type: RESET_ACTION_JSON_RESPONSE,
	};
};

export const fetchDataForRewardsOfferCode = (requestHeaders, requestBody, redirection) => {
	return async(dispatch) => {
		dispatch(fetchDataForRewardsOfferCodeRequest());
		try {
		let resp = await axios.post(
				process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_OFFER_DETAILS,
				requestBody,
				requestHeaders
			)
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				if (redirection.errorRedirectionPath) {
					redirection.navigate(
						"/" +
							window.location.pathname.split("/")[1] +
							redirection.errorRedirectionPath
					);
				}
				dispatch(fetchDataForRewardsOfferCodeSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				if(resp.data.offerData.organization.toLowerCase() !== requestHeaders.headers.organization.toLowerCase()) {
					dispatch(setErrorToaster("Invalid Offer Number"));
					dispatch(fetchDataForRewardsOfferCodeFailure(""));
					return;
				} else{
					if(resp.data.offerData.offerStatus === OFFER_DETAILS_STATUS_ACTIVATED) {
						dispatch(fetchDataForRewardsOfferCodeSuccess(resp.data));
					} else {
						dispatch(setErrorToaster("Rewards Offer Code is not an Active offer"));
						dispatch(fetchDataForRewardsOfferCodeFailure(""));
					}
				}
			}
		}
		catch(err)  {
			if (redirection.errorRedirectionPath) {
				redirection.navigate(
					"/" +
						window.location.pathname.split("/")[1] +
						redirection.errorRedirectionPath
				);
			}
			dispatch(fetchDataForRewardsOfferCodeFailure(err.message));
			setToaster(err, ERROR_MESSAGE, dispatch);
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};

const fetchDataForRewardsOfferCodeRequest = () => {
	return {
		type: GET_REWARD_OFFER_CODE_REQUEST,
	};
};

const fetchDataForRewardsOfferCodeSuccess = (apiData) => {
	return {
		type: GET_REWARD_OFFER_CODE_SUCCESS,
		payload: apiData,
	};
};

export const fetchDataForRewardsOfferCodeFailure = (error) => {
	return {
		type: GET_REWARD_OFFER_CODE_FAILURE,
		payload: error,
	};
};
const fetchDataForRewardsOfferCodeSuccessFailure = (error) => {
	return {
		type: GET_REWARD_OFFER_CODE_SUCCESS_FAILURE,
		payload: error,
	};
};
