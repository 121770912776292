export const OFFER_SAVE = "Offer saved successfully";
export const OFFER_STATUS_UPDATE_SUCCESS_MESSAGE = "Offer is successfully ";
export const OFFER_STATUS_UPDATE_MESSAGE = "Offer is ";
export const SEGMENT_SAVE = "Segment saved successfully";
export const SEGMENT_CREATE = "Segment created successfully";
export const SEGMENT_FILE_UPLOAD_SUCCESS = "File uploaded successfully";
export const SEGMENT_FILE_UPLOAD_ERROR = "Error while uploading file";
export const SEGMENT_FILE_DELETE_SUCCESS = "File delete successfully";
export const SEGMENT_UPDATE = "Segment updated successfully";
export const EDITABLE = "Offer is in Draft mode and can be edited";
export const IMAGE_UPLOAD_SUCCESS = "Image uploaded successfully";
export const SEND_EMAIL_SUCCESS_MESSAGE = "Email sent successfully";
export const WINNER_VARIATION_SUCCESS = "Winner Variation saved successfully";
export const START_AB_TEST_SUCCESS = "AB Testing started successfully";
export const LAUNCH_OFFER_SUCCESS = "Offer is successfully activated";
export const DELETE_IMAGE_SUCCESSFULLY = "Image deleted successfully";
export const ATTRIBUTE_CREATE = "Attribute created successfully";
export const CONNECTOR_CREATE = "Connector created successfully";
export const CONNECTOR_UPDATED = "Connector updated successfully";


export const TELL401 = "TELL-401";
export const TELL400 = "TELL-400";
export const TELL403 = "TELL-403";
export const TELL98 = "TELL-98";
export const TELL99 = "TELL-99";
export const ERR_NETWORK = "ERR_NETWORK";
