import { motion } from "framer-motion/dist/framer-motion";
import { FaRegTimesCircle } from "react-icons/fa";
import "./OfferSelectionPopupComponent.style.scss";
import { CONTENT_OFFER_COLUMNS } from "../createContentComponent/CreateContentConstant";
import {
	DATE_TYPE_OPTIONS,
	OFFER_TABLE_REQ_BODY_KEY,
	OFFER_TABLE_RES_BODY_KEY,
	VIEW_OFFER,
	VIEW_OFFER_PLACEHOLDER,
} from "../../offerSetupComponent/viewOfferComponent/TableViewConstants";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable";
import { REDUX, TYPE } from "../../constantComponent/Constants";
import { formatDate } from "../../utilsComponent/CommonUtil";
import { getDefaultFilter } from "../../utilsComponent/TableUtil/GenericTableUtil";
import { COLUMNS } from "../contentListComponent/ContentListConstants";
const OfferSelectionPopupComponent = ({
	onValueSelect,
	setShowPopup,
	setInputFieldsValue,
	inputFieldsValue,
}) => {
  const advanceSearchHeader = {
    searchBar: true,
    globalFilter: true,
    dateType: true,
    dateRange: true,
  };
  function generateCustomRequestBody(tableReqBody, dateType, dateRange) {
    let requestBody = {
      ...tableReqBody,
      [OFFER_TABLE_REQ_BODY_KEY]: {
        ...tableReqBody[OFFER_TABLE_REQ_BODY_KEY],
        dateType: dateType,
        fromDate: formatDate(dateRange[0].startDate),
        toDate: formatDate(dateRange[0].endDate),
        type: inputFieldsValue?.filterCriteria ? inputFieldsValue?.filterCriteria : "Points Earn",
        status: "ACTIVE",
        // type: tableReqBody[OFFER_TABLE_REQ_BODY_KEY][TYPE]
        //   ? tableReqBody[OFFER_TABLE_REQ_BODY_KEY][TYPE]
        //   : getDefaultFilter(COLUMNS, TYPE),
      },
      // pagination: {
      //   recordPerPage: 10,
      // },
    };
    return requestBody;
  }
  function handleRowClick(row) {
    if (row.id) {
      setInputFieldsValue((prev) => {
        return {
          ...prev,
          sywrOfferCode: row.id,
        };
      });
      onValueSelect(row.id);
    }
  }
  return (
    <>
      <section className="action-campaign-modal-backdrop create-connector-popup offer-selection-popup">
        <motion.div
          className="inner-modal-actions"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="header">
            <h4>Loyalty List</h4>
            <div className="close-campaign-popup-container">
              <FaRegTimesCircle
                onClick={() => {
                  setShowPopup(false);
                }}
                size={25}
                className="email-modal-close-icon"
              />
            </div>
          </div>
          {/* <div className="search-bar-container-email"> */}
          <GenericTable
            COLUMNS={CONTENT_OFFER_COLUMNS}
            FILTER_SHOW_LIMIT={1}
            SHOW_HEADER={true}
            ADVANCE_SEARCH_HEADER={advanceSearchHeader}
            ADVANCE_SEARCH_PLACEHOLDER={VIEW_OFFER_PLACEHOLDER}
            API_URL={process.env.REACT_APP_GET_OFFER_LIST}
            TABLE_SRC={VIEW_OFFER}
            SHOW_HEADER_DASHBOARD={false}
            SHOW_TOOLTIP={false}
            ROW_CLICKABLE={true}
            ROW_CLICK_HANDLER={handleRowClick}
            DATE_TYPE_OPTIONS={DATE_TYPE_OPTIONS}
            REQUEST_BODY_KEY={OFFER_TABLE_REQ_BODY_KEY}
            RESPONSE_BODY_KEY={OFFER_TABLE_RES_BODY_KEY}
            IS_SEARCH_ALLOWED={true}
            CSTM_REQ_BODY={generateCustomRequestBody}
            CSTM_REQ_BODY_KEY={OFFER_TABLE_REQ_BODY_KEY}
            SAVE_SEARCHED_FILTER={{
              isSaved: false, //  true || false => default : true
              storage: REDUX, // redux  || local || session  => default : "redux"
            }}
            SELECTED_VALUE={inputFieldsValue.sywrOfferCode}
            INITIAL_PAGINATION={{
              recordPerPage: 10,
              currentPage: 1,
            }}
            INITIAL_SEARCH={
							inputFieldsValue.sywrOfferCode
								? [
										{
											Type: "Search",
											data: inputFieldsValue.sywrOfferCode,
											status: true,
										},
								  ]
								: []
						}
          />
          {/* </div> */}
        </motion.div>
      </section>
    </>
  );
};
export default OfferSelectionPopupComponent;
