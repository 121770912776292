/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import "./ContentBuilder.style.scss";
import {
  CATEGORY_HEADER,
  COMMUNICATE_HEADER,
  CUSTOM,
  DISPLAY_HEADER,
  INPUT_FIELDS,
  tempPromtResponse,
} from "./ContentBuilderContstants";
import { getCustomStyles } from "../contentDetailsComponent/ContentDetailsUtil";
import Select from "react-select";
import { images } from "../../imagesComponent/Images";
import FilesDragAndDrop from "../../utilsComponent/FilesDragAndDropUtil/FilesDragAndDrop";
import { createBodyline, createHeadline } from "./ContentBuilderUtil";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import PreviewTestContent from "./PreviewTestContent";
import { useDispatch, useSelector } from "react-redux";
import { CONTENT_PREVIEW_AND_TEST } from "../contentDetailsComponent/ContentDetailsConstants";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { getRightsFromSession, skey } from "../../utilsComponent/CommonUtil";
import { generateInitialLinkedOfferJSON } from "../../offerSetupComponent/communicateComponent/CommunicateUtil";
import {
  contentGenerationDetails,
  fetchDataForRewardsOfferCode,
  fetchDataForRewardsOfferCodeFailure,
  loadImageContent,
  setUploadImage,
  upadteLinkedOfferData,
  uploadImageContent,
} from "../../../tellurideExtRedux";
import {
  DARK,
  headers,
  OFRI,
  uploadFileHeader,
} from "../../constantComponent/Constants";
import { AiFillPicture } from "react-icons/ai";
import { miniloading } from "../../LoaderComponents/loadingDesignConstant";
import GetInputFields from "../../utilsComponent/InputFieldHandler/InputFieldUtil";
import Skeleton from "react-loading-skeleton";
import { CONTENT } from "../../constantComponent/PathConstants";
import { FaRegTimesCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import GenericRules from "../../utilsComponent/GenericRules/GenericRules";
import { INITIAL_DEFAULT_RULES } from "../../utilsComponent/GenericRules/GenericRulesConstants";
import { convertToInitialJSON } from "../../utilsComponent/GenericRules/GenericRulesUtil";
import OfferSelectionPopupComponent from "../contentPopups/OfferSelectionPopupComponent";

const BubbleTheme = Quill.import("themes/bubble");
class ExtendBubbleTheme extends BubbleTheme {
  constructor(quill, options) {
    super(quill, options);

    quill.on("selection-change", (range) => {
      if (range) {
        quill.theme.tooltip.show();
        quill.theme.tooltip.position(quill.getBounds(range));
      }
    });
  }
}
const ContentBuilder = ({
  theme,
  contentBuilderData,
  setContentBuilderData,
  isDisabled,
  setDeletedImages,
  inputFieldsValue,
  setInputFieldsValue,
  inputFields,
  setInputFields,
  handleCategoryChange,
  handleSubCategoryChange,
  setValidation,
  validation,
}) => {
  const startEndDateRef = useRef(null);
  const offerContentData = useSelector((state) => state.offerDetailsData);
  const securityData = useSelector((state) => state.securityData);

  const ContentOfferData = offerContentData.offerDetailsResponse;
  const offerType = offerContentData?.offerData?.offerType || CUSTOM;
  const actionJson = ContentOfferData?.actions?.actionJson;
  const [showProcess, setShowProcess] = useState(false);
  const [promptText, setPromptText] = useState("");
  const [showSuggestionBtn, setShowSuggestionBtn] = useState({
    currentFocusId: "",
    show: false,
  });
  const [selectedResponse, setSelectedResponse] = useState({
    currId: "",
    reponse: "",
  });
  const [downLoadClicked, setDownLoadClicked] = useState({
    status: false,
    fileName: "",
  });
  const [componentName, setPoppupComponentName] = useState("");
  const dispatch = useDispatch();
  const metaData = useSelector((state) => state.metaData);
  const categoryOptions = metaData?.categoriesResponse || [];
  const subCategoryOptions = metaData?.subCategoriesResponse || [];
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const [showPopup, setShowPopup] = useState(false);
  const [imageUpload, setImageUpload] = useState({
    fieldName: "",
    loading: false,
  });

  const DYNAMIC_INPUT_FIELDS = metaData?.getContentAttrsResponse?.fields || [];
  useEffect(() => {
    let isMounted = true;

    if (ContentOfferData?.actions?.actionJson?.sywrOfferCode) {
      headers.sessionId = skey();
      headers.requestAccess = getRightsFromSession();
      headers.organization = offerDetailsPageData?.authOrganizationName;

      const requestHeaders = {
        headers,
      };

      const offerRequestBody = {
        requestorID: OFRI,
        messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
        offerNumber: ContentOfferData?.actions?.actionJson?.sywrOfferCode || "",
      };

      dispatch(
        fetchDataForRewardsOfferCode(requestHeaders, offerRequestBody, {})
      )
        .then(() => {
          if (!isMounted) return;
          // Handle the response if needed
        })
        .catch((error) => {
          if (!isMounted) return;
          // Handle the error if needed
        });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (componentName) {
      setShowPopup(true);
    }
  }, [componentName]);
  function handleAddHeadline() {
    if (contentBuilderData.headlines.length < 4) {
      setContentBuilderData((prev) => {
        return {
          ...prev,
          headlines: [...prev.headlines, createHeadline()],
        };
      });
    }
  }
  const onValueSelect = (value) => {
    setShowPopup(false);
    if (value) {
      getOfferDetailsAPI(value);
    }
  };
  function handleAddBodyline() {
    if (contentBuilderData.bodylines.length < 4) {
      setContentBuilderData((prev) => {
        return {
          ...prev,
          bodylines: [...prev.bodylines, createBodyline()],
        };
      });
    }
  }
  function handleRemoveHeadline(id) {
    setContentBuilderData((prev) => {
      return {
        ...prev,
        headlines: prev.headlines.filter((h) => h.id !== id),
      };
    });
  }
  function handleRemoveBodyline(id) {
    setContentBuilderData((prev) => {
      return {
        ...prev,
        bodylines: prev.bodylines.filter((b) => b.id !== id),
      };
    });
  }

  useEffect(() => {
    Quill.register("themes/bubble", ExtendBubbleTheme);
  }, []);
  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      // [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    // "list",
    // "bullet",
    "link",
    "color",
    "background",
    "script",
  ];
  function uploadImage(files) {
    uploadFileHeader.sessionId = skey();
    const headers = uploadFileHeader;
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerContentData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const file = files[0];

    let requestBody = new FormData();
    requestBody.append("file", file);
    requestBody.append("partnerId", offerContentData?.authOrganizationName);
    requestBody.append("contentId", offerContentData.offerData.offerNumber);
    dispatch(uploadImageContent(requestHeaders, requestBody));
  }
  function onUpload(files, fieldName) {
    setImageUpload({
      fieldName: fieldName,
      loading: true,
    });
    setInputFields((prev) => {
      return prev.map((prevInput) => {
        if (prevInput.fieldname === fieldName) {
          return {
            ...prevInput,
            uploading: true,
          };
        } else if (prevInput.inputType === "image") {
          return {
            ...prevInput,
            disabled: true,
          };
        }
        return prevInput;
      });
    });
    uploadImage(files);
  }
  async function fetchImage() {
    var blob = new Blob([offerContentData.loadImageContentResponse], {
      type: "image/jpeg",
    });
    const reader = new FileReader();
    reader.onload = function () {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = reader.result;
      a.download = downLoadClicked.fileName || "image.jpeg";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    reader.readAsDataURL(blob);
  }
  function downloadImage(image) {
    headers.sessionId = skey();
    headers.organization = offerContentData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      imageId: image.imageId,
      imageName: image.imageName,
      partnerId: offerContentData?.organizationName,
      contentId: offerContentData.offerData.offerNumber,
    };
    setDownLoadClicked({ status: true, fileName: image.imageName });
    dispatch(loadImageContent(requestHeaders, requestBody));
  }
  function deleteImage() {
    setContentBuilderData((prev) => {
      return {
        ...prev,
        imageId: "",
        imageName: "",
      };
    });
  }
  function deleteDynamicImage(image) {
    setDeletedImages((prevDeletedImages) => {
      return [
        ...prevDeletedImages,
        {
          imageId: image.imageId,
        },
      ];
    });
  }

  // const validationFunctions = {
  //   getOfferDetailsAPI: (value) => getOfferDetailsAPI(value),
  // };

  function getOfferDetailsAPI(value) {
    if (value) {
      headers.sessionId = skey();
      headers.requestAccess = getRightsFromSession();
      headers.organization = offerDetailsPageData?.authOrganizationName;
      const requestHeaders = {
        headers,
      };
      const offerRequestBody = {
        requestorID: OFRI,
        messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
        offerNumber: value || "",
      };

      return dispatch(
        fetchDataForRewardsOfferCode(requestHeaders, offerRequestBody, {})
      );
    }
    // Add fetch or axios request to validate the offer code
  }
  useEffect(() => {
    if (contentBuilderData.bodylines.length < 1) {
      setContentBuilderData((prev) => {
        return {
          ...prev,
          bodylines: [...prev.bodylines, createBodyline()],
        };
      });
    }
    if (contentBuilderData.headlines.length < 1) {
      setContentBuilderData((prev) => {
        return {
          ...prev,
          headlines: [...prev.headlines, createHeadline()],
        };
      });
    }
  }, [contentBuilderData]);
  useEffect(() => {
    (async () => {
      if (
        downLoadClicked.status === true &&
        offerContentData.loadImageContentResponse
      ) {
        await fetchImage(offerContentData.loadImageContentResponse);
        setDownLoadClicked({ status: false, fileName: "" });
      }
    })();
  }, [offerContentData.loadImageContentResponse]);
  useEffect(() => {
    if (
      DYNAMIC_INPUT_FIELDS.length > 0 &&
      metaData.getContentAttrsLoading === false &&
      metaData.getContentAttrsResponse &&
      contentBuilderData.subCategory
    ) {
      setInputFields(
        DYNAMIC_INPUT_FIELDS.map((input) => {
          if (input.inputType === "image") {
            return {
              ...input,
              onUpload: onUpload,
              onDelete: deleteDynamicImage,
              onDownload: downloadImage,
              theme: theme,
            };
          }
          return {
            ...input,
            theme: theme,
          };
        })
      );
      let values = DYNAMIC_INPUT_FIELDS.reduce((acc, input) => {
        return {
          ...acc,
          [input.fieldname]: actionJson?.hasOwnProperty(input?.fieldname)
            ? actionJson[input?.fieldname]
            : "",
        };
      }, {});
      setInputFieldsValue(values);
    }
  }, [contentBuilderData.subCategory, DYNAMIC_INPUT_FIELDS]);
  useEffect(() => {
    if (metaData.getContentAttrsError) {
      setInputFields([]);
      setInputFieldsValue({});
    }
  }, [metaData.getContentAttrsError]);
  useEffect(() => {
    if (
      offerContentData.uploadImageContentLoading === false &&
      offerContentData.uploadImageContentResponse &&
      offerContentData.uploadImageContentResponse?.status === "200" &&
      imageUpload.loading
    ) {
      if (imageUpload.fieldName === "contentImage") {
        setContentBuilderData((prev) => {
          return {
            ...prev,
            imageId: offerContentData?.uploadImageContentResponse?.imageId,
            imageName: offerContentData?.uploadImageContentResponse?.imageName,
          };
        });
        setInputFields((prev) => {
          return prev.map((prevInput) => {
            if (prevInput.inputType === "image") {
              return {
                ...prevInput,
                disabled: false,
              };
            }
            return prevInput;
          });
        });
        setImageUpload({
          fieldName: "",
          loading: false,
        });
      } else {
        setInputFields((prev) => {
          return prev.map((prevInput) => {
            if (prevInput.fieldname === imageUpload.fieldName) {
              return {
                ...prevInput,
                uploading: false,
              };
            }
            return prevInput;
          });
        });
        setInputFieldsValue((prev) => {
          return {
            ...prev,
            [imageUpload.fieldName]: {
              imageId: offerContentData?.uploadImageContentResponse?.imageId,
              imageName:
                offerContentData?.uploadImageContentResponse?.imageName,
              imagePath:
                offerContentData?.uploadImageContentResponse?.imagePath,
              partnerId: offerContentData.authOrganizationName  
            },
          };
        });
        setImageUpload({
          fieldName: imageUpload.fieldName,
          loading: false,
        });
      }
    }
  }, [offerContentData.uploadImageContentResponse]);
  useEffect(() => {
    if (offerContentData.uploadImageContentError === true) {
      setImageUpload({
        fieldName: "",
        loading: false,
      });
      setInputFields((prev) => {
        return prev.map((prevInput) => {
          if (prevInput.fieldname === imageUpload.fieldName) {
            return {
              ...prevInput,
              uploading: false,
            };
          } else if (prevInput.inputType === "image") {
            return {
              ...prevInput,
              disabled: false,
            };
          }
          return prevInput;
        });
      });
    }
  }, [offerContentData.uploadImageContentError]);

  useEffect(() => {
    setInputFields((prev) => {
      return prev.map((prevInput) => {
        return {
          ...prevInput,
          disabled: isDisabled,
        };
      });
    });
  }, [isDisabled, metaData.getContentAttrsResponse]);

  useEffect(() => {
    if (
      DYNAMIC_INPUT_FIELDS.length > 0 &&
      contentBuilderData.category &&
      contentBuilderData.subCategory
    ) {
      setValidation(() => {
        let newValidation = {};
        DYNAMIC_INPUT_FIELDS?.forEach((input) => {
          newValidation[input.fieldname] = {
            status: false,
            message: "",
            touched: false,
          };
        });
        return newValidation;
      });
    }
  }, [DYNAMIC_INPUT_FIELDS]);

  useEffect(() => {
    if (contentBuilderData.category && contentBuilderData.subCategory) {
      Object.keys(inputFieldsValue).forEach((key) => {
        const input = inputFields.find((input) => input.fieldname === key);
        let errorMessage = "";
        if (input) {
          if (input.validation) {
            if (input?.validation?.required) {
              if (!inputFieldsValue[key]) {
                errorMessage = `${input.displayName} is required`;
              } else if (
                input.inputType === "image" &&
                !inputFieldsValue[key].imageId
              ) {
                errorMessage = `${input.displayName} is required`;
              } else if (
                input.inputType === "image" &&
                inputFieldsValue[key].imageId &&
                inputFieldsValue[key].imageName
              ) {
                errorMessage = "";
              } else {
                errorMessage = "";
              }
            }
            if (input?.validation?.maxLength && inputFieldsValue[key]) {
              if (inputFieldsValue[key].length > input?.validation?.maxLength) {
                errorMessage = `Max length must be ${input.validation.maxLength}`;
              } else {
                errorMessage = errorMessage ? errorMessage : "";
              }
            }
            if (input?.validation?.minLength && inputFieldsValue[key]) {
              if (inputFieldsValue[key].length < input?.validation?.minLength) {
                errorMessage = `Min length must be ${input.validation.minLength}`;
              } else {
                errorMessage = errorMessage ? errorMessage : "";
              }
            }
            if (
              input?.validation?.pattern &&
              inputFieldsValue[key] &&
              inputFieldsValue[key].length > 0
            ) {
              let regex = new RegExp(input?.validation?.pattern.slice(1, -1));
              if (!regex.test(inputFieldsValue[key])) {
                errorMessage = input?.validation?.patternMessage;
              } else {
                errorMessage = errorMessage ? errorMessage : "";
              }
            }
            setValidation((prev) => {
              return {
                ...prev,
                [key]: {
                  ...prev[key],
                  status: errorMessage ? true : false,
                  message: errorMessage,
                },
              };
            });
          }
        }
      });
    }
  }, [inputFieldsValue]);
  useEffect(() => {
    if (inputFieldsValue?.sywrOfferCode) {
      getOfferDetailsAPI();
    }
  }, [inputFieldsValue?.sywrOfferCode]);
  useEffect(() => {
    //console.log("hello2 --",inputFieldsValue?.offerType,offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerType);
    if (inputFieldsValue?.offerType && offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerType&&
      inputFieldsValue?.offerType !==
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerType
    ) {
      //console.log("hello1 --",inputFieldsValue?.offerType,offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerType);
      
      dispatch(fetchDataForRewardsOfferCodeFailure(""));
      setInputFieldsValue((prev) => {
        return {
          ...prev,
          sywrOfferCode: "",
        };
      });
    }
    if (inputFields && inputFieldsValue?.offerType) {
      const options = inputFields.filter(
        (val) => val.fieldname === "offerType"
      )[0]?.options;
      if (options) {
        const filterCriteria = options.filter(
          (option) => option.value === inputFieldsValue?.offerType
        )[0]?.filterCriteria;

        setInputFieldsValue((prev) => {
          return {
            ...prev,
            filterCriteria: filterCriteria,
          };
        });
      }
    }
  }, [inputFieldsValue?.offerType]);

  useEffect(() => {
    if (inputFields && inputFieldsValue?.offerType && !inputFieldsValue?.filterCriteria) {
      const options = inputFields.filter(
        (val) => val.fieldname === "offerType"
      )[0]?.options;
      if (options) {
        const filterCriteria = options.filter(
          (option) => option.value === inputFieldsValue?.offerType
        )[0]?.filterCriteria;

        setInputFieldsValue((prev) => {
          return {
            ...prev,
            filterCriteria: filterCriteria,
          };
        });
      }
    }
  }, [inputFields]);
  useEffect(() => {
    const validationStatus = Object.keys(validation).some(
      (key) => validation[key].status === true
    );
    if (validationStatus) {
      setInputFields((prev) => {
        return prev.map((prevInput) => {
          return {
            ...prevInput,
            validationStatus: {
              status: validation[prevInput.fieldname]?.status,
              message: validation[prevInput.fieldname]?.message,
              touched: validation[prevInput.fieldname]?.touched,
            },
          };
        });
      });
    }
    // check if every input object status is false
    const validationStatusFalse = Object.keys(validation).every(
      (key) => validation[key].status === false
    );
    if (validationStatusFalse) {
      setInputFields((prev) => {
        return prev.map((prevInput) => {
          return {
            ...prevInput,
            validationStatus: {
              status: false,
              message: "",
              touched: false,
            },
          };
        });
      });
    }
  }, [validation]);
  const handleViewOffer = (value) => {
    setPoppupComponentName(value);
    if (value === "Loyalty") {
      setShowPopup(true);
    }
  };
  useEffect(() => {
    if (
      inputFieldsValue.sywrOfferCode !==
      ContentOfferData?.actions?.actionJson?.sywrOfferCode
    ) {
      dispatch(fetchDataForRewardsOfferCodeFailure(""));
      const mainDiv = document.querySelector(".date-display");
      if (mainDiv) {
        mainDiv.style.display = "none";
      }
    } else {
      if (
        offerDetailsPageData?.rewardOfferCodeResponse?.offerData
          ?.offerNumber !== inputFieldsValue?.sywrOfferCode
      ) {
        dispatch(fetchDataForRewardsOfferCodeFailure(""));
        setStartEndDateValues();
      }
    }
  }, [inputFieldsValue?.sywrOfferCode]);
  useEffect(() => {
    if(startEndDateRef.current !== null && offerDetailsPageData?.rewardOfferCodeResponse) {
      setStartEndDateValues();
    }
  }, [startEndDateRef.current, offerDetailsPageData?.rewardOfferCodeResponse])

  const setStartEndDateValues = () => {
    const startDateMessageDiv = document.querySelector(
      ".error-message.fs-12.startDate"
    );
    const endDateMessageDiv = document.querySelector(
      ".error-message.fs-12.endDate"
    );
    const mainDiv = document.querySelector(".date-display");
    if (
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData
        ?.offerStartDate &&
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerEndDate
    ) {
      if (mainDiv) {
        mainDiv.style.display = "flex";
      }
    }
    if (
      startDateMessageDiv &&
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerStartDate
    ) {
      startDateMessageDiv.textContent =
        "Start Date : " +
          offerDetailsPageData?.rewardOfferCodeResponse?.offerData
            ?.offerStartDate || "";
    }
    if (
      endDateMessageDiv &&
      offerDetailsPageData?.rewardOfferCodeResponse?.offerData?.offerEndDate
    ) {
      endDateMessageDiv.textContent =
        "End Date : " +
          offerDetailsPageData?.rewardOfferCodeResponse?.offerData
            ?.offerEndDate || "";
    }
  };
  return (
    <>
      {offerType === CUSTOM ? (
        <section>
          <div className="section-top-title setup-page-section-title">
            {CATEGORY_HEADER}
          </div>
          <div className="content-builder-wrapper">
            <div className="dropdown-container">
              <fieldset
                className="content-fieldset-dropdown dropdown"
                disabled={isDisabled || offerType !== CUSTOM}
              >
                <legend className="required">{"Category"}</legend>
                <Select
                  styles={getCustomStyles(theme)}
                  className="content-dropdown"
                  value={
                    contentBuilderData.category
                      ? {
                          label: categoryOptions.find(
                            (option) =>
                              option.categoryCode ===
                              contentBuilderData.category
                          )?.categoryName,
                          value: contentBuilderData.category,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setContentBuilderData((prev) => {
                      return {
                        ...prev,
                        category: e.value,
                      };
                    });
                    handleCategoryChange(e.value);
                  }}
                  options={categoryOptions?.map((option) => {
                    return {
                      label: option.categoryName,
                      value: option.categoryCode,
                    };
                  })}
                  isDisabled={isDisabled || offerType !== CUSTOM}
                  isSearchable={false}
                  placeholder={"Select..."}
                />
              </fieldset>
              <fieldset
                className="content-fieldset-dropdown dropdown"
                disabled={isDisabled || offerType !== CUSTOM}
              >
                <legend className="required">{"Sub-Category"}</legend>
                <Select
                  styles={getCustomStyles(theme)}
                  className="content-dropdown"
                  value={
                    contentBuilderData.subCategory
                      ? {
                          label: subCategoryOptions.find(
                            (option) =>
                              option.subCategoryCode ===
                              contentBuilderData.subCategory
                          )?.subCategoryName,
                          value: contentBuilderData.subCategory,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setContentBuilderData((prev) => {
                      return {
                        ...prev,
                        subCategory: e.value,
                      };
                    });
                    handleSubCategoryChange(e.value);
                  }}
                  isLoading={metaData.subCategoriesLoading}
                  options={
                    !metaData.subCategoriesLoading
                      ? subCategoryOptions.map((option) => {
                          return {
                            label: option.subCategoryName,
                            value: option.subCategoryCode,
                          };
                        })
                      : []
                  }
                  isDisabled={isDisabled || offerType !== CUSTOM}
                  isSearchable={false}
                  placeholder={"Select..."}
                />
              </fieldset>
            </div>
          </div>
        </section>
      ) : null}
      <section>
        <div className="section-top-title setup-page-section-title">
          {COMMUNICATE_HEADER}
        </div>
        <div className="content-builder-wrapper">
          <div className="communicate-container">
            <div className="communicate-headline-wrapper">
              <p className="sub-header-content">
                Add upto four headlines for your content
              </p>
              <div className="headline-row">
                <AnimatePresence>
                  {contentBuilderData.headlines.map(({ value, id }, index) => {
                    return (
                      <>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="textbox-wrapper"
                          key={id}
                        >
                          <fieldset className="text-box" disabled={isDisabled}>
                            <legend>{`Headline ${index + 1}`}</legend>
                            <ReactQuill
                              theme={"bubble"}
                              modules={modules}
                              formats={formats}
                              bounds={".app"}
                              placeholder={"write something..."}
                              value={value}
                              style={{
                                height: "100%",
                                zIndex: 100,
                              }}
                              className="quill-editor"
                              onFocus={(range, source, editor) => {
                                setPromptText(editor.getText());

                                setShowSuggestionBtn({
                                  currentFocusId: id,
                                  show: true,
                                });
                                // setPromptText(editor.getText());
                              }}
                              onBlur={(range, source, quill) => {
                                // if (
                                //   !event.relatedTarget ||
                                //   event.relatedTarget.tagName !== "A"
                                // ) {
                                //   setShowSuggestionBtn({
                                //     currentFocusId: "",
                                //     show: false,
                                //   });
                                // }
                              }}
                              onChange={(value, delta, source, editor) => {
                                // if (value.includes("<p><br></p>")) {
                                //   value = value.replace("<p><br></p>", "");
                                // }
                                if (source === "user") {
                                  setContentBuilderData((prev) => {
                                    return {
                                      ...prev,
                                      headlines: prev.headlines.map((h) => {
                                        if (
                                          h.id === id &&
                                          editor.getText().length <= 41
                                        ) {
                                          setPromptText(editor.getText());
                                          return {
                                            ...h,
                                            value: value,
                                          };
                                        }
                                        return h;
                                      }),
                                    };
                                  });
                                }
                              }}
                            />
                          </fieldset>
                          {index === 0 &&
                          contentBuilderData.headlines.length === 0 ? (
                            <img
                              src={images.addImage}
                              alt="add"
                              className={`addHeadlineBtn`}
                              onClick={() => {
                                if (isDisabled !== true) {
                                  handleAddHeadline();
                                }
                              }}
                            ></img>
                          ) : null}

                          {contentBuilderData.headlines.length !== 1 && (
                            <img
                              src={images.trashImage}
                              alt="delete"
                              className={`delete-img ${
                                isDisabled === true ? "disabled" : null
                              }`}
                              onClick={() => {
                                if (
                                  contentBuilderData.headlines.length !== 1 &&
                                  isDisabled !== true
                                ) {
                                  handleRemoveHeadline(id);
                                }
                              }}
                            />
                          )}
                          {contentBuilderData.headlines.length > 0 &&
                            index ===
                              contentBuilderData.headlines.length - 1 && (
                              <img
                                src={images.addImage}
                                alt="add"
                                className={`addHeadlineBtn ${
                                  contentBuilderData.headlines.length >= 4 ||
                                  isDisabled
                                    ? "disabled"
                                    : null
                                }`}
                                onClick={() => {
                                  if (isDisabled !== true) {
                                    handleAddHeadline();
                                  }
                                }}
                              ></img>
                            )}
                        </motion.div>
                        {/* {showSuggestionBtn.currentFocusId === id &&
                          showSuggestionBtn.show && (
                            <a
                              className="btn btn-primary suggestion-btn"
                              onClick={() => {
                                setShowProcess(true);
                                setShowSuggestionBtn((prev) => {
                                  return {
                                    ...prev,
                                    show: false,
                                  };
                                });
                              }}
                            >
                              See suggestion
                            </a>
                          )} */}
                      </>
                    );
                  })}
                </AnimatePresence>
              </div>
            </div>
            <div className="communicate-bodyline-wrapper">
              <p className="sub-header-content">
                Add upto four bodylines for your content
              </p>
              <div className="headline-row mb-16">
                <AnimatePresence>
                  {contentBuilderData.bodylines.map(({ value, id }, index) => {
                    return (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="textbox-wrapper"
                        key={id}
                      >
                        <fieldset
                          className="text-box"
                          disabled={isDisabled}
                          key={index}
                        >
                          <legend>{`Bodyline ${index + 1}`}</legend>
                          <ReactQuill
                            theme={"bubble"}
                            modules={modules}
                            formats={formats}
                            bounds={".app"}
                            placeholder={"write something..."}
                            value={value}
                            style={{
                              height: "100%",
                              zIndex: 100,
                            }}
                            className="quill-editor"
                            onChange={(value, delta, source, editor) => {
                              // if (value.includes("<p><br></p>")) {
                              //   value = value.replace("<p><br></p>", "");
                              // }
                              if (source === "user") {
                                setContentBuilderData((prev) => {
                                  return {
                                    ...prev,
                                    bodylines: contentBuilderData.bodylines.map(
                                      (b) => {
                                        if (
                                          b.id === id &&
                                          editor.getText().length <= 81
                                        ) {
                                          return {
                                            ...b,
                                            value: value,
                                          };
                                        }
                                        return b;
                                      }
                                    ),
                                  };
                                });
                              }
                            }}
                          />
                        </fieldset>
                        {index === 0 &&
                        contentBuilderData.bodylines.length === 0 ? (
                          <img
                            src={images.addImage}
                            alt="add"
                            className={`addHeadlineBtn ${
                              isDisabled === true ? "disabled" : null
                            }`}
                            onClick={() => {
                              if (isDisabled !== true) {
                                handleAddBodyline();
                              }
                            }}
                          ></img>
                        ) : null}
                        {contentBuilderData.bodylines.length !== 1 && (
                          <img
                            src={images.trashImage}
                            alt="delete"
                            className={`delete-img ${
                              isDisabled === true ? "disabled" : null
                            }`}
                            onClick={() => {
                              if (
                                contentBuilderData.bodylines.length !== 1 &&
                                isDisabled !== true
                              ) {
                                handleRemoveBodyline(id);
                              }
                            }}
                          />
                        )}
                        {contentBuilderData.bodylines.length > 0 &&
                          index === contentBuilderData.bodylines.length - 1 && (
                            <img
                              src={images.addImage}
                              alt="add"
                              className={`addHeadlineBtn ${
                                contentBuilderData.bodylines.length >= 4 ||
                                isDisabled
                                  ? "disabled"
                                  : null
                              }`}
                              onClick={() => {
                                if (isDisabled !== true) {
                                  handleAddBodyline();
                                }
                              }}
                            ></img>
                          )}
                      </motion.div>
                    );
                  })}
                </AnimatePresence>
              </div>
            </div>
            <div className="copy-wrapper">
              <div className="copy-main-div">
                <div className="communicate-section-inner content-offer">
                  <div className="communication-textareas mb-16">
                    <fieldset
                      className="text-box bodyline-text-box"
                      disabled={isDisabled}
                    >
                      <legend>Disclaimer/Legal Copy</legend>
                      <textarea
                        value={contentBuilderData.disclaimerLegalCopy}
                        className="input-text-textarea"
                        rows="2"
                        cols="30"
                        name="bodyline"
                        maxLength="80"
                        onChange={(e) => {
                          setContentBuilderData((prev) => {
                            return {
                              ...prev,
                              disclaimerLegalCopy: e.target.value,
                            };
                          });
                        }}
                      />
                    </fieldset>
                  </div>
                  <div className="CTAurl-container">
                    <fieldset
                      className="text-box CTAurl-text"
                      disabled={isDisabled}
                    >
                      <legend>Call To Action URL</legend>
                      <input
                        type="text"
                        value={contentBuilderData.callToActionURL}
                        name="callToActionURL"
                        className="width-95"
                        maxLength="100"
                        onChange={(e) => {
                          setContentBuilderData((prev) => {
                            return {
                              ...prev,
                              callToActionURL: e.target.value,
                            };
                          });
                        }}
                      />
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            {offerType !== CUSTOM && (
              <div className="file-upload-section content-offer">
                {!contentBuilderData.imageId ? (
                  <FilesDragAndDrop
                    onUpload={onUpload}
                    isSetDisable={isDisabled || imageUpload.loading}
                    uploadText={"Drag & drop Images"}
                    acceptFileTypes={".jpg,.jpeg,.png"}
                    name={"contentImage"}
                    key={"contentImage"}
                    sourceComponent={"CONTENT"}
                    maxFileSize={1048576}
                    title={"Upload Image"}
                  />
                ) : null}

                {imageUpload.fieldName === "contentImage" &&
                imageUpload.loading ? (
                  <div className="uploaded-images" style={{ marginTop: 15 }}>
                    {miniloading("Uploading...")}
                  </div>
                ) : null}
                {contentBuilderData.imageId && (
                  <>
                    <div className="uploaded-images">
                      <legend className="content-label">
                        Uploaded Image :{" "}
                      </legend>
                      <div className="image-thumbnail">
                        <AiFillPicture />
                      </div>
                      <div className="flex">
                        <a
                          onClick={() =>
                            downloadImage({
                              imageId: contentBuilderData.imageId,
                              imageName: contentBuilderData.imageName,
                            })
                          }
                          className="truncate"
                        >
                          {contentBuilderData?.imageName}
                        </a>
                        <p className="flex center">
                          <img
                            onClick={() => {
                              if (!isDisabled) {
                                deleteImage();
                                setDeletedImages((prevDeletedImages) => {
                                  return [
                                    ...prevDeletedImages,
                                    {
                                      imageId: contentBuilderData.imageId,
                                    },
                                  ];
                                });
                              }
                            }}
                            alt="delete"
                            className={
                              isDisabled === true
                                ? "image-delete-icon disabled"
                                : "image-delete-icon"
                            }
                            src={images.trashImage}
                          />
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            <div style={{ maxWidth: 570, marginTop: 21 }}>
              {contentBuilderData.subCategory ? (
                metaData?.getContentAttrsLoading === true ? (
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      flexDirection: "column",
                    }}
                  >
                    {[...Array(5)].map((loading, index) => (
                      <Skeleton
                        key={index}
                        baseColor={theme === DARK ? "#202020" : ""}
                        highlightColor={theme === DARK ? "#444" : ""}
                        height={30}
                      />
                    ))}
                  </div>
                ) : (
                  <GetInputFields
                    INPUT_FIELDS={inputFields}
                    inputFieldsValue={inputFieldsValue}
                    setInputFieldsValue={setInputFieldsValue}
                    handleViewOffer={handleViewOffer}
                    startEndDateRef={startEndDateRef}
                  />
                )
              ) : null}
            </div>
            {/* <GenericRules
							expression={expression}
							setExpression={setExpression}
							isSetDisable={false}
							entitesOptions={["member", "store"]}
						/> */}
            {showPopup && (
              <OfferSelectionPopupComponent
                setShowPopup={setShowPopup}
                onValueSelect={onValueSelect}
                setInputFieldsValue={setInputFieldsValue}
                inputFieldsValue={inputFieldsValue}
              />
            )}
          </div>
        </div>
      </section>
      {/* {showProcess && (
        <SuggesstionComponent
          promptText={promptText}
          selectedResponse={selectedResponse}
          setPromptText={setPromptText}
          setShowProcess={setShowProcess}
          showProcess={showProcess}
          setContentBuilderData={setContentBuilderData}
          showSuggestionBtn={showSuggestionBtn}
          offerContentData={offerContentData}
        />
      )} */}
      {/* <SuggesstionComponent
        promptText={promptText}
        selectedResponse={selectedResponse}
        setPromptText={setPromptText}
        setShowProcess={setShowProcess}
        showProcess={showProcess}
        setContentBuilderData={setContentBuilderData}
        showSuggestionBtn={showSuggestionBtn}
        offerContentData={offerContentData}
      /> */}
    </>
  );
};
function SuggesstionComponent({
  showProcess,
  promptText,
  setPromptText,
  setShowProcess,
  selectedResponse,
  setContentBuilderData,
  showSuggestionBtn,
  offerContentData,
}) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(promptText);
  const contentGenerationData = useSelector((state) => state.promptData);
  const securityData = useSelector((state) => state.securityData);

  useEffect(() => {
    if (searchText.length > 1) {
      headers.sessionId = skey();
      headers.requestAccess = getRightsFromSession();
      headers.organization = offerContentData.authOrganizationName;
      //   headers.responseActions = "contentGenerationService";
      const requestHeaders = {
        headers,
      };
      let contentGenerationRequestBody = {
        prompt: searchText,
      };
      setSearchText("");
      setPromptText("");
      dispatch(
        contentGenerationDetails(requestHeaders, contentGenerationRequestBody)
      );
    }
  }, [searchText]);
  useEffect(() => {}, [contentGenerationData]);
  function handleSearch(value) {
    setSearchText(value);
  }

  return (
    showProcess && (
      <section className="content-modal-backdrop">
        <motion.div
          className="inner-modal-actions"
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          exit={{ opacity: 0 }}
        >
          <div className="header">
            <div className="search-bar-container-campaign">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  type="text"
                  placeholder="Ask for suggestion"
                  className="attribute-search"
                  rows="3"
                  cols="50"
                  value={promptText}
                  onChange={(e) => {
                    setPromptText(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSearch(promptText);
                    }
                  }}
                  autoFocus
                ></input>
                <small className="search-info">
                  Press enter to get suggestions
                </small>
              </form>
            </div>
            <FaRegTimesCircle
              onClick={() => {
                setShowProcess(false);
              }}
              size={25}
              className="campaign-modal-close-icon"
            />
          </div>
          <div className="mt-23">
            {tempPromtResponse.promptResponse.choices.map((choice, index) => {
              return (
                <div
                  key={index}
                  className="response-wrapper"
                  onClick={() => {
                    setContentBuilderData((prev) => {
                      return {
                        ...prev,
                        headlines: prev.headlines.map((h) => {
                          if (h.id === showSuggestionBtn.currentFocusId) {
                            return {
                              ...h,
                              value: `Don't miss out on our unbeatable deals!`,
                            };
                          }
                          return h;
                        }),
                      };
                    });
                    setShowProcess(false);
                  }}
                >
                  {choice.text}
                </div>
              );
            })}
          </div>
        </motion.div>
      </section>
    )
  );
}

export default ContentBuilder;
