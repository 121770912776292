import "./ContentWrapper.style.scss";
import { useEffect, useState } from "react";

import ContentHeader from "../contentHeaderComponent/ContentHeader";
import ContentDetails from "../contentDetailsComponent/ContentDetails";

//import { getEntitiesDetails } from "../../../tellurideExtRedux/metaData/MetaDataAction.js";
import { useDispatch, useSelector } from "react-redux";

import Toaster from "../../errorsComponent/Toaster.jsx";
import Loading from "../../LoaderComponents/Loading.jsx";
import {
  CONTENT_OFFER_LOADER_SOURCE,
  OFFER_LOADER_SOURCE,
} from "../../LoaderComponents/LoadingConstant.js";
import { ErrorBoundary } from "react-error-boundary";
import Error from "../../errorsComponent/Error.jsx";
import { useErrorBoundary } from "react-error-boundary";
import checkIcon from "../../../tellurideExtAssets/images/offerSetupImages/check.png";
import errorIcon from "../../../tellurideExtAssets/images/offerSetupImages/error.svg";
import {
  ESLO,
  OFFER_DETAILS_STATUS_DRAFT,
  OFRI,
  OFRP,
  SUCCESS,
  TOASTER_ERROR,
  TOASTER_SUCCESS,
} from "../../constantComponent/Constants.js";
import DateRangeFilter from "../../utilsComponent/CalendarDateRange/CalendarDateRange.js";

import { headers } from "../../constantComponent/Constants.js";
import _, { set } from "lodash";

import { useNavigate } from "react-router-dom";
import {
  VIEW_OFFER,
  OFFER,
  CAMPAIGN,
  CONTENT,
} from "../../constantComponent/PathConstants.js";
import {
  changeDateDashFormat,
  checkRightsIsEditable,
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../../utilsComponent/CommonUtil.js";

import ContentBuilder from "../contentBuilderComponent/ContentBuilder";
import {
  emptyContentResponse,
  fetchOfferDetails,
  getCategories,
  getContentAttrs,
  getProgramTypesDetails,
  getSubCategories,
  resetContentMetadata,
  resetUploadImageData,
  setOFferChangeStatus,
} from "../../../tellurideExtRedux";
import moment from "moment";
import PreviewTestContent from "../contentBuilderComponent/PreviewTestContent";
import { generateUniqueId } from "../contentBuilderComponent/ContentBuilderUtil";
import isEqual from "react-fast-compare";
import UnauthorizedAccess from "../../unauthorizeComponent/UnauthorizeWrapper.jsx";

const ContentWrapper = () => {
  const dispatch = useDispatch();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const securityData = useSelector((state) => state.securityData);
  const ContentOfferData = offerDetailsPageData.offerDetailsResponse;
  const actionJson = ContentOfferData?.actions?.actionJson;
  const queryString = require("query-string");
  const offerNumber = queryString.parse(window.location.search).offerid;
  const navigate = useNavigate();
  const [isPreviewImage, setIsPreviewImage] = useState(false);
  const [inputFieldsValue, setInputFieldsValue] = useState({});

  const [inputFields, setInputFields] = useState([]);
  const [validation, setValidation] = useState({});

  const [categoriesLoaded, setCategoriesLoaded] = useState(false);

  //theme
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  const [isDisabled, setIsDisabled] = useState(
    ContentOfferData?.offerData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT
      ? false
      : true
  );
  const [contentBuilderData, setContentBuilderData] = useState({
    category: "",
    subCategory: "", //Brands
    headlines: [],
    bodylines: [],
    disclaimerLegalCopy: "",
    callToActionURL: "",
    imageId: "",
    imageName: "",
    imagePath: ""
  });
  const [imageUploadField, setImageUploadField] = useState("");

  const [contentDetails, setContentDetails] = useState({
    startDate: moment(new Date()).toLocaleString("en-US"),
    endDate: moment(new Date()).toLocaleString("en-US"),
    description: "",
  });
  const [deletedImages, setDeletedImages] = useState([]);
  const [offerType, setOfferType] = useState("");
  useEffect(() => {
    if (ContentOfferData?.offerData?.offerType) {
      setOfferType(
        (ContentOfferData?.offerData?.offerType).split(" ").join("")
      );
      const isEditable = checkRightsIsEditable(
        getRightsKey([
          "content",
          "detail",
          "setup",
          (ContentOfferData?.offerData?.offerType).split(" ").join(""),
        ])
      );

      if (
        ContentOfferData?.offerData?.offerStatus ===
          OFFER_DETAILS_STATUS_DRAFT &&
        isEditable
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [
    ContentOfferData?.offerData?.offerType,
    ContentOfferData?.offerData?.offerStatus,
  ]);
  useEffect(() => {
    dispatch(emptyContentResponse());
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const offerRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
      offerNumber: offerNumber ? offerNumber : "",
    };
    dispatch(
      fetchOfferDetails(requestHeaders, offerRequestBody, {
        navigate: navigate,
        successRedirect: "",
        errorRedirectionPath: CAMPAIGN + CONTENT,
      })
    );
    dispatch(getCategories({}, requestHeaders));

		// ProgramType change
		const programTypesRequestBody = {
			requestorID: OFRI,
			messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
		};
		dispatch(getProgramTypesDetails(programTypesRequestBody, requestHeaders));

    return () => {
      dispatch(resetUploadImageData());
      dispatch(resetContentMetadata());
    };
  }, []);
  const handleClearFields = () => {
    console.log();
    
    setInputFields([]); // Clear the array
    setInputFieldsValue({}); // Reset the object
    if(Object.keys(validation).length !== 0){      
      setValidation({})
    }
  };
  
  function handleCategoryChange(category) {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      id: category,
    };
    setContentBuilderData((prev) => {
      return {
        ...prev,
        subCategory: "",
      };
    });
    dispatch(getSubCategories(requestBody, requestHeaders));
    // setInputFields([]);
    // setInputFieldsValue({});
    handleClearFields()
  }
  function handleSubCategoryChange(subCategory) {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBodyContentAttrs = {
      category: contentBuilderData.category,
      subCategory: subCategory,
    };
    dispatch(getContentAttrs(requestBodyContentAttrs, requestHeaders));
    // setInputFields([]);
    // setInputFieldsValue({});    
    // if(Object.keys(validation).length !== 0){      
    //   setValidation({})
    // }
    handleClearFields()
  }
  useEffect(() => {
    // const isEditable = !checkRightsIsEditable(
    //   getRightsKey([
    //     "content",
    //     "detail",
    //     "setup",
    //     offerType.split(" ").join(""),
    //   ])
    // );
    // console.log(isEditable);
    // if (
    //   ContentOfferData?.offerData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT &&
    //   isEditable
    // ) {
    //   setIsDisabled(true);
    // } else {
    //   setIsDisabled(false);
    // }
    if (
      offerDetailsPageData.offerDetailsLoaded === true &&
      offerDetailsPageData.offerDetailsResponse &&
      offerDetailsPageData.offerDetailsResponse?.status === SUCCESS
    ) {
      setContentDetails({
        description: ContentOfferData?.offerData?.offerDescription,
        startDate: ContentOfferData?.offerData?.offerStartDate,
        endDate: ContentOfferData?.offerData?.offerEndDate,
        programType: ContentOfferData?.offerData?.programType,
      });
      if (actionJson) {
        setContentBuilderData((prev) => {
          return {
            ...prev,
            callToActionURL: actionJson?.callToActionURL,
            imageId: actionJson?.imageId,
            imageName: actionJson?.imageName,
            imagePath: actionJson?.imagePath,
            disclaimerLegalCopy: actionJson?.disclaimerLegalCopy,
            headlines: (() => {
              let headlines = [];
              for (let i = 1; i <= 4; i++) {
                if (actionJson.hasOwnProperty(`headline${i}`)) {
                  headlines.push({
                    value: actionJson[`headline${i}`],
                    id: generateUniqueId(),
                  });
                }
              }
              return headlines;
            })(),
            bodylines: (() => {
              let bodylines = [];
              for (let i = 1; i <= 4; i++) {
                if (actionJson.hasOwnProperty(`bodyline${i}`)) {
                  bodylines.push({
                    value: actionJson[`bodyline${i}`],
                    id: generateUniqueId(),
                  });
                }
              }
              return bodylines;
            })(),
            category: actionJson?.category,
            subCategory: actionJson?.subCategory,
          };
        });
        if (actionJson?.category && !categoriesLoaded) {
          headers.sessionId = skey();
          headers.requestAccess = getRightsFromSession();
          headers.organization = offerDetailsPageData?.authOrganizationName;
          const requestHeaders = {
            headers,
          };
          dispatch(
            getSubCategories(
              {
                id: actionJson.category,
              },
              requestHeaders
            )
          );
        }
        if (
          actionJson?.category &&
          actionJson?.subCategory &&
          !categoriesLoaded
        ) {
          headers.sessionId = skey();
          headers.requestAccess = getRightsFromSession();
          headers.organization = offerDetailsPageData?.authOrganizationName;
          const requestHeaders = {
            headers,
          };
          const requestBodyContentAttrs = {
            category: actionJson.category,
            subCategory: actionJson.subCategory,
          };
          dispatch(getContentAttrs(requestBodyContentAttrs, requestHeaders));
        }
        setCategoriesLoaded(true);
      }
    }
  }, [
    offerDetailsPageData.offerDetailsResponse,
    ContentOfferData?.offerData?.offerStatus,
  ]);

  // useEffect(() => {
  //   if (actionJson && (contentBuilderData.subCategory || contentBuilderData.category)) {
  //     setInputFieldsValue(() => {
  //       let newInputFieldsValue = {};
  //       Object.keys(inputFieldsValue).forEach((key) => {
  //         newInputFieldsValue[key] = actionJson[key];
  //       });
  //       return newInputFieldsValue;
  //     });
  //   }
  // }, [actionJson, contentBuilderData]);

  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData.isDarkTheme]);

  useEffect(() => {
    setInputFields((prev) => {
      return prev.map((input) => {
        return {
          ...input,
          theme: toasterData.isDarkTheme,
        };
      });
    });
  }, [theme, toasterData.isDarkTheme]);

  useEffect(() => {
    if (
      ContentOfferData?.offerData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT
    ) {
      let isOfferEdited = false;
      let headlines = {};
      contentBuilderData.headlines.map((headline, index) => {
        headlines[`headline${index + 1}`] = headline.value;
      });
      let bodylines = {};
      contentBuilderData.bodylines.map((bodyline, index) => {
        bodylines[`bodyline${index + 1}`] = bodyline.value;
      });
      let editedContentJSON = {
        ...contentBuilderData,
        ...headlines,
        ...bodylines,
        ...contentDetails,
        endDate: changeDateDashFormat(contentDetails?.endDate),
        startDate: changeDateDashFormat(contentDetails?.startDate),
        ...inputFieldsValue,
      };
      delete editedContentJSON.headlines;
      delete editedContentJSON.bodylines;
      if(!editedContentJSON?.disclaimerLegalCopy) {
        delete editedContentJSON.disclaimerLegalCopy
      }
      let backendJSON = {
        description: ContentOfferData?.offerData?.offerDescription,
        startDate: ContentOfferData?.offerData?.offerStartDate,
        endDate: ContentOfferData?.offerData?.offerEndDate,
        ...actionJson,
      };
      if (isEqual(editedContentJSON, backendJSON)) {
        isOfferEdited = false;
      } else {
        isOfferEdited = true;
      }
      if (
        offerDetailsPageData.isOfferChanged !== isOfferEdited &&
        checkRightsIsEditable(
          getRightsKey([
            "content",
            "detail",
            "setup",
            offerType.split(" ").join(""),
          ])
        )
      ) {
        dispatch(setOFferChangeStatus(isOfferEdited));
      }
    }
  }, [contentBuilderData, contentDetails, inputFieldsValue]);
  //useEffect(() => {
  //   if (Object.keys(inputFieldsValue).length === 0) {
  //     if (actionJson && (contentBuilderData.subCategory || contentBuilderData.category)) {        
  //       let newInputFieldsValue = {};
  //       Object.keys(actionJson).forEach((key) => {
  //         newInputFieldsValue[key] = actionJson[key];
  //       });        
  //       if (Object.keys(newInputFieldsValue).length > 0) {
  //         setInputFieldsValue(newInputFieldsValue);
  //       }
  //     }
  //   }
  // }, [contentBuilderData, actionJson]);
  
  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <Loading source={CONTENT_OFFER_LOADER_SOURCE}>
        {checkRightsIsVisible(
          getRightsKey(["content", "detail", "setup", offerType])
        ) ? (
          <div className="content-page-wrapper">
            <ContentHeader
              contentBuilderData={contentBuilderData}
              contentDetails={contentDetails}
              isDisabled={isDisabled}
              deletedImages={deletedImages}
              inputFieldsValue={inputFieldsValue}
              setValidation={setValidation}
              validation={validation}
            />
            <section className="display-flex display-wrapper">
              <div className="inner-section-wrapper">
                <div className="content-detail-tablet">
                  <ContentDetails
                    theme={theme}
                    contentDetails={contentDetails}
                    setContentDetails={setContentDetails}
                    isDisabled={isDisabled}
                    setIsPreviewImage={setIsPreviewImage}
                    contentBuilderData={contentBuilderData}
                  />
                </div>
                <ContentBuilder
                  theme={theme}
                  contentBuilderData={contentBuilderData}
                  setContentBuilderData={setContentBuilderData}
                  isDisabled={isDisabled}
                  setDeletedImages={setDeletedImages}
                  imageUploadField={imageUploadField}
                  setImageUploadField={setImageUploadField}
                  inputFieldsValue={inputFieldsValue}
                  setInputFieldsValue={setInputFieldsValue}
                  inputFields={inputFields}
                  setInputFields={setInputFields}
                  handleCategoryChange={handleCategoryChange}
                  handleSubCategoryChange={handleSubCategoryChange}
                  validation={validation}
                  setValidation={setValidation}
                />
              </div>
              <div className="sticky-right content-detail-desktop">
                <ContentDetails
                  theme={theme}
                  contentDetails={contentDetails}
                  setContentDetails={setContentDetails}
                  isDisabled={isDisabled}
                  setIsPreviewImage={setIsPreviewImage}
                  contentBuilderData={contentBuilderData}
                />
              </div>
            </section>
            {isPreviewImage && (
              <PreviewTestContent
                setIsPreviewImage={setIsPreviewImage}
                organizationName={offerDetailsPageData?.organizationName}
                contentBuilderData={contentBuilderData}
                theme={theme}
              />
            )}
          </div>
        ) : (
          <UnauthorizedAccess />
        )}
      </Loading>
    </ErrorBoundary>
  );
};
export default ContentWrapper;
