import React from "react";
import {
  backArror,
  expandInputIcon,
} from "../../../../utilsComponent/SidebarIcons";
import { useSelector } from "react-redux";

const UpdateRequirementInput = ({
  requirementInputText,
  setrequirementInputText,
  expandequirementInput,
  setExpandRequirementInput,
}) => {
  const requirementData = useSelector((state) => state.requirementData);

  return (
    <div
      className="requirement-setup-conatiner"
      style={{ height: expandequirementInput && "90%" }}
    >
      <div
        className="text-area-section"
        style={{ height: expandequirementInput && "100%" }}
      >
        <div className="input-header">
          {expandequirementInput && <span className="back-arrow" onClick={() => setExpandRequirementInput(false)}>{backArror()}</span>}

          <h4 className="header-section mb-10">
            What would you like to change
          </h4>
        </div>
        {!expandequirementInput && (
          <button
            className="expand-btn"
            aria-label="Expand"
            onClick={() => setExpandRequirementInput(true)}
          >
            {expandInputIcon()}
          </button>
        )}

        <fieldset className="text-box">
          {/* <legend>{"Subject Line"}</legend> */}

          <textarea
            value={requirementInputText}
            className="input-text-textarea"
            placeholder={
              "Tell us anything you want to change in this requirement."
            }
            disabled={requirementData?.requirementsLoading}
            autoFocus={requirementData?.requirementsLoading === false}
            onChange={(e) => setrequirementInputText(e.target.value)}
            style={{ height: expandequirementInput && "100%" }}
          />
        </fieldset>
      </div>
    </div>
  );
};

export default UpdateRequirementInput;
