import React, { useEffect, useState } from "react";
import "./UpdateRequirementSection.style.scss";
import { motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  DotLoading,
  loading,
} from "../../../LoaderComponents/loadingDesignConstant";
import { CANCEL } from "../../../constantComponent/Constants";
import { fetchRequirements } from "../../../../tellurideExtRedux/requirements/RequirementsAction";
import { rightDiagonalArrow } from "../../../utilsComponent/SidebarIcons";
import UpdateRequirementBodySection from "./updateRequirementBody/UpdateRequirementBodySection";
import UpdateRequirementPlayGroundBody from "./updateRequirementPlayGround/UpdateRequirementPlayGroundBody";
const UpdateRequirementSection = ({
  showUpdateRequirement,
  setShowUpdateRequirement,
  openPlayGround,
  setOpenPlayGround,
  actionJsonvalue,
  setActionJsonValue
}) => {
  const requirementData = useSelector((state) => state.requirementData);
  const [requirementInputText, setrequirementInputText] = useState("");

  const offerData = useSelector((state) => state.offerDetailsData);
//   const [actionJsonvalue, setActionJsonValue] = useState(
//     offerData?.offerDetailsResponse?.actions?.actionJson
//   );
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(actionJsonvalue);
    console.log(offerData?.offerDetailsResponse?.actions?.actionJson);
    if (actionJsonvalue && Object.keys(actionJsonvalue).length > 0) {
      let headers = {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 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",
      };
      let requestBody = {
        chat_bot_type: "GAE_ASSISTANT",
        trackId: "1175302609",
        parameters: {
          offerCode: offerData?.offerDetailsResponse?.offerData?.offerNumber,
          offerId: "",
          roleType: "Summarizer",
          workflowJson: {
            altSequence: 1,
            actions: [actionJsonvalue],
            criteria: "priority,order",
          },
          history: [],
        },
      };
      const requestHeaders = {
        headers,
      };
      dispatch(fetchRequirements(requestHeaders, requestBody));
    }
  }, []);
  function submitHandler() {
    let headers = {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    };
    let requestBody = {
      chat_bot_type: "GAE_ASSISTANT",
      trackId: "1175302609",
      parameters: {
        offerCode: offerData?.offerDetailsResponse?.offerData?.offerNumber,
        offerId: "",
        userMessage: requirementInputText,
        roleType: "Modifier",
        workflowSummary:
          requirementData?.requirementsResponse?.parameters?.workflowSummary,
        workflowJson: requirementData?.requirementsResponse?.parameters
          ?.workflowJson
          ? requirementData?.requirementsResponse?.parameters?.workflowJson
          : {
            altSequence: 1,
            actions: [actionJsonvalue],
            criteria: "priority,order",
          },
        history: [],
      },
    };
    const requestHeaders = {
      headers,
    };
    // setrequirementInputText("");
    dispatch(fetchRequirements(requestHeaders, requestBody));
  }
  console.log(requirementData.requirementsResponse);
  return (
    <div className="requiement-modal-backdrop">
      <motion.div
        className="inner-modal"
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{
          x: "100%",
          opacity: 0,
          transition: { duration: 0.5, ease: "easeOut" },
        }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        // style={{ width: innerModalWidth }}
      >
        {showUpdateRequirement && (
          <UpdateRequirementBodySection
            requirementInputText={requirementInputText}
            setrequirementInputText={setrequirementInputText}
            setShowUpdateRequirement={setShowUpdateRequirement}
            submitHandler={submitHandler}
            actionJsonvalue={actionJsonvalue}
            setActionJsonValue={setActionJsonValue}
          />
        )}

        {openPlayGround && (
          <UpdateRequirementPlayGroundBody
            setOpenPlayGround={setOpenPlayGround}
          />
        )}
      </motion.div>
    </div>
  );
};

export default UpdateRequirementSection;
