import { useEffect, useState } from "react";
import {
  BACK,
  SAVE,
} from "../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import { inputTypes } from "../ConnectorConstant";
import "./CreateConnectorPopup.style.scss";
import { motion } from "framer-motion/dist/framer-motion";
import { FaRegTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import GetInputFields from "../../utilsComponent/InputFieldHandler/InputFieldUtil";
import { saveConnector, setErrorToaster } from "../../../tellurideExtRedux";
import { headers } from "../../constantComponent/Constants";
import {
  checkRightsIsEditable,
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../../utilsComponent/CommonUtil";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import CreateExistingConnectorConfirmationComponent from "../../modelComponent/createExistingConnectorConfirmationComponent/CreateExistingConnectorConfirmationComponent";
import { miniloadingWithoutMessage } from "../../LoaderComponents/loadingDesignConstant";

const CreateConnectorPopup = ({
  setIsShowCreatePopup,
  currentClickedData,
  isExistingData,
}) => {
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const metaDataDetails = useSelector((state) => state.metaData);
  const { createConnectorLoding } = metaDataDetails;
  const connectorListData = useSelector(
    (state) => state.metaData.addConnectorOptionsResponse
  );
  const existingConnectorList = useSelector(
    (state) => state.metaData.connectorListResponse.instances
  );
  const toasterData = useSelector((state) => state.toasterData);

  const [connectorFields, setConnectorFields] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsValue, setInputFieldsValue] = useState({});
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  const [connectorName, setConnectorName] = useState(
    currentClickedData?.name ? currentClickedData?.name : ""
  );
  const [connectorDescription, setConnectorDescription] = useState(
    currentClickedData?.description ? currentClickedData?.description : ""
  );
  const [showConfirmationPopup, setShowConfirmationPopup] = useState({
    key: "",
    visibility: false,
  });
  const [validation, setValidation] = useState({});
  const [errors, setErrors] = useState({ name: "", description: "" });
  const [isDiabled, setIsDisabled] = useState(
    !checkRightsIsEditable(
      getRightsKey([
        "configure",
        "connector",
        "viewconnector",
        currentClickedData?.connectorSubType
          ? currentClickedData?.connectorSubType.toLowerCase()
          : currentClickedData?.connectorSubType.toLowerCase(),
      ])
    )
  );
  const [isSetDefault, setIsSetDefault] = useState(
    currentClickedData?.defaultInd === "Y" ? true : false
  );
  const dispatch = useDispatch();
  useEffect(() => {
    metaDataDetails.createConnectorResponse = "";
    setValidation(() => {
      let newValidation = {};
      connectorFields?.required?.forEach((input) => {
        newValidation[input] = {
          status: false,
          message: "",
          touched: false,
        };
      });
      return newValidation;
    });
    existingConnectorList?.map((connector) => {
      if (
        currentClickedData?.connectorType === connector?.connectorType &&
        currentClickedData?.connectorSubType === connector?.connectorSubType
      ) {
        setShowConfirmationPopup({
          key: "showPopup",
          visibility: true,
        });
      }
    });
  }, []);
  useEffect(() => {
    if (validation) {
      const validationStatus = Object.keys(validation).some(
        (key) => validation[key].status === true
      );
      if (validationStatus) {
        setInputFields((prev) => {
          return prev.map((prevInput) => {
            return {
              ...prevInput,
              disabled: isDiabled,
              validationStatus: {
                status: validation[prevInput.fieldname]?.status,
                message: validation[prevInput.fieldname]?.message,
                touched: validation[prevInput.fieldname]?.touched,
              },
            };
          });
        });
      }
      // check if every input object status is false
      const validationStatusFalse = Object.keys(validation).every(
        (key) => validation[key].status === false
      );
      if (validationStatusFalse) {
        setInputFields((prev) => {
          return prev.map((prevInput) => {
            return {
              ...prevInput,
              disabled: isDiabled,
              validationStatus: {
                status: false,
                message: "",
                touched: false,
              },
            };
          });
        });
      }
    }
  }, [validation]);
  useEffect(() => {
    if (currentClickedData?.connectionAttributes) {
      const schema = connectorListData.metadata.filter(
        (data) => data.connectorSubType === currentClickedData?.connectorSubType
      );
      setConnectorFields(JSON.parse(schema[0].schema));

      let parsedData = JSON.parse(currentClickedData?.connectionAttributes);

      setInputFieldsValue(parsedData);
    } else {
      let parsedData = JSON.parse(currentClickedData?.schema);
      setConnectorFields(parsedData);
    }
  }, [currentClickedData]);

  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData.isDarkTheme]);

  useEffect(() => {
    if (connectorFields) {
      let fields = [];

      connectorFields?.required?.map((fieldName) => {
        let properties = connectorFields?.properties[fieldName];
        fields.push({
          inputType: inputTypes[properties?.type],
          displayName: fieldName,
          fieldname: fieldName,
          validation: {
            minLength: properties?.minLength ? properties?.minLength : 100,
            maxLength: properties?.maxLength ? properties?.maxLength : 100,
            pattern: "",
            message: "Please enter value.",
            required: true,
          },
        });
      });
      setInputFields(fields);

      setInputFieldsValue(() => {
        let newInputFieldsValue = {};
        connectorFields?.required.map((key) => {
          newInputFieldsValue[key] = inputFieldsValue[key]
            ? inputFieldsValue[key]
            : "";
        });
        return newInputFieldsValue;
      });
    }
  }, [connectorFields]);
  function setDescriptionValue(value) {
    setConnectorDescription(value);
  }
  function createUpdateConnector() {
    const validationStatus = Object.keys(validation).some(
      (key) => validation[key].status === true
    );
    if (validationStatus || !connectorName || !connectorDescription) {
      const validationObj = {};
      Object.keys(validation).forEach((key) => {
        validationObj[key] = {
          ...validation[key],
          touched: true,
        };
      });
      setValidation(validationObj);
      dispatch(setErrorToaster("Please fill the required input fields."));
      const newErrors = { name: "", description: "" };

      if (!connectorName) {
        newErrors.name = "Name is required.";
      }

      if (!connectorDescription) {
        newErrors.description = "Description is required.";
      }

      setErrors(newErrors);
      return;
    }
    if (!connectorName || !connectorDescription) {
      dispatch(setErrorToaster("Please fill the required input fields."));
      const newErrors = { name: "", description: "" };

      if (!connectorName) {
        newErrors.name = "Name is required.";
      }

      if (!connectorDescription) {
        newErrors.description = "Description is required.";
      }

      setErrors(newErrors);

      return;
    } else {
      let setError = false;
      existingConnectorList?.map((connector) => {
        if (connectorName.toLowerCase() === connector?.name.toLowerCase() && !isExistingData) {
          dispatch(setErrorToaster("Connector with same name exists"));
          setError = true;
        }
      });
      if(setError) {
        return;
      }
    }
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    let requestBody = {
      organization: offerDetailsPageData?.authOrganizationName,
      metadataId: currentClickedData?.connectorMetadataId
        ? currentClickedData?.connectorMetadataId
        : currentClickedData?.metadataId,
      name: connectorName,
      version: "1",
      description: connectorDescription,
      connectorType: currentClickedData?.connectorType
        ? currentClickedData?.connectorType
        : currentClickedData?.connectorType,
      connectorSubType: currentClickedData?.connectorSubType
        ? currentClickedData?.connectorSubType
        : currentClickedData?.connectorSubType,
      status: currentClickedData?.status,
      connectionAttributes: inputFieldsValue,
      defaultInd: isSetDefault ? "Y" : "N",
    };
    const connectorTableListRequestBody = {
      organization: offerDetailsPageData?.authOrganizationName,
      Type: "",
      subtype: "",
    };
    dispatch(
      saveConnector(
        requestHeaders,
        requestBody,
        connectorTableListRequestBody,
        isExistingData
      )
    );
  }
  useEffect(() => {
    if (metaDataDetails?.createConnectorResponse !== "") {
      setIsShowCreatePopup(false);
    }
  }, [metaDataDetails]);
  useEffect(() => {
    Object.keys(inputFieldsValue).forEach((key) => {
      const input = inputFields.find((input) => input.fieldname === key);
      let errorMessage = "";
      if (input) {
        if (input.validation) {
          if (input?.validation?.required) {
            if (!inputFieldsValue[key]) {
              errorMessage = `${input.displayName} is required`;
            } else if (
              input.inputType === "image" &&
              !inputFieldsValue[key].imageId
            ) {
              errorMessage = `${input.displayName} is required`;
            } else if (
              input.inputType === "image" &&
              inputFieldsValue[key].imageId &&
              inputFieldsValue[key].imageName
            ) {
              errorMessage = "";
            } else {
              errorMessage = "";
            }
          }
          if (input?.validation?.maxLength && inputFieldsValue[key]) {
            if (inputFieldsValue[key].length > input?.validation?.maxLength) {
              errorMessage = `Max length must be ${input.validation.maxLength}`;
            } else {
              errorMessage = errorMessage ? errorMessage : "";
            }
          }
          if (input?.validation?.minLength && inputFieldsValue[key]) {
            if (inputFieldsValue[key].length < input?.validation?.minLength) {
              errorMessage = `Min length must be ${input.validation.minLength}`;
            } else {
              errorMessage = errorMessage ? errorMessage : "";
            }
          }
          if (
            input?.validation?.pattern &&
            inputFieldsValue[key] &&
            inputFieldsValue[key].length > 0
          ) {
            let regex = new RegExp(input?.validation?.pattern.slice(1, -1));
            if (!regex.test(inputFieldsValue[key])) {
              errorMessage = input?.validation?.patternMessage;
            } else {
              errorMessage = errorMessage ? errorMessage : "";
            }
          }
          setValidation((prev) => {
            return {
              ...prev,
              [key]: {
                ...prev[key],
                status: errorMessage ? true : false,
                message: errorMessage,
              },
            };
          });
        }
      }
    });
  }, [inputFieldsValue]);
  return (
    <section className="action-campaign-modal-backdrop create-connector-popup">
      <motion.div
        className="inner-modal-actions"
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{
          x: "100%",
          opacity: 0,
          transition: { duration: 0.5, ease: "easeOut" },
        }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        style={{
          width: "35rem",
        }}
      >
        <div className="header">
          <h4>Setup Connector</h4>
          <div className="close-campaign-popup-container">
            <FaRegTimesCircle
              onClick={() => {
                setIsShowCreatePopup(false);
              }}
              size={25}
              className="campaign-modal-close-icon"
            />
          </div>
        </div>
        <div className="campaignPopup">
          <section className="popup-body">
            <div className="offer-info-container offer-type-container">
              <label className="offer-info-label offer-type-label">
                Type :
              </label>
              <span className="offer-info-bold">
                {currentClickedData?.connectorType
                  ? currentClickedData?.connectorType
                  : currentClickedData?.connectorType}
              </span>
              <label className="offer-info-label offer-type-label">
                SubType :
              </label>
              <span className="offer-info-bold">
                {currentClickedData?.connectorSubType
                  ? currentClickedData?.connectorSubType
                  : currentClickedData?.connectorSubType}
              </span>
            </div>
            <fieldset
              className="text-box dynamic-field"
              disabled={isExistingData ? true : false}
            >
              <legend className={"required"}>Name</legend>
              <input
                type="text"
                value={connectorName}
                name="name"
                className={`width-95`}
                maxLength="50"
                minLength="1"
                onChange={(e) => {
                  setConnectorName(e.target.value);
                }}
                // readOnly={isExistingData ? true : false}
                disabled={isExistingData ? true : false}
                required={true}
              />
              <small className="error-message">
                {errors.name && errors.name}
              </small>
            </fieldset>
            <fieldset className="text-box dynamic-field" disabled={isDiabled}>
              <legend className={"required"}>Description</legend>
              <input
                type="text"
                value={connectorDescription}
                name="name"
                className={`width-95`}
                maxLength="50"
                minLength="1"
                onChange={(e) => {
                  setDescriptionValue(e.target.value);
                }}
                readOnly={false}
                disabled={false}
                required={true}
              />
              <small className="error-message">
                {errors.description && errors.description}
              </small>
            </fieldset>
            <GetInputFields
              INPUT_FIELDS={inputFields}
              inputFieldsValue={inputFieldsValue}
              setInputFieldsValue={setInputFieldsValue}
            />
            <span className="ab-testing-toggle">
              <div className="offer-info-container offer-type-container">
                <label className="offer-info-label offer-type-label default-checkbox">
                  {" "}
                  Default{" "}
                </label>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setIsSetDefault(e.target.checked);
                    }}
                    checked={isSetDefault}
                    disabled={isDiabled}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </span>
          </section>
          <footer className="campaign-modal-footer">
            <button
              className="btn btn-secondary"
              onClick={() => {
                setIsShowCreatePopup(false);
              }}
            >
              {BACK}
            </button>
            {!checkRightsIsEditable(
              getRightsKey([
                "button",
                "configure",
                "connector",
                "viewconnector",
                "saveconnector",
              ])
            ) && (
              <button
                className={`btn btn-primary continue-setup-btn `}
                disabled={
                  offerDetailsPageData?.createConnectorLoding || isDiabled
                }
                onClick={() => {
                  createUpdateConnector();
                }}
              >
                {offerDetailsPageData?.createConnectorLoding
                  ? miniloadingWithoutMessage()
                  : SAVE}
              </button>
            )}
          </footer>
        </div>
        {/* {checkRightsIsEditable(
          getRightsKey([
            "configure",
            "connector",
            "viewconnector",
            currentClickedData?.connectorSubType.toLowerCase()
          ])
        ) && (
          <AnimatePresence>
            {showConfirmationPopup.visibility && !isExistingData && (
              <CreateExistingConnectorConfirmationComponent
                onNoClick={() => {
                  setIsShowCreatePopup(false);
                }}
                setShowConfirmationPopup={setShowConfirmationPopup}
              />
            )}
          </AnimatePresence>
        )} */}
      </motion.div>
    </section>
  );
};

export default CreateConnectorPopup;
