import {
  GET_CHATBOT_ANSWER_REQUEST,
  GET_CHATBOT_ANSWER_SUCCESS,
  GET_CHATBOT_ANSWER_FAILURE,
  GET_CHATBOT_ANSWER_SUCCESS_FAILURE,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
  GET_REQUIREMENTS_REQUEST,
  GET_REQUIREMENTS_SUCCESS,
  GET_REQUIREMENTS_SUCCESS_FAILURE,
  GET_REQUIREMENTS_FAILURE,
  UPDATE_REQUIREMENTS_REQUEST,
  UPDATE_REQUIREMENTS_SUCCESS,
  UPDATE_REQUIREMENTS_SUCCESS_FAILURE,
  UPDATE_REQUIREMENTS_FAILURE,
  API_INVOKE_TEST_REQUEST,
  API_INVOKE_TEST_SUCCESS,
  API_INVOKE_TEST_FAILURE,
  UPDATE_REQUIREMENT_RESPONSE,
} from "./RequirementsType";

const initialState = {
  requirementsLoading: false,
  requirementsResponse: "",
  requirementsError: "",
  updateRequirementsLoading: false,
  updateRequirementsResponse: "",
  updateRequirementsError: "",
  testApiLoading: false,
  testApiResponse: "",
  testApiError: "",
};

export const RequirementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUIREMENTS_REQUEST:
      return {
        ...state,
        requirementsLoading: true,
      };
    case GET_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        requirementsLoading: false,
        requirementsResponse: action.payload,
      };
    case GET_REQUIREMENTS_SUCCESS_FAILURE:
      return {
        ...state,
        requirementsLoading: false,
        requirementsError: action.payload,
      };
    case GET_REQUIREMENTS_FAILURE:
      return {
        ...state,
        requirementsLoading: false,
        requirementsError: action.payload,
      };
    case UPDATE_REQUIREMENTS_REQUEST:
      return {
        ...state,
        updateRequirementsLoading: true,
      };
    case UPDATE_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        updateRequirementsLoading: false,
        updateRequirementsResponse: action.payload,
      };
    case UPDATE_REQUIREMENTS_SUCCESS_FAILURE:
      return {
        ...state,
        updateRequirementsLoading: false,
        updateRequirementsError: action.payload,
      };
    case UPDATE_REQUIREMENTS_FAILURE:
      return {
        ...state,
        updateRequirementsLoading: false,
        updateRequirementsError: action.payload,
      };
    case SET_BLANK_SESSION_ID_ERROR:
      return {
        ...state,
        blankSessionIdError: true,
      };
    case SET_SESSION_TIMEOUT_POPUP:
      return {
        ...state,
        showExpiredSessionPopup: true,
      };
    case API_INVOKE_TEST_REQUEST:
      return {
        ...state,
        testApiLoading: true,
      };
    case API_INVOKE_TEST_SUCCESS:
        return {
          ...state,
          testApiLoading: false,
          testApiResponse: action.payload,
        };
    case API_INVOKE_TEST_FAILURE:
      return {
        ...state,
        testApiLoading: false,
        testApiError: action.payload,
        testApiResponse: ""
      };
    case UPDATE_REQUIREMENT_RESPONSE:
        return{
            ...state,
            requirementsResponse: action.payload
        }
    default:
      return state;
  }
};
